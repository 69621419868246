// Quests
const isQuestOwner = (user, quest) => user && quest && user.id === quest.user_id
const amQuestOwner = quest => isQuestOwner(window.gon?.currentUser, quest)

const isTeamAdmin = (user, team) => user && team && team.admin_id === user.id
const amTeamAdmin = team => isTeamAdmin(window.gon?.currentUser, team)

const isQuestAdmin = (user, quest) => isTeamAdmin(user, quest?.team)
const amQuestAdmin = quest => isTeamAdmin(window.gon?.currentUser, quest?.team)

// Answers
const isAnswerOwner = (user, answer) => user && answer && user.id === answer.user_id
const amAnswerOwner = answer => isAnswerOwner(window.gon?.currentUser, answer)

const canEditAnswer = (user, answer) => isAnswerOwner(user, answer)

const canArchiveAnswer = (user, answer, quest) =>
    isAnswerOwner(user, answer) ||
    (answer?.quest_id === quest?.id && (isQuestOwner(user, quest) || isQuestAdmin(user, quest)))

const canDeleteAnswer = (user, answer) => isAnswerOwner(user, answer)

export default {
    // Quests
    isQuestOwner,
    amQuestOwner,
    isTeamAdmin,
    amTeamAdmin,
    isQuestAdmin,
    amQuestAdmin,

    // Answers
    amAnswerOwner,

    canEditAnswer,
    canArchiveAnswer,
    canDeleteAnswer,
}
