import React, { useEffect, useState, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import styles from './video-button.module.scss'
import cn from 'classnames'

const Video = props => {
    const { videoSourceRef, spinnerRef, downloadRef, videoTagRef } = props.refObj
    const [height, setHeight] = useState(0)

    const ref = useRef(null)

    useEffect(() => {
        if (
            ref?.current &&
            props?.answer?.recording_thumbnail_url &&
            ref.current.clientHeight > 100 // work around the brief time window while thumbnail is generating
        ) {
            setHeight(ref.current.clientHeight)
        }
    }, [props.answer])

    let observer
    const onIntersection = ([video]) => {
        if (video && video.isIntersecting) {
            videoTagRef?.current?.load()
            observer.unobserve(videoTagRef?.current)
        }
    }

    // useEffect(() => {
    //     observer = new IntersectionObserver(onIntersection, { rootMargin: '800px' })
    //     observer.observe(videoTagRef?.current)
    // }, [props.answer])

    const resetVideoTime = e => {
        e.target.currentTime = 0
    }

    // We use styles instead of a boolean bc there are situations where we have to use the DOM to display the link from Answer.showDownload.
    // editedVideoUrl will be overridden after saving via Answer.showDownload.
    const isMp4 =
        props.answer.mp4_recording_url || props.answer.original_recording_type === 'video/mp4'
    const justEdited = props.editedVideoUrl && !props.editMode
    const showSpinner = !isMp4 || justEdited
    //console.log('show spinner', showSpinner)
    const spinnerStyles = showSpinner ? { display: 'block' } : { display: 'none' }

    const showDownload = isMobile && isMp4 && !props.editedVideoUrl
    const downloadStyles = showDownload ? { display: 'block' } : { display: 'none' }

    let arr, seoKey
    if (showDownload && props.answer.path) {
        arr = props.answer.path.split('/')
        seoKey = arr[arr.length - 1]
    }

    const [isPlaying, setIsPlaying] = useState(false)

    const initFrame = '1'
    //console.log('render video', props)
    return (
        <div className="video-comp" ref={ref}>
            {props.answer.mp4_recording_url ? (
                <link rel="preload" as="video" href={props.answer.mp4_recording_url} />
            ) : (
                <link rel="preload" as="video" href={props.answer.recording_url} />
            )}

            {props?.answer?.recording_thumbnail_url && !isPlaying && (
                <>
                    <link rel="preload" as="image" href={props.answer.recording_thumbnail_url} />

                    <div className={cn(styles.videoContainer)}>
                        <img src={props.answer.recording_thumbnail_url} />
                        <div
                            onClick={() => setIsPlaying(true)}
                            className={cn(styles.playButtonContainer)}
                        >
                            <div className={styles.playButton}>
                                <i className="fa fa-play"></i>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {(isPlaying || !props?.answer?.recording_thumbnail_url) && (
                <video
                    autoPlay={isPlaying}
                    onLoadedMetadata={resetVideoTime}
                    controls
                    playsInline
                    preload="metadata"
                    ref={videoTagRef}
                    style={{ ...(height ? { height } : {}), display: 'block', borderRadius: '5px' }}
                >
                    {props.answer.mp4_recording_url && (
                        <source src={`${props.answer.mp4_recording_url}`} type="video/mp4" />
                    )}
                    <source
                        ref={videoSourceRef}
                        src={`${props.answer.recording_url}#t=${initFrame}`}
                        type={props.answer.original_recording_type}
                    />
                </video>
            )}

            {gon.currentUser && props.answer.user_id === gon.currentUser.id && !props.isTitle && (
                <div className="video-controls">
                    <div style={spinnerStyles} className="process-video" ref={spinnerRef}>
                        <div>
                            <i className="fa fa-circle-o-notch fa-spin" />
                            <span>Processing</span>
                        </div>
                    </div>

                    <div style={downloadStyles} className="download-btn" ref={downloadRef}>
                        <a href={props.answer.mp4_recording_url} download={seoKey}>
                            Download
                        </a>
                    </div>
                </div>
            )}
        </div>
    )
}

// no video
// edit

// video
// edit
// edit
// unprocessed
// processed

const prevKeys = {}

const areEqual = (prevProps, nextProps) => {
    if (prevProps.editMode !== nextProps.editMode) return false

    const nextAnswer = nextProps.answer
    const prevAnswer = prevProps.answer

    let equal = true

    if (nextProps.editedVideoUrl) {
        // We're editing and ...
        if (
            nextAnswer.mp4_recording_url &&
            nextAnswer.mp4_recording_url !== prevAnswer.mp4_recording_url
        ) {
            // This overrides url link if an updated processed vid comes back.
            //console.log('edited mp4 show download')
            // Update via DOM so no need to set equal to false.
            nextProps.showDownload(nextAnswer)
        } else if (nextProps.editedVideoUrl !== prevProps.editedVideoUrl) {
            // This means we updated the edited video.
            //console.log('edited url')
            equal = false
            prevKeys[nextAnswer.id] = nextProps.editedVideoUrl
        }
    } else if (
        nextAnswer.mp4_recording_url &&
        nextAnswer.mp4_recording_url !== prevAnswer.mp4_recording_url
    ) {
        // Not editing, but updated url comes back, perhaps from a newly created video or a different machine?
        // TODO might want to add mp4_recording_key to cached answers for consistency, using url for now.
        //console.log('mp4 show download')
        // Update via DOM so no need to set equal to false.
        nextProps.showDownload(nextAnswer)
    } else if (nextAnswer.recording_key && nextAnswer.recording_key !== prevAnswer.recording_key) {
        equal = false
    }
    //console.log('equal', equal)
    return equal
}

export default React.memo(Video, areEqual)
