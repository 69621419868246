import React from 'react'
import styles from 'components/answers/url-post.module.scss'
import cn from 'classnames'
import WithSelection from 'components/shared/WithSelection'

export default class UrlPost extends React.Component {
    constructor(props) {
        super(props)
    }

    capture = ev => {
        ev.stopPropagation()
    }

    address = () => {
        const address = this.props.url.address
        const params =
            this.props.domain === 'youtube.com' && this.props.vidStart
                ? `&t=${this.props.vidStart}`
                : ''
        const snippet = encodeURIComponent(this.props.snippet)
        const scrollToText = '' //this.props.snippet ? `#:~:text=${snippet.toLocaleLowerCase()}` : ''
        return `${address}${params}${scrollToText}`
    }

    render() {
        const url = this.props.url

        return (
            <div className={styles.urlPostComp}>
                <div className={styles.urlTitleContainer}>
                    {url.title && (
                        <WithSelection>
                            <div className={styles.urlTitle} onClick={this.capture}>
                                <a target="_blank" href={this.address()}>
                                    {url.title.replace('&nbsp;', ' ').replace('&amp', '&')}
                                </a>
                            </div>
                        </WithSelection>
                    )}
                </div>

                <div className={styles.urlAddressContainer}>
                    <WithSelection>
                        <div
                            className={cn(styles.urlAddress, 'url-address fs-mask')}
                            onClick={this.capture}
                        >
                            <a target="_blank" href={this.address()}>
                                {this.address()}
                            </a>
                        </div>
                    </WithSelection>
                </div>
            </div>
        )
    }
}
