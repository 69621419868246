import React, { useEffect, useState } from 'react'
import Container from './Container'
import GlobalContext from './contexts/Global'
import StoriesContext from './contexts/Stories'
import ProgressContext from './contexts/Progress'
import { getRenderer } from './util/renderers'
import { renderers as defaultRenderers } from './renderers/index'
import withHeader from './renderers/wrappers/withHeader'
import withSeeMore from './renderers/wrappers/withSeeMore'
import { isEqual } from 'lodash'

const ReactInstaStories = function (props) {
    let renderers = props.renderers ? props.renderers.concat(defaultRenderers) : defaultRenderers
    const { loop } = props
    let context = {
        width: props.width,
        height: props.height,
        loader: props.loader,
        header: props.header,
        storyStyles: props.storyStyles,
        loop,
        defaultInterval: props.defaultInterval,
        isPaused: props.isPaused,
        currentIndex: props.currentIndex,
        onStoryStart: props.onStoryStart,
        onStoryEnd: props.onStoryEnd,
        onAllStoriesEnd: props.onAllStoriesEnd,
        keyboardNavigation: props.keyboardNavigation,
    }
    const [stories, setStories] = useState({ stories: generateStories(props.stories, renderers) })
    const [currentId, setCurrentId] = useState(0)
    const [pause, setPause] = useState(true)
    const [videoDuration, setVideoDuration] = useState(0)
    const [count, setCount] = useState(0)

    const setCurrentIdWrapper = val => {
        setCurrentId(val)
        toggleState('play')
    }
    const previous = () => {
        const val = currentId > 0 ? currentId - 1 : currentId
        setCurrentIdWrapper(val)
    }
    const next = () => {
        if (loop) {
            updateNextStoryIdForLoop()
        } else {
            updateNextStoryId()
        }
    }
    const updateNextStoryIdForLoop = () => {
        setCurrentIdWrapper(prev => (prev + 1) % stories.stories.length)
    }
    const updateNextStoryId = () => {
        const val = currentId < stories.stories.length - 1 ? currentId + 1 : currentId
        setCurrentIdWrapper(val)
    }

    const toggleState = action => {
        setPause(action === 'pause')
    }

    useEffect(() => {
        setStories({ stories: generateStories(props.stories, renderers) })
    }, [])
    return (
        <GlobalContext.Provider value={context}>
            <StoriesContext.Provider value={stories}>
                <ProgressContext.Provider
                    value={{
                        videoDuration: videoDuration || 0,
                        currentId,
                        pause,
                        next,
                        setVideoDuration,
                        setCurrentId,
                        setPause,
                        previous,
                        toggleState,
                        count,
                        setCount,
                    }}
                >
                    <Container />
                </ProgressContext.Provider>
            </StoriesContext.Provider>
        </GlobalContext.Provider>
    )
}
const generateStories = (stories, renderers) => {
    return stories.map(s => {
        let story = {}
        if (typeof s === 'string') {
            story.url = s
            story.type = 'image'
        } else if (typeof s === 'object') {
            story = Object.assign(story, s)
        }
        let renderer = getRenderer(story, renderers)
        story.originalContent = story.content
        story.content = renderer
        return story
    })
}

ReactInstaStories.defaultProps = {
    width: 360,
    height: 640,
    defaultInterval: 4000,
}
export const WithHeader = withHeader
export const WithSeeMore = withSeeMore
export default ReactInstaStories
