import React from 'react'
import styles from 'components/answers/thread-info.module.scss'

interface AnswerFilterButtonProps {
    toggleDropdown: (e: React.MouseEvent) => void
    isActive: boolean
    selectedFilter: string
}

export default function AnswerFilterButton({
    toggleDropdown,
    isActive,
    selectedFilter,
}: AnswerFilterButtonProps) {
    const getFilterIcon = () => {
        switch (selectedFilter) {
            case 'none':
                return 'fa-filter'
            case 'archive':
                return 'fa-folder'
            case 'all':
                return 'fa-eye'
            default:
                return 'fa-filter'
        }
    }

    return (
        <button
            className={`${styles.filterButton} ${styles.threadInfoButton} ${
                isActive ? styles.active : ''
            }`}
            onClick={toggleDropdown}
        >
            <i className={`fa ${getFilterIcon()}`} />
        </button>
    )
}
