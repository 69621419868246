import React from 'react'
import useStore from 'state/knovStore'
import WithTooltip from 'components/shared/WithTooltip'
import Button from 'components/shared/buttons/Button'
import styles from 'components/shared/filter-options.module.scss'

export default function AddStream({ contextStyles, closeStreamSelector }) {
    const openNewStreamModal = useStore(state => state.actions.modalActions.openNewStreamModal)

    return (
        <WithTooltip tip={'Create new stream.'}>
            <div
                className={styles.addStreamComp}
                onClick={() => {
                    openNewStreamModal()
                    closeStreamSelector()
                }}
            >
                <Button icon="fa fa-plus" text="New Stream" contextStyles={contextStyles} />
            </div>
        </WithTooltip>
    )
}
