import React from 'react'
import styles from 'components/spaces/space-icon.module.scss'
import cn from 'classnames'
import { initials } from 'utils'
import useStore from 'state/knovStore'

export default function SpaceIcon(props) {
    useStore(state => state.activeSpaceId)
    const activeSpace = useStore.getState().getActiveSpace() || useStore.getState().knovigatorSpace
    const space = props.space || activeSpace

    return (
        <div className={cn(styles.spaceIconComp, props.contextStyles, 'space-icon-comp')}>
            {space?.space_image_url ? (
                <img src={space?.space_image_url} />
            ) : (
                <div className={cn(styles.spaceInitials, props.initialStyles, 'space-initials')}>
                    {initials(space?.name)}
                </div>
            )}
        </div>
    )
}
