import React from 'react'
const ProgressWrapper = props =>
    React.createElement(
        'div',
        {
            style: Object.assign(
                Object.assign({}, styles.progress),
                getProgressWrapperStyle(props),
            ),
        },
        props.children,
    )
const getProgressWrapperStyle = ({ width, pause }) => ({
    width: `${width * 100}%`,
    opacity: 1,
})
const styles = {
    progress: {
        height: 2,
        maxWidth: '100%',
        background: '#555',
        margin: 2,
        borderRadius: 2,
        transition: 'opacity 400ms ease-in-out',
    },
}
export default ProgressWrapper
