import React from 'react'

export default function SatsIcon({ showBars = true, contextStyles }) {
    return (
        <div className={contextStyles}>
            {true && (
                <svg
                    width="129"
                    height="145"
                    viewBox="0 0 129 145"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    transform="rotate(2)"
                >
                    <path d="M98.286 93.6586C99.0924 98.9737 98.4887 103.812 96.4749 108.174C94.4612 112.537 91.1318 116.192 86.4869 119.141C81.9136 121.992 76.0835 123.956 68.9966 125.031C65.875 125.505 63.2608 125.038 60.1595 125.077C57.1426 125.103 56.3444 124.491 53.4451 124.154C50.6173 123.72 47.3985 123.473 43.2035 120.57L46.4235 117.047C70.4246 128.577 68.052 113.765 68.052 113.765C68.052 113.765 69.535 113.54 71.4374 112.746C73.3398 111.952 74.7478 111.232 76.5002 109.449C78.2525 107.666 78.7214 106.072 79.3475 104.51C80.433 102.278 79.9724 99.2422 79.6908 97.3861C79.3451 95.1082 78.2492 93.2893 76.403 91.9295C74.6412 90.5569 72.3502 89.3941 69.53 88.441C66.697 87.4036 63.4715 86.3394 59.8536 85.2484C57.5908 84.556 55.1337 83.7204 52.4822 82.7418C49.8179 81.6787 47.1932 80.3076 44.6081 78.6284C42.0945 76.852 39.8966 74.5962 38.0143 71.8609C36.1321 69.1256 34.8773 65.691 34.2501 61.5569C33.4309 56.1574 33.9566 51.3738 35.8273 47.206C37.7695 42.9411 40.8649 39.4506 45.1136 36.7345C49.4466 34.0057 54.7347 32.1677 60.9779 31.2204C65.7025 30.5036 74.4024 32.2908 77.1141 33.4977C85.0416 37.0259 82.8317 36.1703 88.5993 39.1994L84.8316 49.3514C80.4415 48.2913 76.5309 47.5468 73.0998 47.118C69.6686 46.6892 66.2235 46.7371 62.7644 47.262C60.4021 47.6204 58.4349 48.3072 56.8628 49.3225C55.2906 50.3379 54.1429 51.5909 53.4197 53.0816C52.7808 54.5594 52.6022 56.2264 52.8838 58.0825C53.2038 60.1917 54.1117 61.9096 55.6077 63.2362C57.0908 64.4785 59.2003 65.5825 61.9361 66.5484C64.6719 67.5143 68.0789 68.6373 72.1571 69.9174C77.1301 71.4069 81.3989 73.0896 84.9632 74.9655C88.612 76.8286 91.5654 79.2287 93.8236 82.1659C96.0817 85.1031 97.5692 88.934 98.286 93.6586Z" />

                    <path
                        d="M72.2465 118.079C70.4999 118.079 54.4216 123.925 36.4583 115.524C24.4999 109.932 12.0385 93.4367 9.99994 80C7.9614 66.5633 5.99997 23.215 58.5 17C77.8648 14.7076 110.111 25.106 116.907 55.7808C120.5 72 116.907 84.5 112 93.5"
                        strokeWidth="10"
                        fill="none"
                    />

                    {showBars && (
                        <>
                            <path
                                d="M63.9999 4L63.9999 20.5L63.9999 34"
                                strokeWidth="10"
                                strokeOpacity="0.9"
                            />
                            <path
                                d="M62.9999 122L62.9999 140"
                                strokeWidth="12"
                                strokeOpacity="0.95"
                            />
                        </>
                    )}
                </svg>
            )}
        </div>
    )
}
