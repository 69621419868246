import React from 'react'
import perms from 'lib/perms'
import styles from 'components/quests/quest-options.module.scss'
import { OptionList } from 'components/quests/QuestOptions'
import api from 'api/api'
import useStore from 'state/knovStore'
import * as cache from 'state/cache'

export default function TypeOptions(props) {
    const quest = props.quest
    const amAdmin = perms.amQuestOwner(quest)

    const updateQuest = useStore(state => state.updateQuest)

    const setThreadType = async options => {
        if (quest.is_draft) {
            const typedQuest = { ...quest, ...options }
            cache.cacheQuest(typedQuest)
        } else {
            const apiQuest = await api.updateQuest(quest.id, options)
            cache.cacheQuest(apiQuest)
        }
    }

    const shortCircuit = () => {
        alert("You do not have permission to change this quest's type.")
    }

    const setKnovGpt = async () => {
        props.close()

        if (amAdmin)
            setThreadType({
                blog: false,
                default_sort_col: 'position',
                default_sort_dir: 'asc',
                thread_type: 'chatgpt',
            })
        else shortCircuit()
    }

    const setBlog = async () => {
        props.close()

        if (amAdmin)
            setThreadType({
                blog: true,
                default_sort_col: 'position',
                default_sort_dir: 'asc',
                thread_type: null,
            })
        else shortCircuit()
    }

    const setConversation = async () => {
        props.close()

        if (amAdmin)
            setThreadType({
                blog: false,
                default_sort_col: 'position',
                default_sort_dir: 'asc',
                thread_type: null,
            })
        else shortCircuit()
    }

    const setQuest = async () => {
        props.close()
        if (amAdmin)
            setThreadType({
                blog: false,
                default_sort_col: 'vote',
                default_sort_dir: 'desc',
                thread_type: null,
            })
        else shortCircuit()
    }

    const selected = () => {
        if (quest.blog) return 'post'
        else if (quest.default_sort_col === 'vote') return 'quest'
        else if (quest.thread_type === 'chatgpt') return 'knovgpt'
        else return 'conversation'
    }

    const options = [
        {
            type: 'icon',
            name: 'Thread',
            value: 'conversation',
            desc: 'Discussion with multiple participants.',
            logo: 'fa fa-comment',
            callback: setConversation,
        },
        {
            type: 'icon',
            name: 'Quest',
            value: 'quest',
            desc: 'Messages ranked by upvalue.',
            logo: 'fa fa-sort-amount-desc',
            callback: setQuest,
        },
        /*
        {
            type: 'icon',
            name: 'Post',
            value: 'post',
            desc: 'Post with a single author.',
            logo: 'fa fa-file',
            callback: setBlog,
        },
        */
    ]

    return (
        <div className={styles.typeOptionsComp}>
            <OptionList options={options} selectedValue={selected()} close={props.close} />
        </div>
    )
}
