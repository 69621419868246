import React from 'react'
import PropTypes from 'prop-types'
import UserIcon from '../users/UserIcon'

export default class VidCommands extends React.Component {
    constructor(props) {
        super(props)

        this.comment = this.props.comment

        this.replay = null
        this.countDownInterval = null
        this.handlePausing = true

        this.state = {
            looping: false,
        }
    }

    player = () => this.props.getYtPlayer()
    startTime = () => this.props.vidStart
    loopDuration = () => this.props.vidLoop

    playVid = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (!this.props.vidLoaded) {
            this.props.loadVid()
            return
        }
        if (!this.player()) return

        this.stopLoop(this.player(), this.comment.id, this.loopDuration())

        this.player().seekTo(this.startTime(), true)
        this.player().playVideo()
    }

    handleManualPause = ev => {
        if (this.handlePausing && ev.data == 2) {
            this.clearLoop(this.player(), this.comment.id, this.loopDuration())
            this.clearCountDown()
        }
    }

    toggleLoop = ev => {
        if (!this.props.vidLoaded) {
            this.props.loadVid()
            return
        }
        if (!this.player()) return

        if (this.state.looping) this.stopLoop(this.player(), this.comment.id, this.loopDuration())
        else this.startLoop(this.startTime(), this.loopDuration())
    }

    startLoop = (start_time, loop_time) => {
        this.setState({
            looping: true,
        })
        this.player().seekTo(parseInt(this.startTime(), 10), true)
        this.player().playVideo()

        this.replay = setInterval(() => {
            this.setState({ loopTime: this.loopDuration() })
            this.loopVid(this.startTime())
            this.clearCountDown()

            this.countDownInterval = setInterval(() => {
                this.countDown()
            }, 1000)
        }, parseInt(this.loopDuration(), 10) * 1000)

        this.countDownInterval = setInterval(() => {
            this.countDown()
        }, 1000)

        this.player().addEventListener('onStateChange', this.handleManualPause)
    }

    countDown = () => {
        let loopTime = this.state.loopTime
        if (loopTime > 1) {
            loopTime = loopTime - 1
        } else {
            clearInterval(this.countDownInterval)
            loopTime = this.loopDuration()
        }

        this.setState({ loopTime: loopTime })
    }

    loopVid = startTime => {
        // Temporarily set hande_pausing to false so that we don't clear the counter styles in handleManualPause().
        this.handlePausing = false

        this.player().pauseVideo()

        this.player().seekTo(parseInt(startTime, 10), true)
        // Set it back to enable manual pausing.
        setTimeout(() => {
            this.player().playVideo()
            this.handlePausing = true
        }, 50)
    }

    stopLoop = (player, commentId, loopTime) => {
        this.setState({
            looping: false,
            loopTime: loopTime,
        })
        this.clearLoop(player, commentId, loopTime)
        this.clearCountDown()
        player.pauseVideo()
        if (this.handleManualPause)
            player.removeEventListener('onStateChange', this.handleManualPause)
    }

    clearLoop = (player, commentId, loopTime) => {
        if (this.replay) clearInterval(this.replay)
        this.clearCountDown()
    }

    clearCountDown = () => {
        if (this.countDownInterval) {
            clearInterval(this.countDownInterval)
        }
    }

    render() {
        return (
            <div className="command comp">
                <span className="command play btn-primary btn-sm btn" onClick={this.playVid}>
                    <i className="fa-play fa" style={{ marginRight: 5 }}></i>
                    <span className="start-time">{this.props.command.params}</span>
                </span>

                {/*<span className={`command loop btn-primary btn-sm btn ${this.state.looping ? 'playing' : ''}`} onClick={this.toggleLoop}>
                    <i className={`fa fa-circle-o-notch ${this.state.looping ? 'fa-spin' : ''}`}></i>
                    <span className='loop-counter'>
                        {this.loopDuration()}s
                    </span>
                </span>
                */}
            </div>
        )
    }
}
