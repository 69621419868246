import React, { useContext } from 'react'
import ProgressCtx from '../contexts/Progress'

export const renderer = props => {
    React.useEffect(() => {
        props.action('play')
    }, [props.story])

    const Content = props.story.originalContent
    return React.createElement(Content, Object.assign({}, props))
}
export const tester = story => {
    return {
        condition: !!story.content,
        priority: 3,
    }
}
export default {
    renderer,
    tester,
}
