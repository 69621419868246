import { QueryClient } from '@tanstack/react-query'

declare global {
    interface Window {
        queryClient: QueryClient
    }
}

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: Infinity,
            gcTime: Infinity,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            structuralSharing: false,
            networkMode: 'always',
        },
    },
})
window.queryClient = queryClient
export default queryClient
