import React from 'react'
import TeamLogo from 'components/shared/icons/TeamLogo'

export default function TeamIcon(props) {
    const team = props.team

    const onClick = ev => {
        if (props.onClick) props.onClick(team.id, ev)
    }

    return (
        <div onClick={onClick} data-tip={props.tip || `${team.name} Group`}>
            <TeamLogo
                team={team}
                contextStyles={props.contextStyles}
                initialStyles={props.initialStyles}
                borderStyles={props.borderStyles}
                quest={props.quest}
            />
        </div>
    )
}
