import React, { useContext, useState, useRef, useEffect } from 'react'
import GlobalContext from './contexts/Global'
import StoriesContext from './contexts/Stories'
import ProgressContext from './contexts/Progress'
import Story from './Story'
import ProgressArray from './ProgressArray'
export default function () {
    let mousedownId = useRef()
    const { width, height, loop, currentIndex, isPaused, keyboardNavigation } =
        useContext(GlobalContext)
    const { currentId, pause, next, setVideoDuration, setPause, previous, toggleState } =
        useContext(ProgressContext)
    const { stories } = useContext(StoriesContext)
    useEffect(() => {
        if (typeof currentIndex === 'number') {
            if (currentIndex >= 0 && currentIndex < stories.length) {
                setCurrentIdWrapper(() => currentIndex)
            } else {
                console.error(
                    'Index out of bounds. Current index was set to value more than the length of stories array.',
                    currentIndex,
                )
            }
        }
    }, [currentIndex])

    useEffect(() => {
        if (typeof isPaused === 'boolean') {
            setPause(isPaused)
        }
    }, [isPaused])

    useEffect(() => {
        const isClient = typeof window !== 'undefined' && window.document
        if (isClient && typeof keyboardNavigation === 'boolean' && keyboardNavigation) {
            document.addEventListener('keydown', handleKeyDown)
            return () => {
                document.removeEventListener('keydown', handleKeyDown)
            }
        }
    }, [keyboardNavigation])
    const handleKeyDown = e => {
        if (e.key === 'ArrowLeft') {
            previous()
        } else if (e.key === 'ArrowRight') {
            next()
        }
    }

    const debouncePause = e => {
        e.stopPropagation()
        mousedownId.current = setTimeout(() => {
            toggleState('pause')
        }, 200)
    }

    const clickPause = e => {
        if (e.target !== e.currentTarget) return false
        e.stopPropagation()
        togglePause()
    }

    const togglePause = () => {
        if (pause) toggleState('play')
        else toggleState('pause')
    }

    const mouseUp = (e, type) => {
        e.preventDefault()
        e.stopPropagation()
        mousedownId.current && clearTimeout(mousedownId.current)
        toggleState('play')
        type === 'next' ? next() : previous()
    }
    const getVideoDuration = duration => {
        setVideoDuration(duration * 1000)
    }
    return (
        <div style={Object.assign({}, styles.container, { width, height })}>
            <ProgressArray />
            <Story
                action={toggleState}
                playState={pause}
                story={stories[currentId]}
                getVideoDuration={getVideoDuration}
                key={currentId}
            />
            <div className="story-control-container" style={styles.overlay} onClick={clickPause}>
                <div
                    className="story-control story-left-control"
                    style={{ width: '20%', zIndex: 999 }}
                    onTouchStart={debouncePause}
                    onTouchEnd={e => mouseUp(e, 'previous')}
                    onMouseDown={debouncePause}
                    onMouseUp={e => mouseUp(e, 'previous')}
                />
                <div
                    className="story-control story-right-control"
                    style={{ width: '20%', zIndex: 999 }}
                    onTouchStart={debouncePause}
                    onTouchEnd={e => mouseUp(e, 'next')}
                    onMouseDown={debouncePause}
                    onMouseUp={e => mouseUp(e, 'next')}
                />
            </div>
        </div>
    )
}
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        background: '#111',
        position: 'relative',
    },
    overlay: {
        position: 'absolute',
        height: 'inherit',
        width: 'inherit',
        display: 'flex',
        justifyContent: 'space-between',
    },
}
