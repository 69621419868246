import React from 'react'
import ReactDOM from 'react-dom'
import styles from 'components/answers/lock-control.module.scss'
import LockVote from 'components/votes/LockVote'
import { SATOSHIS } from 'lib/bsv-util'
import SatsIcon from 'components/shared/SatsIcon'
import cn from 'classnames'
import api from 'api/api'
import useStore from 'state/knovStore'
import useStreamFilters from 'components/filters/useStreamFilters'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useBlockheight from 'api/useBlockheight'
import { IBsvLock, IBsvUpvalue } from 'types/answers'

// calculate total value signals (locks + upvalues) for an answer
export const calculateAnswerValueSignals = (answer: any, blockheight: number) => {
    const lockAmount = (answer?.bsv_locks || []).reduce((sum, lock) => {
        // only include locks that are at or below the current block height
        return lock.block_height >= blockheight ? sum + parseInt(lock.amount, 10) : sum
    }, 0)
    const upvalueAmount =
        answer?.bsv_upvalues?.reduce((sum, upvalue) => sum + parseInt(upvalue.amount, 10), 0) || 0
    return lockAmount + upvalueAmount
}

// calculate total value signals for multiple answers
export const calculateThreadValueSignals = (answers: any[], blockheight: number) => {
    return (
        answers?.reduce((acc, answer) => {
            return acc + calculateAnswerValueSignals(answer, blockheight)
        }, 0) || 0
    )
}

// get answers that have any value signals (locks or upvalues)
export const filterAnswersWithValueSignals = (answers: any[]) => {
    return (
        answers?.filter(
            answer => answer?.bsv_locks?.length > 0 || answer?.bsv_upvalues?.length > 0,
        ) || []
    )
}

export default function LockControl({ answer }) {
    const postLockRef = React.useRef<HTMLDivElement>(null)
    const blockheight = useBlockheight()
    const lockedSats = calculateAnswerValueSignals(answer, blockheight)

    const [showPopover, _setShowPopover] = React.useState(false)
    const setShowPopover = async val => {
        _setShowPopover(val)
        if (val) {
            // to make sure lock content is fresh
            await api.getAnswer(answer.id)
        }
    }

    return (
        <div className={styles.bsvLockContainer}>
            <div ref={postLockRef} className={styles.bsvLockAmountContainer}>
                <>
                    <div
                        onClick={ev => {
                            ev.stopPropagation()
                            setShowPopover(true)
                        }}
                    >
                        <LockAmount lockedSats={lockedSats} />
                    </div>

                    {showPopover &&
                        ReactDOM.createPortal(
                            <BsvValueSignals
                                answers={[answer]}
                                postLockRef={postLockRef}
                                setShowPopover={setShowPopover}
                            />,
                            document.body,
                        )}
                </>
            </div>

            <LockVote postLockRef={postLockRef} answer={answer} />
        </div>
    )
}

function LockAmount({ lockedSats }) {
    return (
        <div className={styles.lockAmount}>
            {lockedSats > 0 && lockedSats / SATOSHIS > 1 && (
                <div className={styles.bitcoinBar}>
                    <span className={cn(styles.bitcoinSymbol, !lockedSats ? styles.dormant : null)}>
                        <i className="fa fa-btc" style={{ marginRight: '2px' }}></i>
                    </span>

                    <div
                        className={cn(
                            styles.lockVoteAmount,
                            styles.bitcoin,
                            !lockedSats ? styles.dormant : null,
                        )}
                    >
                        <NumberWithSpaces number={Math.floor(lockedSats / SATOSHIS)} px={2} />
                    </div>
                </div>
            )}

            {lockedSats > 0 && lockedSats % SATOSHIS > 0 && (
                <div className={styles.satsBar}>
                    <SatsIcon
                        showBars={true}
                        contextStyles={cn(
                            styles.satsSymbol,
                            !lockedSats ? styles.dormantSatsSymbol : null,
                        )}
                    />

                    <div
                        className={cn(
                            styles.lockVoteAmount,
                            styles.sats,
                            !lockedSats ? styles.dormant : null,
                        )}
                    >
                        <>
                            <NumberWithSpaces number={lockedSats % SATOSHIS} px={2} />
                        </>
                    </div>
                </div>
            )}

            {lockedSats === 0 && (
                <div className={styles.emptyBar}>
                    <SatsIcon
                        showBars={true}
                        contextStyles={cn(styles.satsSymbol, styles.dormantSatsSymbol)}
                    />

                    <div className={cn(styles.lockVoteAmount, styles.sats, styles.dormant)}>0</div>
                </div>
            )}
        </div>
    )
}

export function BsvValueSignals({ answers, postLockRef, setShowPopover }) {
    const answersArray = Array.isArray(answers) ? answers : [answers]
    const blockheight = useBlockheight()
    const allLocks = answersArray.flatMap(ans => (ans?.bsv_locks || []) as IBsvLock[])
    const allUpvalues = answersArray.flatMap(ans => (ans?.bsv_upvalues || []) as IBsvUpvalue[])
    const totalSignals = allLocks.length + allUpvalues.length

    // Split locks into active and expired
    const activeLocks = allLocks.filter(
        lock => blockheight <= lock.locked_at_block + Number(lock.num_blocks),
    )
    const expiredLocks = allLocks.filter(
        lock => blockheight > lock.locked_at_block + Number(lock.num_blocks),
    )

    const renderLockItem = (lock: IBsvLock, isExpired: boolean) => (
        <li key={lock.id} style={isExpired ? { textDecoration: 'line-through' } : undefined}>
            <a
                href={`https://whatsonchain.com/tx/${lock.tx_id}`}
                target="_blank"
                rel="noopener noreferrer"
                onClick={ev => ev.stopPropagation()}
                title={lock?.created_at ? new Date(lock.created_at).toLocaleString() : 'unknown'}
            >
                <i className="fa fa-cube"></i>
            </a>
            <UserHandleFromId userId={lock.user_id} afterClick={() => setShowPopover(false)} />
            {isExpired ? '🔓' : '🔒'} locked {Number(lock?.amount)?.toLocaleString()} sats for{' '}
            {lock?.block_height - lock?.locked_at_block} blocks
        </li>
    )

    return (
        <div
            className={styles.popover}
            ref={node => {
                // make sure the locks show onscreen and are positioned properly and that they are
                // scrollable if there are a lot
                if (!node || !postLockRef.current) return

                const rect = postLockRef.current.getBoundingClientRect()
                const viewportHeight = window.innerHeight

                let left = rect.left + window.scrollX
                const rightEdge = left + node.offsetWidth
                if (rightEdge > window.innerWidth) {
                    left = window.innerWidth - node.offsetWidth - 10
                }
                node.style.left = `${left}px`

                let top = rect.bottom + 5 + window.scrollY
                const bottomEdge = top + node.offsetHeight
                const viewportBottom = window.scrollY + viewportHeight

                if (bottomEdge > viewportBottom) {
                    const topPosition = rect.top + window.scrollY - node.offsetHeight - 5
                    if (topPosition > window.scrollY) {
                        top = topPosition
                    }
                }
                node.style.top = `${top}px`
            }}
        >
            <div
                onClick={e => {
                    e.stopPropagation()
                    setShowPopover(false)
                }}
                className={styles.closeButton}
            >
                &#215;
            </div>
            <p>💰 {totalSignals} Value Signals</p>
            <div className={styles.locksList}>
                <ul>
                    {allUpvalues.map((upvalue, index) => (
                        <li key={`upvalue-${index}`}>
                            <a
                                href={`https://whatsonchain.com/tx/${upvalue.tx_id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={ev => ev.stopPropagation()}
                                title={
                                    upvalue?.created_at
                                        ? new Date(upvalue.created_at).toLocaleString()
                                        : 'unknown'
                                }
                            >
                                <i className="fa fa-cube"></i>
                            </a>
                            <UserHandleFromId
                                userId={upvalue.from_user_id}
                                afterClick={() => setShowPopover(false)}
                            />
                            <i className="fa fa-arrow-up"></i> Upvalued{' '}
                            {Number(upvalue?.amount)?.toLocaleString()} sats
                        </li>
                    ))}
                    {activeLocks.map(lock => renderLockItem(lock, false))}
                    {expiredLocks.map(lock => renderLockItem(lock, true))}
                </ul>
            </div>
        </div>
    )
}

const UserHandleFromId = ({ userId, afterClick }) => {
    const users = useStore(state => state.users)
    const { panel } = usePanelContext()
    const { selectUser } = useStreamFilters(panel?.id, { append: true })
    const onSelectUser = ev => {
        ev.preventDefault()
        ev.stopPropagation()
        selectUser(userId)
        afterClick()
    }
    const user = users?.find(user => user.id === userId)
    return <a onClick={onSelectUser}>@{user?.name}</a>
}

const NumberWithSpaces = ({ number, px }) => {
    let numberParts: string[] = []
    if (number === 0) {
        numberParts.push('0')
    } else {
        while (number > 0) {
            let part = number % 1000
            numberParts.unshift(part.toString().padStart(3, '0'))
            number = Math.floor(number / 1000)
        }
    }

    if (numberParts.length >= 1) {
        numberParts[0] = numberParts[0].replace(/^0+/, '')
    }

    return (
        <span>
            {numberParts.map((part, index) => (
                <React.Fragment key={index}>
                    {index > 0 && <span style={{ marginRight: `${px}px` }}></span>}
                    <span>{part}</span>
                </React.Fragment>
            ))}
        </span>
    )
}

export { NumberWithSpaces, LockAmount }
