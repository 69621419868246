import React from 'react'
import WithTooltip from 'components/shared/WithTooltip'
import styles from 'components/answers/answer-controls.module.scss'
import cn from 'classnames'
import { isMobile } from 'react-device-detect'

export default React.forwardRef((props, ref) => {
    const answerHoverStyles = props.answerHover ? styles.hoverBtn : null
    const mobileStyles = isMobile ? styles.mobileBtn : null

    return (
        <div
            id={`answer-file-upload-${props.btnId}`}
            className={cn(
                styles.editMediaBtn,
                answerHoverStyles,
                styles.imgHover,
                styles.addFileBtn,
                mobileStyles,
            )}
            onClick={ev => ev.stopPropagation()}
        >
            <label htmlFor={`answer-file-input-${props.btnId}`}>
                <WithTooltip tip="Upload file.">
                    <div className="quest-btn file-btn">
                        <i
                            id={`answer-file-btn-${props.btnId}`}
                            className={cn(
                                styles.fileUploadIcon,
                                'fa fa-paperclip file-upload-control',
                            )}
                        />
                    </div>
                </WithTooltip>
            </label>

            <input
                style={{
                    height: 0,
                    width: 0,
                    top: 30000,
                    left: 30000,
                    position: 'absolute',
                    margin: 0,
                    padding: 0,
                    display: 'none',
                }}
                id={`answer-file-input-${props.btnId}`}
                ref={ref}
                className="answer-file"
                name="answer_file"
                type="file"
                data-answer-id={props.answer?.id}
                onChange={props.fileHandler}
            />
        </div>
    )
})
