import React from 'react'
import useStore from 'state/knovStore'

const Connector =
    (stateSelector, getConnectorProps = () => ({})) =>
    WrappedComponent =>
        React.forwardRef((props, ref) => {
            const connectorProps = getConnectorProps(props)
            const selectedState = useStore(state =>
                stateSelector(state, { ...connectorProps, ...props }),
            )

            return <WrappedComponent ref={ref} {...props} {...selectedState} {...connectorProps} />
        })

export default Connector
