// HACK TO CLEAN UP insert null bug for entity quests.
const cleanDeltaJson = deltaJson => {
    if (deltaJson && deltaJson.ops) {
        const newDeltaJson = { ...deltaJson, ops: [...deltaJson.ops] }

        for (var op of newDeltaJson.ops) {
            if (typeof op?.insert !== undefined && op?.insert === null) {
                op = { insert: '' }
            }
        }
        return newDeltaJson
    }
    return deltaJson
}

const removeTrailingNewlines = deltaJson => {
    if (!deltaJson) return null

    let newDeltaJson = { ...deltaJson, ops: [...deltaJson.ops] }
    let ops = newDeltaJson?.ops
    if (ops) {
        // Cleanup trailing blank inserts.
        let lastInsertString = ops[ops.length - 1]?.insert
        while (typeof lastInsertString === 'string' && lastInsertString?.trim() === '') {
            ops.pop()
            lastInsertString = ops[ops.length - 1]?.insert
        }

        // Cleanup and trailing newlines in non-blank insert string.
        if (typeof lastInsertString === 'string') {
            ops.pop()
            ops.push({ insert: lastInsertString.trimEnd() })
        }
    }
    return newDeltaJson
}

const getValue = answer => {
    let value = null
    if (answer?.delta_json) {
        value = cleanDeltaJson({ ...answer.delta_json })
    } else {
        value = answer?.content
    }
    return value
}

const getText = deltaJson => {
    if (deltaJson) {
        const ops = deltaJson['ops'] || []
        return ops
            .filter(op => typeof op.insert === 'string' || op?.insert?.mention)
            .map(op => (op?.insert.mention ? '@' + op.insert.mention.content : op?.insert))
            .join('')
            .trim()
    } else {
        return null
    }
}

const getImages = deltaJson => {
    if (deltaJson) {
        const ops = deltaJson['ops'] || []
        const images = ops.filter(op => op?.insert?.image).map(op => op.image)
        return images?.length > 0 ? images : null
    } else {
        return null
    }
}

const isEmpty = deltaJson => {
    if (deltaJson) {
        const ops = deltaJson['ops'] || []
        return ops.length === 0 || (ops.length === 1 && ops[0].insert?.trim() === '')
    } else return true
}

export { getText, getImages, getValue, isEmpty, removeTrailingNewlines }
