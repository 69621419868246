import React from 'react'
import styles from 'components/answers/answer-controls.module.scss'
import cn from 'classnames'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'

export default React.forwardRef((props, ref) => {
    const answerHoverStyles = props.answerHover ? styles.hoverBtn : null
    const mobileStyles = isMobile ? styles.mobileBtn : null

    return (
        <>
            <div
                className={cn(
                    styles.editMediaBtn,
                    answerHoverStyles,
                    styles.videoHover,
                    styles.mobileVideoBtn,
                    mobileStyles,
                )}
                onClick={props.onClick}
            >
                <label htmlFor={props.btnId}>
                    <WithTooltip tip={'Record video.'}>
                        <i className="fa fa-video-camera"></i>
                    </WithTooltip>
                </label>

                <input
                    style={{
                        width: 0,
                        height: 0,
                        top: 30000,
                        left: 30000,
                        position: 'absolute',
                        display: 'none',
                        margin: 'auto',
                    }}
                    id={props.btnId}
                    ref={ref}
                    className="new-answer-video-input"
                    type="file"
                    onChange={props.videoHandler}
                    accept="video/*"
                    capture="environment"
                />
            </div>
        </>
    )
})
