import React from 'react'
import cn from 'classnames'
import useStore from 'state/knovStore'
import api from 'api/api'
import styles from '../lock-vote.module.scss'
import { SignalType } from 'types/signals'

interface SaveButtonProps {
    save: SignalType
    customSats: number | null
    customBlocks?: number
    quickLockRef: React.RefObject<any>
    animateLock: (ref: React.RefObject<any>, scale?: number) => void
}

export function SaveButton({
    save,
    customSats,
    customBlocks,
    quickLockRef,
    animateLock,
}: SaveButtonProps) {
    return (
        <div className={styles.saveButtonContainer}>
            <div
                className={cn(
                    styles.saveButton,
                    save === 'upvalue'
                        ? styles.saveUpvalue
                        : save === 'boost'
                        ? styles.saveBoost
                        : styles.saveLock,
                )}
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()

                    const wholeSats = Math.round(customSats)
                    if (
                        typeof wholeSats === 'number' &&
                        wholeSats >= 1 &&
                        (save === 'upvalue' ||
                            save === 'boost' ||
                            (typeof customBlocks === 'number' && customBlocks > 0))
                    ) {
                        if (save === 'boost') {
                            // Boost is the same as upvalue but when upvaluing own content.
                            // We update upvalueSats to keep a single upvalue value.
                            useStore.setState({
                                signalType: 'boost',
                                upvalueSats: wholeSats,
                            })
                            api.updateUserSpaceOptions({
                                signalType: 'upvalue',
                                upvalueSats: wholeSats,
                            })
                        } else if (save === 'upvalue') {
                            useStore.setState({
                                signalType: 'upvalue',
                                upvalueSats: wholeSats,
                            })
                            api.updateUserSpaceOptions({
                                signalType: 'upvalue',
                                upvalueSats: wholeSats,
                            })
                        } else {
                            useStore.setState({
                                signalType: 'lock',
                                lockSats: wholeSats,
                                lockBlocks: customBlocks,
                            })
                            api.updateUserSpaceOptions({
                                signalType: 'lock',
                                lockSats: wholeSats,
                                lockBlocks: customBlocks,
                            })
                        }

                        animateLock(quickLockRef)
                    }
                }}
            >
                <i className="fa fa-save" />
                <span className={styles.saveQuickLockButtonLabel}>Save</span>
            </div>
        </div>
    )
}
