import React, { useState } from 'react'
import ImageInput from 'components/shared/ImageInput'
import { accessTokens } from 'api/accessTokenMgmt'
import styles from 'components/modals/new-stream.module.scss'
import useStore from 'state/knovStore'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import api from 'api/api'
import { uniqBy, defer } from 'lodash'

export default function NewStream({ modalParams, closeModal }) {
    const navigate = useNavigate()
    const set = useStore(state => state.set)
    const activeSpaceId = useStore(state => state.activeSpaceId)
    const userStreams = useStore(state => state.userStreams)

    const [{ alt, src }, setImg] = useState({
        src: '',
        alt: '',
    })

    const handleImg = e => {
        if (e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name,
            })
        }
    }
    const handleSubmit = async e => {
        e.preventDefault()
        const form = e.target
        const data = new FormData(form)
        // TODO: move this to api.ts
        const response = await fetch(gon.api.teams, {
            method: 'POST',
            body: data,
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
            },
        })
        const output = await response.json()
        //console.log('>>> Received newstream response: ', output)

        if (output?.team) {
            const team = output.team
            const path = `/stream/${output?.team?.id}`
            // TODO prolly dont need to await on this options update... the path should override any outdated user settings.
            await api.updateUserSpaceOptions({ last_path: path, filter: { team_ids: [team.id] } })
            set({
                userStreams: uniqBy([...userStreams, team], s => s.id),
                stream: team,
                streamId: team.id,
            })
            defer(() => navigate(path))
            closeModal()
        }
    }

    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            {/* <input placeholder="Type the name of a collection." className="collection-name" />

             */}

            {gon.currentUser && <input type="hidden" name="admin_id" value={gon.currentUser.id} />}

            {activeSpaceId && <input type="hidden" name="space_id" value={activeSpaceId} />}

            <div className="team-input-group">
                <label className={styles.label} htmlFor="name">
                    Stream Name
                </label>
                <input
                    className={cn(styles.input, 'team-input')}
                    id="name"
                    name="name"
                    required={true}
                />
            </div>

            <ImageInput
                src={src}
                alt={alt}
                name="team_image"
                handleImg={handleImg}
                label="Stream Logo"
            />

            <div className="btn-container">
                <button className="btn btn-primary reload-btn" type="submit">
                    Submit
                </button>
            </div>
        </form>
    )
}
