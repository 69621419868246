import React, { useState } from 'react'
import HeroImage from 'components/auth/HeroImage.jsx'
import { Link } from 'react-router-dom'
import flashStyles from 'components/shared/flash.module.scss'
import styles from 'components/auth/request-password-reset.module.scss'

function RequestPasswordReset() {
    const [email, setEmail] = useState('')
    const [success, setSuccess] = useState()
    const [message, setMessage] = useState('')

    const handleSubmit = async event => {
        event.preventDefault()

        try {
            const response = await fetch(
                `${window.knovApiUrl}/api/v1/users/request-password-reset`,
                {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ email }),
                },
            )
            const data = await response.json()

            if (response.ok) {
                setSuccess(true)
                setMessage('Check your email for a link to reset your password')
            } else {
                setSuccess(false)
                setMessage(data.error)
            }
        } catch (error) {
            // handle your error here
            console.error(error)
        }
    }

    const statusStyles = message && success ? flashStyles.success : flashStyles.error

    return (
        <div className="sessions_new landing">
            <div className="earth container-fluid">
                <div className="row">
                    <HeroImage />
                </div>

                <div className="sign-in-form col-md-offset-2 col-md-8">
                    <div className={styles.statusContainer}>
                        <div className={statusStyles}>{message}</div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="sign-in-fields">
                            <div className="field">
                                <span className="field-label">Email</span>

                                <input
                                    className="sign-in-email-field form-control"
                                    type="email"
                                    value={email}
                                    placeholder="example@example.com"
                                    autoComplete="email"
                                    onChange={e => setEmail(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="sign-in-btn-container">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg sign-in-btn"
                                >
                                    Send Reset Link
                                </button>
                            </div>

                            <div className="auth-links">
                                {'Already have an account?'}
                                <Link className="devise-shared-signup" to={`/login`}>
                                    {' '}
                                    Login{' '}
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default RequestPasswordReset
