import React, { useState } from 'react'
import useStore from 'state/knovStore'
import styles from 'components/quests/quest-options.module.scss'
import cn from 'classnames'
import perms from 'lib/perms'
import PermsOptions from 'components/quests/PermsOptions'
import ExtendedOptions from 'components/quests/ExtendedOptions'
import TypeOptions from 'components/quests/TypeOptions'

export default function QuestOptions(props) {
    const quest = props.quest
    const amAdmin = perms.amQuestOwner(quest)

    return (
        <React.Fragment>
            <div className={styles.questOptionsComp}>
                <div className={cn(styles.optionsContainer)}>
                    {amAdmin && props.showPermsOptions && (
                        <PermsOptions
                            quest={quest}
                            panel={props.panel}
                            close={props.closeQuestOptions}
                            showHeader={true}
                        />
                    )}

                    {props.showExtendedOptions && (
                        <ExtendedOptions
                            quest={quest}
                            deleteHandler={props.deleteQuest}
                            muteQuestUser={props.muteQuestUser}
                            tweet={props.tweet}
                            close={props.closeQuestOptions}
                        />
                    )}

                    {amAdmin && props.showTypeOptions && (
                        <TypeOptions
                            quest={quest}
                            panel={props.panel}
                            close={props.closeQuestOptions}
                        />
                    )}

                    {props.showFilterDropdown && (
                        <OptionList
                            options={props.answerFilterOptions}
                            selectedValue={props.answerFilter}
                            close={props.closeQuestOptions}
                            showHeader={true}
                            title="Set Message Filter"
                        />
                    )}
                </div>
            </div>
        </React.Fragment>
    )
}

function OptionList(props) {
    //console.log('selectedValue', props.selectedValue)
    const closeQuestOptions = ev => {
        ev.stopPropagation()
        ev.preventDefault()

        if (props.close) props.close()
    }

    return (
        <div className={styles.optionsListComp}>
            {props.showHeader && (
                <div className={styles.questOptionsHeader}>
                    <div className={styles.questOptionsTitle}>{props.title}</div>

                    {props.close && (
                        <div className={styles.questOptionsToggle} onClick={closeQuestOptions}>
                            <i className="fa fa-close"></i>
                        </div>
                    )}
                </div>
            )}

            {props.options
                //.filter(opt => props.selectedOption ? opt.value !== props.selectedOption.value : true)
                .map((opt, ix) => {
                    const Comp = opt.customComp ? (
                        opt.customComp
                    ) : (
                        <Option
                            opt={opt}
                            close={props.close}
                            isSelected={opt.value === props.selectedValue}
                        />
                    )
                    return (
                        <div key={ix} className={styles.optionContainer}>
                            {Comp}
                        </div>
                    )
                })}
        </div>
    )
}

function Option(props) {
    const opt = props.opt

    const onClick = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (opt.callback) opt.callback(opt.value)
        if (opt.closeOnClick && props.close) props.close()
    }

    const selectedStyles = props.isSelected ? styles.selected : null

    return (
        <div className={cn(styles.optionComp, selectedStyles)}>
            <div className={styles.optionContainer}>
                <div className={styles.option} onClick={onClick}>
                    {opt.type === 'icon' ? (
                        <span className={styles.optionIcon}>
                            <i className={opt.logo}></i>
                        </span>
                    ) : (
                        <span className={styles.optionImage}>
                            <img src={opt.logo} />
                        </span>
                    )}

                    <span className={styles.name}>{opt.name}</span>

                    <span className={styles.description}>{opt.desc}</span>
                </div>
            </div>
        </div>
    )
}

function Search(props) {
    return (
        <div className="launch-search search-control control">
            <WithTooltip tip={'Launch Google search.'}>
                <div className="question-btn" onClick={props.launchSearch}>
                    <i className="fa fa-google"></i>
                    <span className="question-control-label">search</span>
                </div>
            </WithTooltip>
        </div>
    )
}

export { OptionList, Option }
