import React from 'react'
import styles from 'components/shared/icons/icons.module.scss'
import cn from 'classnames'
export default function NotiIcon(props) {
    const numNoti = props.numNoti
    const notiActive =
        props.canActive && numNoti > 0 ? props.activeStyles || styles.notiActive : null
    //const { selectNotifications } = useStreamFilters(props.filter, props.panel)

    return (
        <div className={cn(styles.iconComp, styles.notiIcon, props.contextStyles, notiActive)}>
            {props.showIcon && <i className={cn(styles.bell, 'fa fa-bell')} />}

            {props.showNumNoti && numNoti > 0 && (
                <span className={cn(styles.counter, props.absolute && styles.absolute)}>
                    {numNoti}
                </span>
            )}
        </div>
    )
}
