import React, { useState, useEffect, useRef } from 'react'
import useStore from 'state/knovStore'
import CommonEditor from '../shared/CommonEditor'
import { getValue } from '../../lib/value'

const recordingTimeMS = 140000
const almostDoneSecs = 31
let recorder = null
let previewReady

export default function VideoRecorder(props) {
    const closeModal = useStore(state => state.actions.modalActions.closeModal)

    //const [recording, setRecording] = useState(true)

    //let preview = document.getElementById("preview");
    let preview = useRef(null)
    let stopButton = useRef(null)
    let countInterval
    let statsUpdateInterval
    //let logElement = document.getElementById("log");

    const [counter, setCounter] = useState(recordingTimeMS / 1000)
    const [blobTime, setBlobTime] = useState(0)
    const [blobSize, setBlobSize] = useState(0)
    //const [previewLoaded, setPreviewLoaded] = useState(false)
    var isSafari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
    var iOS =
        /iPad|iPhone|iPod/.test(navigator.platform) ||
        (navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1)

    useEffect(() => {
        if (window.MediaRecorder && !(isSafari && iOS)) {
            console.log('preview ready')

            previewReady = navigator.mediaDevices
                .getUserMedia({
                    video: true,
                    audio: true,
                })
                .then(stream => {
                    preview.current.srcObject = stream
                    //downloadButton.href = stream;
                    preview.current.captureStream =
                        preview.current.captureStream || preview.current.mozCaptureStream
                    return new Promise(resolve => (preview.current.onplaying = resolve))
                })

            previewReady
                .then(async () => {
                    // Add a delay before recording so as not to record initial black frames.
                    await new Promise(r => setTimeout(r, 500))

                    countInterval = setInterval(() => {
                        setCounter(counter => counter - 1)
                        setBlobTime(blobTime => blobTime + 1)
                    }, 1000)

                    return startRecording(
                        preview.current.captureStream(),
                        recordingTimeMS,
                        countInterval,
                        statsUpdateInterval,
                    )
                })
                .then(async recordedChunks => {
                    let recordedBlob = new Blob(recordedChunks, { type: 'video/webm' })
                    //recording.current.src = URL.createObjectURL(recordedBlob);
                    //downloadButton.href = recording.current.src;
                    //downloadButton.download = "RecordedVideo.webm";
                    closeModal()
                    console.log(
                        'Successfully recorded ' +
                            recordedBlob.size +
                            ' bytes of ' +
                            recordedBlob.type +
                            ' media.',
                    )
                    props.modalParams.videoHandler(recordedBlob)
                })
                .catch(console.log)

            return () => {
                unmount(countInterval, statsUpdateInterval)
            }
        }
    }, [countInterval])

    useEffect(() => {
        const videoFile = document.getElementById('videoFile')
        if (videoFile) {
            videoFile.click()
        }
    }, [])

    function unmount(countInterval, statsInterval) {
        if (preview?.current?.srcObject)
            stop(preview.current.srcObject, countInterval, statsInterval)
    }

    function wait(delayInMS) {
        return new Promise(resolve => setTimeout(resolve, delayInMS))
    }

    function startRecording(stream, lengthInMS, countInterval, statsInterval) {
        let data = []

        recorder = new MediaRecorder(stream)
        recorder.ondataavailable = event => {
            data.push(event.data)

            setBlobSize(blobSize => blobSize + event.data.size)
        }

        recorder.start()

        statsInterval = setInterval(() => {
            recorder.requestData()
        }, 1000)

        console.log(recorder.state + ' for ' + lengthInMS / 1000 + ' seconds...')

        let stopped = new Promise((resolve, reject) => {
            recorder.onstop = resolve
            recorder.onerror = event => reject(event.name)
        })

        wait(lengthInMS).then(() => {
            if (preview && preview.current)
                stop(preview.current.srcObject, countInterval, statsInterval)
        })

        return stopped.then(() => {
            clearInterval(statsInterval)
            return data
        })
    }

    function stop(stream, interval, statsInterval) {
        if (interval) clearInterval(interval)
        if (statsInterval) clearInterval(statsInterval)
        if (statsUpdateInterval) clearInterval(statsUpdateInterval)
        recorder.state == 'recording' && recorder.stop()
        stream.getTracks().forEach(track => track.stop())
    }

    function onStop(ev) {
        stop(preview.current.srcObject)
    }

    const almostDoneClass = parseInt(counter, 10) < almostDoneSecs ? 'almost-done' : ''
    let recordingModalBody

    recordingModalBody = (
        <div className="video-recorder-comp">
            {props.modalParams.answer && (
                <div className="video-answer-content">
                    <CommonEditor value={getValue(props.modalParams.answer)} readOnly={true} />
                </div>
            )}
            <video
                ref={preview}
                className={`answer-video-preview`}
                width="100%"
                height="auto"
                autoPlay
                muted
            />
            {/*<video ref={recording} className={`answer-video-recording`} width="100%" height="auto" autoPlay controls />*/}
            {/*<div ref={startButton} className='btn btn-default' onClick={onStart}>Start</div>*/}
            <div className="video-controls">
                <div ref={stopButton} className="btn stop-btn" onClick={onStop}>
                    <i className="fa fa-stop"></i>
                </div>
                <div className={`counter ${almostDoneClass}`}>{counter}</div>
                <div className="twitter-container">
                    <div className="twitter-grid">
                        <div>Stats</div>
                        <div>Video duration: {blobTime}/120s</div>
                        <div>Video size: {(blobSize / 1000000).toFixed(3)}/512MB</div>
                    </div>
                </div>
            </div>
        </div>
    )

    return recordingModalBody
}
