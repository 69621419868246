import React from 'react'
import cn from 'classnames'
import styles from './quest-header.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import { Time } from 'components/users/UserName'
import { CryptoVoteTotal } from 'components/votes/Vote'
import Perms from 'components/quests/Perms'
import ThreadControl from 'components/quests/Quest/ThreadControl'
import questModel from 'components/quests/questModel'

const QuestHeader = ({
    quest,
    filter,
    panel,
    showPermsOptions,
    setShowPermsOptions,
    showExtendedOptions,
    setShowExtendedOptions,
    aggCryptoVoteTotal,
    lastAnswerCreatedAt,
    isDraft,
    systemUser,
    showStatus,
    isBsv,
    onClickTitle,
}) => {
    const questFilter = filter?.questId ? questModel.getFilter(quest) : filter

    const showStreamRoot =
        questFilter?.public ||
        questFilter?.treechat ||
        questFilter?.hodlocker ||
        questFilter?.twetch

    return (
        <div className={cn(styles.questHeaderComp)} onClick={onClickTitle}>
            <div className={styles.threadControlContainer}>
                <div className={styles.leftControlContainer}>
                    {showStreamRoot && (
                        <div className={styles.streamRootPath}>
                            <Perms
                                quest={quest}
                                filter={filter}
                                panel={panel}
                                showPermsOptions={showPermsOptions}
                                setShowPermsOptions={setShowPermsOptions}
                                isBsv={isBsv}
                                isRoot={true}
                                contextStyles={styles.streamRoot}
                            />
                            <div className={styles.breadSlash}>/</div>
                        </div>
                    )}
                    <div className={styles.permsContainer}>
                        <Perms
                            quest={quest}
                            filter={filter}
                            panel={panel}
                            showPermsOptions={showPermsOptions}
                            setShowPermsOptions={setShowPermsOptions}
                            isBsv={isBsv}
                        />
                    </div>
                </div>

                {true && (
                    <div className={styles.rightControlContainer}>
                        <div className={styles.cornerControlsContainer}>
                            {!isDraft && (
                                <>
                                    {aggCryptoVoteTotal > 0 && (
                                        <>
                                            <div className={styles.cryptoVoteContainer}>
                                                <WithTooltip tip="Aggregate thread upvalue.">
                                                    <CryptoVoteTotal
                                                        voteTotal={aggCryptoVoteTotal}
                                                        votableId={quest.id}
                                                        votableType={'Quest'}
                                                        visible={systemUser ? 'visible' : ''}
                                                        color={null}
                                                    />
                                                </WithTooltip>
                                            </div>
                                            {aggCryptoVoteTotal > 0 && '|'}
                                        </>
                                    )}

                                    <div className={styles.timeContainer}>
                                        <Time
                                            time={lastAnswerCreatedAt}
                                            tip={'Latest thread message.'}
                                        />
                                    </div>

                                    <div className={styles.typeControlContainer}>
                                        <ThreadControl
                                            quest={quest}
                                            showTypeOptions={showExtendedOptions}
                                            setShowTypeOptions={setShowExtendedOptions}
                                        />

                                        {showStatus && <div className={styles.status}></div>}
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default QuestHeader
