import React from 'react'
import Stories from '../stories/ReactInstaStories'
import Answer from '../answers/Answer'
import { isMobile } from 'react-device-detect'
import questModel from '../quests/questModel'

export default function PlayAll(props) {
    const quest = props.modalParams.quest

    const contentStyle = {
        background: 'rgb(238, 238, 238)',
        width: '100%',
        height: '100%',
        margin: 'auto',
        //padding: '20px',
        //paddingTop: isMobile ? 0 : '30px',
        color: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        minWidth: props.width,
    }

    const showVideoText = answerContent => {
        const first_action_tag = ['!screen', '!video'].includes(answerContent?.split(' ')[0])
        return answerContent && !(first_action_tag || quest.parent?.is_transcription)
    }

    const getAnswerStory = (answer, showEmbed = true) => {
        let stories = []

        if (answer.mp4_recording_url || answer.recording_url) {
            const videoStory = {
                type: 'video',
                mp4_recording_url: answer.mp4_recording_url,
                recording_url: answer.recording_url,
                storyStyles: contentStyle,
            }
            stories.push(videoStory)
        } else {
            const answerNoVideo = Object.assign(
                {},
                { ...answer },
                {
                    mp4_recording_url: null,
                    recording_url: null,
                    recording_type: null,
                    embeds: null,
                },
            )
            stories.push({
                content: ({ action, isPaused }) => (
                    <div style={contentStyle}>
                        <Answer answer={answerNoVideo} quest={quest} isMobile={isMobile} isStory />
                    </div>
                ),
            })
        }

        if (showEmbed && answer.embeds) {
            answer.embeds.forEach(ans => {
                const embedStories = getAnswerStory(ans, false)
                stories = stories.concat(embedStories)
            })
        }

        return stories
    }

    let stories = []

    const parentAnswer = props.modalParams.quest?.parent
    if (parentAnswer && !quest.parent?.is_transcription) stories.push(getAnswerStory(parentAnswer))
    const answers = questModel.filterUnarchivedAnswers(questModel.sortedQuestAnswers(quest))
    stories = stories.concat(
        answers.map(answer => {
            return getAnswerStory(answer)
        }),
    )

    return (
        <Stories
            stories={stories.flat()}
            height={props.height}
            width={'100%'}
            onAllStoriesEnd={props.closeModal}
        />
    )
}
