import React, { useEffect, useState, useRef, useLayoutEffect, useContext } from 'react'
import useStore from 'state/knovStore'
import cn from 'classnames'
import { isPanelCenter } from 'state/imperativeApis/swiperApi'
import usePanelContext from 'refactor/hooks/usePanelContext'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import useLogRender from 'refactor/hooks/useLogRender'
import PanelHeader from 'components/panels/PanelHeader'
import QuestContainer from './QuestContainer'
import QuestStream from './QuestStream'
import styles from 'components/panels/panel.module.scss'

export default React.memo(function Panel(props) {
    const panelContext = usePanelContext()
    // Add any other contexts you're using
    // const someOtherContext = useContext(SomeOtherContext)

    useLogRender(props, {
        panelContext,
    })

    const { panel, hide } = panelContext

    const panelId = panel.panelId
    const filter = panel.filter

    useEffect(function onMount() {
        function onHashChange() {
            if (isPanelCenter(panelId) && location.hash === '#play') {
                openStoryModal(quest)
            }
        }
        window.onhashchange = onHashChange
        return () => {
            window.removeEventListener('hashchange', onHashChange)
        }
    }, [])

    const openStoryModal = useStore.getState().actions.modalActions.openStoryModal

    const isQuest = filter?.questId
    const isQuestStream = !isQuest

    //console.log('RENDER PANEL', panelId, panel.empty, filter)

    return (
        <ErrorBoundary label={`panel ${panel.panelId}`}>
            <div
                style={{ visibility: hide ? 'hidden' : 'visible' }}
                className={cn(
                    styles.panelComp,
                    props.keyboardOpen && styles.keyboardOpen,
                    'panel-comp new-quest quests-new-view',
                )}
            >
                <div className={cn(styles.panelHeaderContainer)}>
                    <PanelHeader />
                </div>

                {isQuestStream && <QuestStream filter={filter} />}

                {isQuest && (
                    <div className={cn(styles.activeQuestContainer, `panel-quest quest-container`)}>
                        <QuestContainer filter={filter} />
                    </div>
                )}
            </div>

            {/* We use this instead of padding to handle mobile keyboard wierdenss. */}
            {!props.keyboardOpen && <div className={styles.keyboardPlaceholder} />}
        </ErrorBoundary>
    )
})
