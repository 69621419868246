import usePanelContext from 'refactor/hooks/usePanelContext'
import useStore from 'state/knovStore'
import { getSwiperRef, getCenterIndex } from 'state/imperativeApis/swiperApi'
import { isMobile } from 'react-device-detect'

// [[ Treechat Slide Design ]] I think in general we want the panel we acted on to stay static, and have the other panels around the one we clicked on move.

export default function useRemoveLeft() {
    const { panel } = usePanelContext()
    const panelId = panel.panelId

    return function removeLeft() {
        const panels = useStore.getState().panels
        const getIndexById = panels.getIndexById
        const panelIndex = getIndexById(panelId)
        const leftPanelIndex = getIndexById(panelId) - 1
        const clickedLeftPanel = panelIndex < getCenterIndex()

        // Make sure there is a non empty panel to remove.
        if (leftPanelIndex > 0) {
            if (clickedLeftPanel) {
                // If we clicked the left panel's parent button, the panel we need to remove is off screen so no animation is necessary.
                panels.removePanelAtIndex(leftPanelIndex)
            } else {
                // If we are removing a panel to the left and are at the left-most edge of panels, we need to make sure there are more than 2 panels to the left of the current panel so we can remove one and slide the remaining 2+ to the right to fill the 3 slots available in the view, otherwise we slide the current panel and everything after it to the left.
                const slideDirection =
                    !isMobile && getSwiperRef()?.current?.activeIndex === 0 && panelIndex === 2
                        ? 'left'
                        : 'right'
                useStore.getState().panels.startAnimatedRemove(leftPanelIndex, slideDirection)
            }
        }
    }
}
