import React, { ReactElement } from 'react'
import EditableAmount from '../EditableAmount'
import { ActionButton } from '../buttons/ActionButton'
import { SaveButton } from '../buttons/SaveButton'
import styles from '../lock-vote.module.scss'
import { useSatsToDollars, formatUsdValue } from 'api/useBsvExchangeRate'

interface LockTabProps {
    description: string
    customSats: number | null
    customBlocks: number
    lockBlocks: number | null
    useSats: boolean
    setCustomSats: (value: number | null) => void
    setCustomBlocks: (value: number) => void
    quickLockRef: React.RefObject<any>
    handleLockOrUpvalue: (sats: number, blocks?: number) => void
    animateLock: (ref: React.RefObject<any>, scale?: number) => void
}

export function LockTab({
    description,
    customSats,
    customBlocks,
    lockBlocks,
    useSats,
    setCustomSats,
    setCustomBlocks,
    quickLockRef,
    handleLockOrUpvalue,
    animateLock,
}: LockTabProps): ReactElement {
    const usdAmount = useSatsToDollars(customSats)
    const usdValue = formatUsdValue(usdAmount)

    return (
        <>
            <div className={styles.popupDescription}>{description}</div>

            <div className={styles.amountRow}>
                <div className={styles.amountInputContainer}>
                    <EditableAmount
                        value={customSats}
                        useSats={useSats}
                        onChange={setCustomSats}
                        label="Choose Amount"
                    />
                    {usdValue && <div className={styles.usdValue}>≈ {usdValue}</div>}
                </div>
            </div>

            <EditableAmount
                value={customBlocks}
                isBlockInput={true}
                onChange={setCustomBlocks}
                defaultValue={lockBlocks}
                label="Choose Duration"
            />

            <ActionButton
                action="lock"
                customSats={customSats}
                customBlocks={customBlocks}
                handleAction={handleLockOrUpvalue}
            />

            <SaveButton
                save="lock"
                customSats={customSats}
                customBlocks={customBlocks}
                quickLockRef={quickLockRef}
                animateLock={animateLock}
            />
        </>
    )
}
