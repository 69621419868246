import { useEffect, useLayoutEffect, useRef } from 'react'
import useStore, { startingIndex } from 'state/knovStore'
import { getCenterIndex, slideTo, slidePrev, slideNext } from 'state/imperativeApis/swiperApi'
import { isMobile } from 'react-device-detect'
import { highlightCenterPanel } from 'state/imperativeApis/cssAnimations'

export default function useSlideEffects() {
    const panels = useStore(store => store.panels.state)
    const lastAction = useStore(store => store.panels.lastAction)
    const lastInsertedPanel = lastAction?.insert
    const animatedPanelInsert = useStore(store => store.panels.animatedPanelInsert)
    const animatedPanelRemove = useStore(store => store.panels.animatedPanelRemove)

    const getIndexById = useStore(store => store.panels.getIndexById)
    const getPanels = useStore(store => store.panels.getPanels)

    const insertWasAnimated = useRef<'left' | 'right' | false>(false)
    useEffect(() => {
        insertWasAnimated.current = animatedPanelInsert?.direction || false
    }, [animatedPanelInsert])

    const prevCenteredPanelId = useRef(panels[startingIndex]?.panelId)
    const updateCenteredPanelId = (newCenteredIndex: number) => {
        //console.log('updating centered panel', newCenteredIndex)
        prevCenteredPanelId.current = getPanels()[newCenteredIndex]?.panelId
    }

    // REMOVE SCENARIO 3 PANELS
    // 1. Remove the center panel
    // 2. Remove the panel on the left of the center
    // 3. Remove the panel on the right of the center
    // 4. The center panel is no longer in the array, so we need to update the prevCenteredPanelId.
    // 5. When removing panel to the left/in front of center, we slidePrev to keep the center panel consistent since it is now one index less due to removed panel
    // 6. When removing panel on the right side, ie larger indexes, swiper.activeIndex does not change.

    // 6. When removing panel to the right of center, we slidePrev to keep the panel on the left of center in view.

    useLayoutEffect(
        function slideAfterRemove() {
            if (lastAction?.remove) {
                if (lastAction.animated) {
                    if (lastAction.removeIndex < lastAction.centerIndex) {
                        // The direction is always right, and we're always reducing the amount of previous panels, therefore reducing the active/center index.
                        slideTo(lastAction.centerIndex - 1, 0)
                    } else if (lastAction.removeIndex === lastAction.centerIndex) {
                        prevCenteredPanelId.current = lastAction.panelIds[lastAction.centerIndex]
                        if (lastAction.animated === 'left') {
                            slideTo(lastAction.centerIndex, 0)
                        } else {
                            // If the slide direction is right, it means we are on the last panel and the indexing will decrease by 1.

                            // This should be where we handle the mobile case, since mobile is always removing the center panel.
                            slideTo(lastAction.centerIndex - 1, 0)
                        }
                    } else {
                        // The direction is always left, and the amount of previous panels stays the same and therefore so does the index.
                        slideTo(lastAction.centerIndex, 0)
                    }
                } else {
                    // If the action is not animated this means we are removing panels off screen to the left (toggling parent quest) or right (toggling child quest).
                    if (lastAction.removeIndex < lastAction.centerIndex) {
                        slideTo(lastAction.centerIndex - 1, 0)
                    } else {
                        slideTo(lastAction.centerIndex, 0)
                    }
                }
            }
        },
        [lastAction],
    )

    useLayoutEffect(
        function slideAfterInsert() {
            if (!lastInsertedPanel || insertWasAnimated.current === 'right') {
                highlightCenterPanel(getCenterIndex())
                return
            }

            if (insertWasAnimated.current === 'left') {
                slideTo(getCenterIndex() + 1, 0)
                highlightCenterPanel(getCenterIndex())
                return
            }

            const panels = useStore.getState().panels.state
            const insertionCausedShift =
                prevCenteredPanelId.current !== panels[getCenterIndex()]?.panelId
            let centeredIndexAfterShift = null
            if (insertionCausedShift) {
                centeredIndexAfterShift = getIndexById(prevCenteredPanelId.current)
                console.log(
                    `insertionCausedShift, prev centered panel ${prevCenteredPanelId.current} at index ${centeredIndexAfterShift}`,
                )
                slideTo(centeredIndexAfterShift, 0)
            }

            if (isMobile) {
                setTimeout(() => slideTo(getIndexById(lastInsertedPanel.panelId)))
                return
            }

            const indexOfInsertedPanel = getIndexById(lastInsertedPanel.panelId)
            const centeredIndex = centeredIndexAfterShift || getCenterIndex()
            const offset = indexOfInsertedPanel - centeredIndex
            //console.log('indexOfInsertedPanel', indexOfInsertedPanel)
            //console.log('centeredIndex', centeredIndex)
            //console.log('offset', offset)

            if (Math.abs(offset) > 1) {
                // This checks if the insertion is off the left edge of the screen.
                // If so, we slide just enough to bring the panel on to the screen, and center the formerly left panel.
                const leftEdge = offset < 0
                const rightEdge = offset > 0
                let newCenterIndex
                if (leftEdge) {
                    newCenterIndex = indexOfInsertedPanel + 1
                } else if (rightEdge && !lastAction?.insertCenter) {
                    newCenterIndex = indexOfInsertedPanel - 1
                } else {
                    newCenterIndex = centeredIndex + offset
                }
                const direction = offset > 0 ? 'right' : 'left'
                console.log(`inserted off screen ${direction}, sliding to:`, newCenterIndex)

                // We set timeout to give time for the centered panel to shift and for the css to be updated in cssAnimations.
                setTimeout(() => slideTo(newCenterIndex))
            }
        },
        [lastAction],
    )

    return {
        updateCenteredPanelId,
    }
}
