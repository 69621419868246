import React, { useState } from 'react'
import HeroImage from 'components/auth/HeroImage.jsx'
import { Link } from 'react-router-dom'
import flashStyles from 'components/shared/flash.module.scss'
import styles from 'components/auth/request-password-reset.module.scss'

const ResetPassword = props => {
    const [password, setPassword] = useState('')
    const [passwordConfirmation, setPasswordConfirmation] = useState('')

    const [success, setSuccess] = useState()
    const [message, setMessage] = useState('')

    const handleSubmit = async e => {
        e.preventDefault()
        console.log(password, passwordConfirmation, props.resetPasswordToken)
        //const url = `${window.knovApiUrl}/auth/password`
        const url = `${gon.api.users}/reset-password`
        const response = await fetch(url, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                password: password,
                password_confirmation: passwordConfirmation,
                reset_password_token: props.resetPasswordToken,
            }),
        })
        const data = await response.json()

        if (response.ok) {
            alert(data.message)
            window.location.href = '/login'
        } else {
            setSuccess(false)
            setMessage(data.error)
        }
    }

    const statusStyles = message && success ? flashStyles.success : flashStyles.error

    return (
        <div className="sessions_new landing">
            <div className="earth container-fluid">
                <div className="row">
                    <HeroImage />
                </div>

                <div className="sign-in-form col-md-offset-2 col-md-8">
                    <div className={styles.statusContainer}>
                        <div className={statusStyles}>{message}</div>
                    </div>

                    <form onSubmit={handleSubmit}>
                        <div className="sign-in-fields">
                            <div className="field">
                                <span className="field-label">New Password:</span>
                                <input
                                    className="form-control"
                                    placeholder="Password"
                                    type="password"
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="field">
                                <span className="field-label">Confirm New Password:</span>
                                <input
                                    className="form-control"
                                    placeholder="Confirm Password"
                                    type="password"
                                    value={passwordConfirmation}
                                    onChange={e => setPasswordConfirmation(e.target.value)}
                                    required
                                />
                            </div>

                            <div className="sign-in-btn-container">
                                <button
                                    type="submit"
                                    className="btn btn-primary btn-lg sign-in-btn"
                                >
                                    Update Password
                                </button>
                            </div>

                            <div className="auth-links">
                                {'Already have an account?'}
                                <Link className="devise-shared-signup" to={`/login`}>
                                    {' '}
                                    Login{' '}
                                </Link>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default ResetPassword
