import React, { useContext } from 'react'
import ProgressWrapper from './ProgressWrapper'
import ProgressCtx from './contexts/Progress'
export default props => {
    const { pause } = useContext(ProgressCtx)
    const computedStyles = Object.assign({}, styles, props.styles)
    const getProgressStyle = ({ active }) => {
        switch (active) {
            case 2:
                return { width: '100%' }
            case 1:
                return { transform: `scaleX(${props.count / 100})` }
            case 0:
                return { width: 0 }
            default:
                return { width: 0 }
        }
    }
    const { width, active } = props
    return React.createElement(
        ProgressWrapper,
        { width: width, pause: pause },
        React.createElement('div', {
            style: Object.assign(
                Object.assign({}, getProgressStyle({ active })),
                computedStyles.inner,
            ),
        }),
    )
}
const styles = {
    inner: {
        background: '#fff',
        height: '100%',
        maxWidth: '100%',
        borderRadius: 2,
        transformOrigin: 'center left',
        WebkitBackfaceVisibility: 'hidden',
        MozBackfaceVisibility: 'hidden',
        msBackfaceVisibility: 'hidden',
        backfaceVisibility: 'hidden',
        WebkitPerspective: 1000,
        MozPerspective: 1000,
        msPerspective: 1000,
        perspective: 1000,
    },
}
