import React, { useCallback } from 'react'
import styles from 'components/answers/thin-answer.module.scss'
import { UserStream } from 'components/filters/FilterIcon'
import SpaceIcon from 'components/spaces/SpaceIcon'
import answerModel from 'components/answers/answerModel'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import { AnswerImage } from 'components/answers/Answer'
import { Stream } from 'components/quests/ThinQuest'
import cn from 'classnames'
import CopyLinkButton from 'components/shared/CopyLinkButton'
import StarQuestButton from 'components/quests/StarQuestButton'
import questModel from 'components/quests/questModel'

export default function ThinAnswer({
    answer,
    quest,
    onClick: propsOnClick,
    contextStyles,
    showStream = false,
    showControls = false,
}) {
    const user = answer?.user
    const team = quest?.team
    const filter = quest ? questModel.getFilter(quest) : null

    const isSystemUser = useCallback(() => {
        return answer?.is_system || answer?.user?.system || answer.user_id === gon.SYSTEM_USER
    }, [answer])

    const isAgent = useCallback(() => {
        return answer?.message_type === 'chatgpt' || answer.message_type === 'agent'
    }, [answer])

    const onClick = useCallback(
        ev => {
            ev.preventDefault()
            ev.stopPropagation()

            if (propsOnClick) {
                propsOnClick(answer)
            }
        },
        [answer?.id],
    )

    return (
        <div className={cn(styles.thinAnswerComp, contextStyles)} onClick={onClick}>
            {showStream && team && (
                <div className={styles.streamIconContainer}>
                    <Stream team={team} user={user} filter={filter} />
                </div>
            )}

            <div className={styles.userStreamContainer}>
                {isSystemUser() ? (
                    <div className={styles.privateIcon}>
                        <SpaceIcon contextStyles={styles.spaceIcon} />
                    </div>
                ) : (
                    <UserStream
                        user={user}
                        contextStyles={cn(styles.userIcon, isAgent() && styles.agentIcon)}
                        agentIcon={isAgent()}
                        virtualUserIcon={answer.virtual_user_icon_url}
                    />
                )}
            </div>

            <div className={styles.contentContainer}>
                {isSystemUser() ? (
                    <div className={styles.privateAnswer}>
                        <div className={styles.privateLabel}>This message was quoted.</div>
                    </div>
                ) : (
                    <ThinAnswerContent answer={answer} isQuestDraft={false} />
                )}
            </div>

            {showControls && (
                <>
                    <div className={cn(styles.stackButton)}>
                        <CopyLinkButton
                            showLabel={false}
                            tooltip
                            getPublicLink={async ev => {
                                // props.getPublicLink() makes an api call to get the public link,
                                // but this needs to happen quickly to avoid being marked by the browser
                                // as not in response to a user interaction (copy/paste is only allowed
                                // in response to a user interaction).
                                // so we assemble it ourselves instead of making an api call
                                try {
                                    const publicUrl = `${document.location.protocol}//${document.location.host}/quest/${answer.child_quests?.[0]?.id}`
                                    return publicUrl
                                } catch (error) {
                                    console.error("Couldn't generate public link!", error)
                                    return null
                                }
                            }}
                        />
                    </div>

                    <div className={cn(styles.stackButton)}>
                        <StarQuestButton
                            quest={answer?.child_quests?.[0]}
                            contextStyles={styles.starQuestButton}
                        />
                    </div>
                </>
            )}
        </div>
    )
}

export function ThinAnswerContent({ answer, isQuestDraft }) {
    const text = answerModel.getText(answer) || ''
    const url = (!answer.url?.tweet_id && answer.url?.title) || answer.url?.address
    const imageUrl = answer.answer_image_url

    const empty = !isQuestDraft && !text && !url

    return (
        <ErrorBoundary label={'ThinAnswerContent'}>
            <div className={cn(styles.thinAnswerContentComp, styles.questName)}>
                {isQuestDraft && <div className={cn(styles.label, styles.draftLabel)}>DRAFT</div>}

                {imageUrl && <AnswerImage url={imageUrl} contextStyles={styles.image} />}

                {text && <div className={cn(styles.text, styles.ellipsis)}>{text}</div>}

                {url && (
                    <>
                        <div className={styles.label}>CLIP</div>
                        <div className={cn(styles.url, styles.ellipsis)}>{url}</div>
                    </>
                )}

                {empty && (
                    <div
                        className={cn(styles.empty, styles.ellipsis)}
                    >{`message-${answer.id}`}</div>
                )}
            </div>
        </ErrorBoundary>
    )
}
