import { useInfiniteQuery } from '@tanstack/react-query'
import api from 'api/api'
import { cacheQuest, getCachedQuest } from 'state/cache'

type ResponseData = {
    actions: object[]
}

export default function useGetHistory(enabled: boolean = true) {
    const emptyResult = {
        actions: [],
    }

    // Adjust the `enabled` flag based on the filter condition.
    // This ensures hooks are called in the same order every time.
    const shouldFetch = enabled

    // This must always be called with enabled flag controlling the fetch, to keep hook order consistent :(
    const result = useInfiniteQuery({
        queryKey: ['actions'],
        queryFn: async ({ pageParam = 1 }) => {
            const actions = await api.getHistory({ page: pageParam })
            return { actions }
        },
        enabled: shouldFetch,
        staleTime: Infinity,
        gcTime: Infinity,
        initialPageParam: 1,
        getNextPageParam: (lastPage, pages) => {
            const fetchedAmount = pages.reduce((acc, page) => acc + page.actions.length, 0)
            return fetchedAmount < lastPage.actions.length ? pages.length + 1 : undefined
        },
    })

    // Use the `enabled` flag to conditionally manipulate the result or return early.
    if (!shouldFetch) {
        return emptyResult
    }

    const actions = result.data?.pages.flatMap(page => page.actions) || []

    return {
        ...result,
        actions,
    }
}
