import React from 'react'
import { useState } from 'react'
import useStore from 'state/knovStore'
import cn from 'classnames'
import styles from 'components/quests/action.module.scss'
import useGetQuest from 'refactor/hooks/api/useGetQuest'
import useInsertRight from 'refactor/hooks/useInsertRight'
import usePanelContext from 'refactor/hooks/usePanelContext'
import ThinQuest, { Stream } from 'components/quests/ThinQuest'

export default function Action(props) {
    const debug = useStore(state => state.DEBUG)
    const [isHovering, setIsHovering] = useState(false)

    const { hide } = usePanelContext()

    const action = props.action
    const { quest, isFetching } = useGetQuest(action.actionable_id)

    const { insertRight } = useInsertRight()

    return (
        <div
            className={cn(styles.actionComp)}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
            style={{ visibility: hide ? 'hidden' : 'visible' }}
        >
            <div className={styles.flexContainer}>
                {quest ? (
                    <ThinQuest quest={quest} contextStyles={styles.thinQuest} showStream={true} />
                ) : (
                    <>
                        <Stream
                            team={action.team_from_filter}
                            user={action.user}
                            filter={action.meta?.filter}
                        />

                        <div className={cn(styles.actionPart)}>
                            <div className={cn(styles.iconContainer)}>
                                <span className={cn(styles.stackSearchIcon)}>
                                    <i className="fa fa-search"></i>
                                </span>
                            </div>

                            <div className={cn(styles.actionContentContainer)}>
                                <div className={cn(styles.actionName)}>{action.meta?.query}</div>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}
