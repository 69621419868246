import React, { useState } from 'react'
import { useInView } from 'react-intersection-observer'
import cn from 'classnames'
import useGetAnswer from 'refactor/hooks/api/useGetAnswer'
import { getCachedAnswer } from 'state/cache'
import useAnswerChannel from 'state/channels/useAnswerChannel'
import styles from 'components/answers/Answer/answer.module.scss'
import usePanelContext from 'refactor/hooks/usePanelContext'
import { getValue, getText } from '../../lib/value'

const AnswerInView = ({ answerId, visibleDefault = false, children, isActive }) => {
    //console.log('AnswerInView', answerId, visibleDefault, children, isActive)
    let answer = useGetAnswer(answerId)
    const { subscribe, unsubscribe } = useAnswerChannel(answerId)
    const [isVisible, setIsVisible] = useState(visibleDefault)
    const { panel, animationPanel, animationStyles } = usePanelContext()
    let minHeight = 140
    if (animationPanel && animationStyles) {
        minHeight = animationStyles.mirrorPanelRef.querySelector(
            '.answer-in-view-' + answerId,
        )?.clientHeight
    }
    const [height, setHeight] = useState(isActive ? minHeight : 0)

    const COLLAPSE_POST_LENGTH = 300
    const [textIsCollapsed, setTextIsCollapsed] = useState(() => {
        const textLength = getText(getValue(answer))?.length || 0
        return textLength > COLLAPSE_POST_LENGTH
    })

    //const scrollContainer = panel.getPanelRef()?.querySelector('.scroll-container')

    const { ref, inView, entry } = useInView({
        root: document,
        rootMargin: '1000px 0px 1000px 0px',
        onChange: (inView, entry) => {
            if (inView) {
                setIsVisible(true)
                subscribe()
            } else {
                // Use entry.target.clientHeight instead of entry.boundingRectangle.height
                // https://home.treechat.ai/quest/use-clientheight-bc-transform-want-ignore-gpt-4-1509fe52-af2f-4de1-9ac4-6a6e87884271
                if (entry.target.clientHeight > height) {
                    //console.log('setHeight?', entry.target.clientHeight, height)
                    setHeight(entry.target.clientHeight)
                }
                setIsVisible(false)
                unsubscribe()
            }
        },
    })

    const inViewCallback = () => {
        if (entry?.target) {
            //console.log('inViewCallback', entry.target.clientHeight)
            setHeight(entry.target.clientHeight)
        }
        answer = getCachedAnswer(answerId)
    }

    return (
        <div
            ref={ref}
            className={cn(`answer-in-view-${answerId}`)}
            style={{
                minHeight: height,
            }}
        >
            {isVisible ? (
                React.cloneElement(children, {
                    // key is used to force a re-render when textIsCollapsed changes
                    key: `answer-${answerId}-${textIsCollapsed}`,
                    answer,
                    inViewCallback,
                    textIsCollapsed,
                    setTextIsCollapsed,
                    COLLAPSE_POST_LENGTH,
                })
            ) : (
                <AnswerPlaceholder />
            )}
        </div>
    )
}

export default AnswerInView

function AnswerPlaceholder() {
    return <div className={styles.answerPlaceholderComp}></div>
}
