import React from 'react'
import styles from 'components/shared/icons/icons.module.scss'
import cn from 'classnames'

export default function StarredIcon(props) {
    return (
        <div className={cn(styles.iconComp, styles.starredIcon, props.contextStyles)}>
            <i className={cn(styles.star, 'fa fa-star')} />
        </div>
    )
}
