import React from 'react'
import cn from 'classnames'
import styles from '../lock-vote.module.scss'
import { SignalType } from 'types/signals'

interface SignalOptionsButtonProps {
    signalType: SignalType
    signalOptionsRef: React.RefObject<HTMLDivElement>
    onToggleModal: () => void
}

export function SignalOptionsButton({
    signalType,
    signalOptionsRef,
    onToggleModal,
}: SignalOptionsButtonProps) {
    return (
        <div
            ref={signalOptionsRef}
            className={cn(
                styles.signalOptionsButton,
                signalType === 'upvalue' || signalType === 'boost'
                    ? styles.signalOptionsUpvalue
                    : styles.signalOptionsLock,
            )}
            onClick={ev => {
                ev.stopPropagation()
                ev.preventDefault()
                onToggleModal()
            }}
        >
            <i
                className={`fa fa-${
                    signalType === 'upvalue' || signalType === 'boost' ? 'arrow-up' : 'lock'
                }`}
            />
        </div>
    )
}
