import usePanelContext from 'refactor/hooks/usePanelContext'
import useStore from 'state/knovStore'
import { isPanelCenter, getCenterIndex } from 'state/imperativeApis/swiperApi'

export default function useRemovePanel(panelId) {
    const { panel } = usePanelContext()
    const nativePanelId = panel.panelId
    const anchorPanelId = panelId || nativePanelId

    return function removePanel(direction = null) {
        const panels = useStore.getState().panels
        const panelIndex = panels.getIndexById(anchorPanelId)
        const lastActivePanelIndex = panels.state.length - 2

        // When removing left panel, ie panelIndex < centerIndex, keep center panel static and slide remaining left panels to the right.
        // When removing center panel, ie panelIndex === centerIndex, slide the remaining right panels left.
        // When removing right panel, ie panelIndex > centerIndex, slide the remaining right panels left.
        // Unless panel is center index with only the empty append panel to the right, ie lastActivePanelIndex === panelIndex, in which case there is no more panels to the right that we can slide left, so instead we slide any left panels right.
        direction =
            direction ||
            (isPanelCenter(anchorPanelId) && panelIndex === lastActivePanelIndex) ||
            panelIndex < getCenterIndex()
                ? 'right'
                : 'left'

        setTimeout(() => useStore.getState().panels.startAnimatedRemove(panelIndex, direction))
    }
}
