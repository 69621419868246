import { Quest } from 'types/quests'
import { Answer } from 'types/answers'
import queryClient from 'api/queryClient'

function set(key, data) {
    queryClient.setQueryData(key, data)
}

export function getCachedAnswer(answerId: string): Answer {
    const answer = queryClient.getQueryData(['answer', answerId]) as Answer
    return answer
        ? {
              ...answer,
              ...(answer.embeds && {
                  embeds: answer.embeds.map(embed =>
                      queryClient.getQueryData(['answer', embed.id]),
                  ),
              }),
              ...(answer.draft_answer_id && {
                  draft_answer: queryClient.getQueryData(['answer', answer.draft_answer_id]),
              }),
          }
        : null
}

export function cacheAnswers(answers: Answer[]) {
    answers.map(answer => cacheAnswer(answer))
}

function updateQuestsForAnswer(answer: Answer, existingAnswer: Answer) {
    // Handle quest_id changes
    if (existingAnswer?.quest_id !== answer.quest_id) {
        const origQuestId = existingAnswer?.quest_id
        const newQuestId = answer.quest_id

        const origQuest = getCachedQuest(origQuestId)
        const newQuest = getCachedQuest(newQuestId)

        // Remove from old quest
        if (origQuest) {
            updateCachedQuest(origQuestId, {
                sorted_answers: origQuest.sorted_answers.filter(a => a?.id != answer.id),
            })
        }

        // Add to new quest
        if (newQuest) {
            updateCachedQuest(newQuestId, {
                sorted_answers: [...newQuest.sorted_answers, { id: answer.id }],
            })
        }
    } else if (!existingAnswer && answer.quest_id) {
        // New answer with quest_id
        const newQuest = getCachedQuest(answer.quest_id)
        if (newQuest) {
            updateCachedQuest(newQuest.id, {
                sorted_answers: [...newQuest.sorted_answers, { id: answer.id }],
            })
        }
    }

    // Handle side_quest_id changes
    if (existingAnswer?.side_quest_id !== answer.side_quest_id) {
        const origSideQuestId = existingAnswer?.side_quest_id
        const newSideQuestId = answer.side_quest_id

        const origSideQuest = getCachedQuest(origSideQuestId)
        const newSideQuest = getCachedQuest(newSideQuestId)

        // Remove parent from old side quest
        if (origSideQuest) {
            updateCachedQuest(origSideQuest.id, {
                parent_id: null,
                parent: null,
            })
        }

        // Add parent to new side quest
        if (newSideQuest) {
            updateCachedQuest(newSideQuest.id, {
                parent_id: answer.id,
                parent: { id: answer.id },
            })
            answer.child_quests = [newSideQuest]
            answer.child_answer_count = newSideQuest?.sorted_answers?.length
        } else {
            answer.child_quests = []
            answer.child_answer_count = 0
        }
    }
}

export function cacheAnswer(answer: Answer, update_timestamp: boolean = true) {
    if (!answer) return null

    // Get the existing cached answer
    const existingAnswer = getCachedAnswer(answer.id)

    // Update quests that contain this answer
    updateQuestsForAnswer(answer, existingAnswer)

    // Cache embeds
    answer.embeds?.forEach(embed => {
        if (!queryClient.getQueryData(['answer', embed.id])) {
            set(['answer', embed.id], embed)
        }
    })

    // Cache the answer
    set(['answer', answer.id], {
        ...answer,
        ...(answer.embeds && { embeds: answer.embeds.map(embed => ({ id: embed.id })) }),
        ...(answer.draft_answer?.id && { draft_answer_id: answer.draft_answer.id }),
        ...(update_timestamp && {
            // react-query cache doesn't update if we don't do this
            updated_at: new Date(),
        }),
    })
    return answer
}

export function getCachedQuest(questId: string): Quest {
    const quest = queryClient.getQueryData(['quest', questId]) as Quest
    if (quest)
        return {
            ...quest,
            ...(quest.parent_id && { parent: getCachedAnswer(quest.parent_id) }),
            ...(quest.new_answer_id && { new_answer: getCachedAnswer(quest.new_answer_id) }),
            ...(quest.sorted_answer_ids && {
                sorted_answers: quest.sorted_answer_ids?.map(answer_id =>
                    getCachedAnswer(answer_id),
                ),
            }),
        }
    return null
}

export function cacheQuests(quests) {
    return quests.map(quest => cacheQuest(quest))
}

export function cacheQuest(quest: Quest, options: { skipParent?: boolean } = {}) {
    const { skipParent = false } = options
    if (!quest) return null

    // Normalize in cache.
    if (!skipParent && quest.parent) {
        cacheAnswer(quest.parent)
    }

    if (quest?.sorted_answers) {
        quest.sorted_answers.forEach(answer => cacheAnswer(answer))
    }

    if (quest.new_answer) {
        cacheAnswer(quest.new_answer)
    }

    set(['quest', quest.id], { ...quest })
    return quest
}

export function removeFromStream(filterKey, questId) {
    queryClient.setQueryData(['quests', filterKey], (oldData: object) => {
        if (!oldData) return oldData

        return {
            ...oldData,
            pages: oldData.pages?.map(page => ({
                ...page,
                quests: page.quests?.filter(quest => quest.id !== questId),
            })),
        }
    })
}

export function updateCachedAnswer(answerId, params) {
    let answer = getCachedAnswer(answerId)
    if (!answer) return null

    answer = { ...answer, ...params, updated_at: new Date() }

    // Cache the updated answer, which will handle updating quests
    return cacheAnswer(answer)
}

export function updateCachedQuest(questId, newContent) {
    return cacheQuest({ ...getCachedQuest(questId), ...newContent })
}
