import React, { useState, useEffect } from 'react'
import styles from 'components/shared/settings/settings-header.module.scss'
import Button from 'components/shared/buttons/Button'

export default function SettingsHeader({
    header,
    IconComponent,
    name,
    handleSave,
    openImageUpdateModal,
}) {
    const [localName, setLocalName] = useState(name)

    const onKeyDown = ev => {
        if (ev.key === 'Enter') {
            handleSave(localName)
            ev.target.blur()
        } else if (ev.keyCode === 27) {
            handleCancel()
            ev.target.blur()
        }
    }

    const handleCancel = () => {
        if (localName !== name) setLocalName(name)
    }

    const changedName = name !== localName

    return (
        <div className={styles.settingsHeaderComp}>
            <div className={styles.header}>
                <div className={styles.headerContainer}>
                    <div className={styles.headerLabel}>{header}</div>
                </div>
            </div>

            <div className={styles.headerContent}>
                <div className={styles.logoContainer} onClick={openImageUpdateModal}>
                    {IconComponent}
                </div>

                <div className={styles.headerName}>
                    <input
                        className={styles.nameInput}
                        value={localName}
                        onChange={ev => setLocalName(ev.target.value)}
                        onKeyDown={onKeyDown}
                    />
                </div>
            </div>

            {changedName && (
                <div className={styles.btnContainer}>
                    <div className={styles.buttons}>
                        <Button
                            type="default"
                            text="cancel"
                            icon="fa fa-remove"
                            onClick={handleCancel}
                        />

                        <Button
                            type="primary"
                            text="Save"
                            contextStyles={styles.saveBtn}
                            onClick={() => {
                                handleSave(localName)
                            }}
                        />
                    </div>
                </div>
            )}
        </div>
    )
}
