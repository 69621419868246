import React, { useState, useEffect } from 'react'
import WithTooltip from 'components/shared/WithTooltip'
import styles from 'components/shared/error-boundary.module.scss'
import * as Sentry from '@sentry/react'

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            hasError: false,
            error: null,
            errorInfo: null,
            showError: false,
        }
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true }
    }

    componentDidCatch(error, errorInfo) {
        // You can log the error to an error reporting service
        console.error(error, errorInfo)

        this.setState({
            error: error,
            errorInfo: JSON.stringify(errorInfo),
        })

        Sentry.withScope(scope => {
            scope.setExtras({
                label: this.props.label,
                error: error,
                error_info: errorInfo,
            })

            Sentry.captureException(error)
        })
    }

    render() {
        if (this.state.hasError) {
            // TODO: implement a better/proper fallback UI
            // TODO: report error to sentry?
            const label = `>>> ErrorBoundary hit for component ${
                this.props?.label ? `'${this.props.label}'` : ''
            }.`

            const errorComp = this.props.label
            const error = this.state.error
            const errorInfo = this.state.errorInfo

            console.log(errorComp, error, errorInfo)

            return (
                <>
                    <WithTooltip tip={label}>
                        <div
                            className={styles.error}
                            onClick={() =>
                                this.setState(state => ({ showError: !state.showError }))
                            }
                        >
                            <i className={'fa fa-meh-o'} />
                        </div>
                    </WithTooltip>

                    {this.state.showError && (
                        <div className={styles.errorMessage}>
                            <div>ERROR {errorComp}</div>

                            <div>{error}</div>

                            <div>{errorInfo}</div>
                        </div>
                    )}
                </>
            )
        }

        return this.props.children
    }
}

export default ErrorBoundary
