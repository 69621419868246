export type KnovAccessToken = {
    'access-token': string
    uid: string
    client: string
    expiry?: string
}

/**
 * Returns an object containing access-token, uid and client suitable for directly inserting into headers sent on requests.
 */
export function accessTokens(): KnovAccessToken {
    // TODO: these need to be retrieved (and stored!) in localforage after auth flow completes
    const token = localStorage.getItem('token')
    if (token) {
        try {
            const tokenObj = JSON.parse(token)
            return tokenObj
        } catch (e) {
            console.error('Error unparsing token: ', e)
        }
    }
}

export function setAccessToken(tokens: KnovAccessToken) {
    localStorage.setItem('token', JSON.stringify(tokens))
}

export function clearAccessTokens() {
    localStorage.removeItem('token')
    localStorage.removeItem('gon')
}
