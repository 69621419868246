import React, { useState, useEffect, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { getTooltipRef } from '../../state/imperativeApis/tooltipApi'
import { isMobile } from 'react-device-detect'

const show = tipped => ReactTooltip.show(tipped)
const hide = tipped => ReactTooltip.hide(tipped)

export default React.forwardRef(({ tip, children, offset }, ref) => {
    const [hover, setHover] = useState(false)
    const onMouseEnter = ev => setHover(true)
    const onMouseLeave = ev => setHover(false)

    useEffect(() => {
        hover && show(ref.current)
        return () => {
            hide(ref.current)
            getTooltipRef()?.current?.clearTimer?.()
        }
    }, [hover])

    return (
        <div
            ref={ref}
            className="with-tool-tip-comp comp"
            data-tip={tip}
            data-effect="solid"
            data-place="bottom"
            data-offset={isMobile ? `{'top' : 70}` : `{'left': ${offset || 0}}`}
            data-delay-show={1000}
            data-arrow-color="rgba(0,0,0,0)"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
            style={{ display: 'inline-block' }}
        >
            {children}
        </div>
    )
})
