import React, { useState } from 'react'
import ImageInput from 'components/shared/ImageInput'

export default function StreamImageUpdate({ modalParams, closeModal }) {
    const { team } = modalParams
    const [{ alt, src }, setImg] = useState({
        src: team.team_image.url ? team.team_image.url : '',
        alt: '',
    })

    const handleImg = e => {
        if (e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name,
            })
        }
    }

    const handleSubmit = async ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const form = new FormData(ev.target)
        closeModal()
        const apiTeam = await api.updateTeam(team.id, form)
        if (apiTeam) modalParams.callback(apiTeam)
    }

    return (
        <form method="PATCH" onSubmit={handleSubmit} encType="multipart/form-data">
            {/*<input type="hidden" name="authenticity_token" value={gon?.formToken} />*/}

            <ImageInput
                src={src}
                alt={alt}
                name="team_image"
                handleImg={handleImg}
                label="Stream Logo"
            />

            <div className="btn-container">
                <button className="btn btn-primary reload-btn" type="submit">
                    Submit
                </button>
            </div>
        </form>
    )
}
