import React from 'react'
import styles from 'components/quests/private-quest.module.scss'

export default function PrivateQuest(props) {
    return (
        <div className={styles.privateQuestComp}>
            <div className={styles.iconContainer}>
                <i className="fa fa-lock fa-lg" />
            </div>

            <div className={styles.messageContainer}>
                You do not have permission to view this thread.
            </div>
        </div>
    )
}
