import { useEffect, useRef } from 'react'
import * as cache from 'state/cache'
import CableApp from 'actioncable'
import useStore from 'state/knovStore'
import { JobLifecycle } from 'types/jobLifecycle'

const REPORT = false

enum MsgTypes {
    UPDATE_ANSWER = 'update answer',
    UPDATE_JOB_STATUS = 'update job status',
}

export default function useAnswerChannel(answerId: string) {
    const channel = useRef<any>(null)
    const set = useStore(state => state.set)
    const answerJobs = useStore(state => state.answerJobs)

    const subscribe = () => {
        channel.current = CableApp.cable.subscriptions.create(
            { channel: 'AnswerChannel', stream_from: `answer_${answerId}`, answer_id: answerId },
            {
                received: async data => {
                    const { message: msgType } = data
                    if (msgType === MsgTypes.UPDATE_ANSWER) {
                        cache.cacheAnswer(data.answer)
                    } else if (msgType === MsgTypes.UPDATE_JOB_STATUS) {
                        console.log('>>> update job status:', data)
                        set(state => {
                            state.answerJobs[answerId] = {
                                ...data?.job_status,
                                status: data?.job_status?.status as JobLifecycle,
                            }
                        })
                    }
                },

                connected() {
                    if (gon.env === 'development' && REPORT)
                        console.log(`Connected to AnswerChannel id ${answerId}`)
                },

                disconnected() {
                    if (gon.env === 'development' && REPORT)
                        console.log(`Disconnected from AnswerChannel id ${answerId}`)
                },
            },
        )
        if (gon.env === 'development' && REPORT)
            console.log(
                `subscribed to answer channel id ${answerId}`,
                channel.current,
                channel.current?.consumer?.subscriptions?.subscriptions?.length,
            )
    }

    const unsubscribe = () => {
        if (channel.current) {
            channel.current.unsubscribe()
            if (gon.env === 'development' && REPORT)
                console.log(
                    `unsubscribed from answer channel id ${answerId}`,
                    channel.current,
                    channel.current?.consumer?.subscriptions?.subscriptions?.length,
                )
            channel.current = null
        }
    }

    useEffect(() => {
        // TODO: have this map be stored elsewhere and imported so that all
        // components can use it
        if (answerId) subscribe()

        return () => unsubscribe()
    }, [answerId])

    // We return this so AnswerInView or others can unsubscribe.
    return {
        subscribe,
        unsubscribe,
    }
}
