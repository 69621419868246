import React from 'react'
import cn from 'classnames'
import styles from './question-controls.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import useStore from 'state/knovStore'

const QuestionControls = props => {
    const showFilter = () => {
        const b = props.CB
        return !props.editMode && props.controlButtons.includes(b.filter)
    }

    const showCopy = () => !props.editMode && props.controlButtons.includes(props.CB.copy)

    const showCollapseBtn = () => {
        const b = props.CB
        return props.controlButtons.includes(b.collapse)
    }

    const toggleFilter = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        props.setShowFilterDropdown(!props.showFilterDropdown)
    }

    const collapseHandler = ev => {
        ev.preventDefault()
        ev.stopPropagation()
        props.collapseHandler?.()
    }

    return (
        <div className={cn(styles.questionControlsComp)}>
            <div className={cn(styles.controlContainer)}>
                {showCollapseBtn() && <Collapse collapseHandler={collapseHandler} />}

                {showFilter() && <AnswerFilter filter={props.filter} toggleFilter={toggleFilter} />}

                <PlayAll quest={props.quest} />
            </div>
        </div>
    )
}

export default QuestionControls

function PlayAll(props) {
    const openStoryModal = useStore(state => state.actions.modalActions.openStoryModal)

    return (
        <WithTooltip tip="Play thread as Story.">
            <div
                className={cn(styles.questControlButton, styles.playButton)}
                onClick={() => openStoryModal(props.quest)}
            >
                <i className="fa fa-play"></i>

                {false && <span className="question-control-label">play quest</span>}
            </div>
        </WithTooltip>
    )
}

function Collapse(props) {
    return (
        <WithTooltip tip="Collapse all messages.">
            <div className={styles.questControlButton} onClick={props.collapseHandler}>
                <i className="fa fa-compress"></i>
                {false && <span className="question-control-label">collapse all answers</span>}
            </div>
        </WithTooltip>
    )
}

function AnswerFilter(props) {
    const filterLogos = {
        none: 'fa fa-filter',
        archive: 'fa fa-folder',
        all: 'fa fa-cube',
    }
    const logo = filterLogos[props.filter] || 'fa fa-exclamation'

    return (
        <WithTooltip tip={'Filter messages.'}>
            <div className={styles.questControlButton} onClick={props.toggleFilter}>
                <i className={logo}></i>
            </div>
        </WithTooltip>
    )
}
