import * as cache from 'state/cache'

export function fileHandler(file: File, answerId: number): void {
    if (file && answerId) {
        let params
        if (file.type.includes('image')) {
            params = {
                answer_image: file,
                answer_image_url: URL.createObjectURL(file),
            }
        } else if (file.type.includes('video')) {
            const videoUrl = URL.createObjectURL(file)
            // TODO we're moving local state to the draft answer cache one piece at a time. Video here, but need to do images/files.
            params = {
                recording: file,
                recording_url: videoUrl,
            }
        } else {
            params = {
                files: [file],
                file_urls: [file].map(f => ({ name: f.name, url: URL.createObjectURL(f) })),
            }
        }
        cache.updateCachedAnswer(answerId, params)
    }
}
