import React, { useCallback, useState } from 'react'
import cn from 'classnames'
import styles from 'components/answers/answer-top-controls.module.scss'
import CopyLinkButton from 'components/shared/CopyLinkButton'
import { isMobile } from 'react-device-detect'
import perms from 'lib/perms'
import WithTooltip from 'components/shared/WithTooltip'
import api from 'api/api'
import usePanelContext from 'refactor/hooks/usePanelContext'
import StarQuestButton from 'components/quests/StarQuestButton'
import { ArchiveButton, ConfirmArchive } from 'components/answers/AnswerControls'

export default function AnswerTopControls(props) {
    const answer = props.answer
    const { hide } = usePanelContext()

    const onClick = ev => {
        ev.stopPropagation()
    }

    const visibleStyles = !hide && (props.isBsv || isMobile || props.hover) ? styles.visible : null

    const toggleControlsPopup = useCallback(ev => {
        ev.stopPropagation()
        props.toggleControlsPopup()
    }, [])

    const isBsv = props.isBsv

    return (
        <div className={cn(styles.answerTopControlsComp, visibleStyles)} onClick={onClick}>
            {!isBsv && gon.currentUser && perms.canEditAnswer(gon.currentUser, answer) && (
                <div className={cn(styles.topControlContainer)}>
                    <div
                        className={cn(styles.topControl, props.editMode && styles.editMode)}
                        onClick={props.toggleEdit}
                    >
                        <i className={`fa ${props.editMode ? 'fa-edit' : 'fa-edit'} fa-lg`} />
                    </div>
                </div>
            )}

            {isBsv && (
                <div className={cn(styles.topControlContainer)}>
                    {answer?.isLocal ? (
                        <div className={cn(styles.topControl)}>
                            <i className="fa fa-spinner fa-spin" />
                        </div>
                    ) : (
                        <WithTooltip tip={`View tx: ${answer.bsv_tx_id}`}>
                            <a
                                href={
                                    answer.bsv_tx_id
                                        ? `https://whatsonchain.com/tx/${answer.bsv_tx_id}`
                                        : '#'
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                className={cn(
                                    styles.topControl,
                                    styles.blockchainControl,
                                    !answer?.bsv_tx_id && styles.notOnchain,
                                )}
                                onClick={ev => {
                                    ev.stopPropagation()
                                    if (!answer.bsv_tx_id) {
                                        ev.preventDefault()
                                        api.postToBsv(answer.id)
                                    }
                                }}
                            >
                                <i className="fa fa-cube" />
                            </a>
                        </WithTooltip>
                    )}
                </div>
            )}

            <div className={cn(styles.topControlContainer)}>
                <div className={cn(styles.topControl, styles.copyLinkControl)}>
                    <CopyLinkButton
                        tooltip
                        getPublicLink={() => {
                            // props.getPublicLink() makes an api call to get the public link,
                            // but this needs to happen quickly to avoid being marked by the browser
                            // as not in response to a user interaction (copy/paste is only allowed
                            // in response to a user interaction).
                            // so we assemble it ourselves instead of making an api call
                            try {
                                const publicUrl = `${document.location.protocol}//${document.location.host}/quest/${props.answer.child_quests?.[0]?.id}`
                                return publicUrl
                            } catch (error) {
                                console.error("Couldn't generate public link!", error)
                                return null
                            }
                        }}
                        isMobile={isMobile}
                        styles={styles}
                        showLabel={false}
                    />
                </div>
            </div>

            <div className={cn(styles.topControlContainer)}>
                <WithTooltip tip="Add message to your starred stream.">
                    <div className={cn(styles.topControl)}>
                        <StarQuestButton
                            quest={answer.child_quests?.[0]}
                            contextStyles={styles.starQuestButton}
                        />
                    </div>
                </WithTooltip>
            </div>

            <div className={cn(styles.topControlContainer)}>
                <div className={cn(styles.topControl)} onClick={toggleControlsPopup}>
                    {props.showControlsPopup ? (
                        <i className="fa fa-remove fa-lg" />
                    ) : (
                        <i className="fa fa-ellipsis-v fa-lg" />
                    )}
                </div>
            </div>
        </div>
    )
}
