import React from 'react'
import cn from 'classnames'
import styles from 'components/shared/icons/icons.module.scss'
import TreechatPng from 'assets/treechat.png'

export default function TreechatIconPng(props) {
    return (
        <div className={cn(styles.treechatIconPng, props.contextStyles)}>
            <img src={TreechatPng} />
        </div>
    )
}
