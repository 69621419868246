declare global {
    interface Window {
        logEv: (event: string, props?: Record<string, any>) => void
        amplitude: any
    }
}

export function logEv(event: string, props: Record<string, any> = {}): void {
    try {
        if (window?.gon?.env === 'production') {
            window.amplitude.getInstance().logEvent(event, props)
        } else {
            return
            console.log('log in production', event, props)
        }
    } catch (err) {
        console.error(err)
    }
}

window.logEv = logEv
