import useGetQuest from 'refactor/hooks/api/useGetQuest'

export default function useStreamHeader(filter) {
    const { quest } = useGetQuest(filter?.questId)

    let header
    if (filter?.questId) {
        if (quest) header = quest.default_sort_col === 'vote' ? 'QUEST' : 'THREAD'
        else header = ''
    } else if (filter?.meme) header = 'MEME'
    else if (filter?.user) header = 'USER'
    else if (filter) header = 'STREAM'

    return header
}
