import React from 'react'
import Header from '../../Header'
const withHeader = ({ story, globalHeader, children }) => {
    return React.createElement(
        React.Fragment,
        null,
        children,
        story.header &&
            React.createElement(
                'div',
                { style: { position: 'absolute', left: 12, top: 20, zIndex: 19 } },
                typeof story === 'object'
                    ? globalHeader
                        ? globalHeader(story.header)
                        : React.createElement(Header, {
                              heading: story.header.heading,
                              subheading: story.header.subheading,
                              profileImage: story.header.profileImage,
                          })
                    : null,
            ),
    )
}
export default withHeader
