import React from 'react'
import cn from 'classnames'
import styles from 'components/shared/icons/icons.module.scss'

export default function AllIcon(props) {
    return (
        <div className={cn(styles.iconComp, styles.fontIconComp, props.contextStyles)}>
            <i className="fa fa-eye" />
        </div>
    )
}
