import React from 'react'
import styles from 'components/shared/icons/icons.module.scss'
import cn from 'classnames'

export default function HistoryIcon(props) {
    return (
        <div className={cn(styles.iconComp, styles.historyIcon, props.contextStyles)}>
            <i className={cn(styles.clock, 'fa fa-clock-o')} />
        </div>
    )
}
