import React from 'react'

export default function HandCashError(props) {
    const getMessage = () => {
        if (props.modalParams.message.toLowerCase().includes('no_account'))
            return 'Please connect to the Handcash wallet to upvote with money.'
        else if (props.modalParams.message.toLowerCase().includes('invalid_token'))
            return 'Please re-connect Handcash.'
        else if (props.modalParams.message.toLowerCase().includes('insufficient funds'))
            return 'Please increase your Handcash balance to upvote with money.'
        else return props.modalParams.message
    }

    const getHandcashButton = () => {
        if (['no_account', 'invalid_token'].includes(props.modalParams.message))
            return (
                <a
                    id="connectButton"
                    href={`${gon.handCash.url}/#/authorizeApp?appId=${gon.handCash.app_id}`}
                >
                    Connect with Handcash
                </a>
            )
        else
            return (
                <a id="connectButton" href={`${gon.handCash.url}`}>
                    Visit Handcash
                </a>
            )
    }

    return (
        <React.Fragment>
            <div className="handcash-error-comp">
                <div className="handcash-error-container">{getMessage()}</div>

                <div className="handcash-btn-container">{getHandcashButton()}</div>
            </div>
        </React.Fragment>
    )
}
