import React from 'react'

export default function Reload(props) {
    const reload = () => location.reload

    return (
        <div className="reload-modal-comp">
            <div className="reload-label-container">
                Please <span className="reload-label">Reload</span> to access the latest version of
                Knovigator.
            </div>

            <div className="btn-container">
                <div
                    className="btn btn-primary reload-btn"
                    onClick={() => window.location.reload()}
                >
                    Reload
                </div>
                <div className="btn btn-default cancel-btn" onClick={() => props.closeModal()}>
                    Cancel
                </div>
            </div>
        </div>
    )
}
