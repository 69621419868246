import React from 'react'
import { isMobile } from 'react-device-detect'
import CableApp from '../../actioncable'
import Connector from '../state/Connector'
import styles from './vote.module.scss'
import cn from 'classnames'
import api from 'api/api'

const stateSelector = state => ({
    openHandCashErrorModal: state.actions.modalActions.openHandCashErrorModal,
    openCryptoModal: state.actions.modalActions.openCryptoModal,
    closeCryptoModal: state.actions.modalActions.closeCryptoModal,
})

class Vote extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            cryptoMode: this.props.displayCrypto,
            cryptoVoteTotal: this.props.cryptoVoteTotal,
            aggCryptoVoteTotal: this.props.aggCryptoVoteTotal,
            upvoteTotal: this.props.voteTotal,
        }
        this.containerRef = React.createRef()
    }

    componentDidMount = async () => {
        if (gon && gon.currentUser && gon.currentUser.id) {
            CableApp.channel = CableApp.cable.subscriptions.create(
                { channel: 'UserChannel', userId: gon.currentUser.id },
                {
                    received: async data => {
                        if (
                            data.message === 'votes updated' &&
                            data.votable_id == this.props.votableId &&
                            data.votable_type == this.props.votableType
                        ) {
                            if (data.success) {
                                this.setState({ voteTotalColor: 'green' })
                            } else {
                                this.setState(state => {
                                    this.props.openHandCashErrorModal(data.error)

                                    const newTotal =
                                        state.cryptoVoteTotal > this.props.cryptoVoteTotal
                                            ? state.cryptoVoteTotal - data.pay_value || 0
                                            : this.props.cryptoVoteTotal
                                    return { voteTotalColor: null, cryptoVoteTotal: newTotal }
                                })
                            }
                        }
                    },
                },
            )
        }
    }

    componentWillUnmount = () => {
        if (CableApp.channel && CableApp.channel.unsubscribe) {
            CableApp.channel.unsubscribe()
        }
    }

    handleVote = (value, e) => {
        e.preventDefault()
        e.stopPropagation()

        if (!this.props.userSignedIn) {
            //document.getElementById('auth-modal')?.style?.display = 'block'
            return
        }

        this.setState((prevState, props) => ({
            upvoteTotal: (prevState?.upvoteTotal || 0) + value,
        }))

        api.upvoteAnswer(this.props.votableId)
        // .then(res => console.log('>>> handle vote result', res))
    }

    handleCryptoVote = (value, e = null) => {
        if (e) {
            e.preventDefault()
            e.stopPropagation()
        }

        if (!this.props.userSignedIn) {
            $('#auth-modal').modal('show')
            return
        }

        this.setState(state => {
            return {
                voteTotalColor: 'red',
                cryptoVoteTotal: (state.cryptoVoteTotal || 0) + value / 100,
                aggCryptoVoteTotal: (state.aggCryptoVoteTotal || 0) + value / 100,
            }
        })

        return fetch('/crypto_votes/register', {
            method: 'POST',
            body: JSON.stringify({
                value: value,
                crypto_votable_id: this.props.votableId,
                crypto_votable_type: this.props.votableType,
            }),
            headers: {
                'Content-Type': 'application/json',
            },
        })
            .then(response => response.json())
            .then(data => {
                console.log('Success:', data)
            })
            .catch(error => {
                console.error('Error:', error)
            })
    }

    toggleCrypto = () => {
        this.setState((state, props) => {
            return { cryptoMode: !state.cryptoMode }
        })
    }

    openCryptoModal = () => {
        const { left, top } = this.containerRef.current.getBoundingClientRect()
        this.props.openCryptoModal(left + 40, top + 40, this.handleCryptoVote)
    }

    render() {
        const isSystemUser = this.props.votable?.user?.system || this.props.votable?.is_system
        const buttonVisibleClass =
            !isSystemUser && (isMobile || this.props.hover) ? styles.visible : null
        const voteVisible =
            !isSystemUser &&
            (isMobile ||
                this.props.hover ||
                this.props.voteTotal > 0 ||
                this.props.cryptoVoteTotal > 0 ||
                this.props.aggCryptoVoteTotal > 0)
                ? styles.visible
                : null
        const isTitle = this.props.isTitle
        const voteStyles = isTitle ? styles.title : null
        const voteColor = this.state.voteTotalColor ? { color: this.state.voteTotalColor } : null

        return (
            <div className={cn(styles.voteComp, voteStyles)} ref={this.containerRef}>
                <>
                    <div
                        className={cn(styles.voteButton, styles.voteUp, buttonVisibleClass)}
                        onClick={e =>
                            this.state.cryptoMode
                                ? this.handleCryptoVote(1, e)
                                : this.handleVote(1, e)
                        }
                        onMouseEnter={() =>
                            this.state.cryptoMode && this.props.toggleShowKnovigator
                        }
                        onMouseLeave={() =>
                            this.state.cryptoMode && this.props.toggleShowKnovigator
                        }
                    >
                        <i
                            className={cn(styles.vote, styles.voteUp, 'fa fa-caret-up fa-lg')}
                            data-votable-id={this.props.votableId}
                            data-votable-type={this.props.votableType}
                        ></i>
                    </div>

                    {this.state.cryptoMode ? (
                        <div className={styles.valueContainer}>
                            {false && isTitle && (
                                <CryptoVoteTotal
                                    voteTotal={this.state.aggCryptoVoteTotal}
                                    votableId={this.props.votableId}
                                    votableType={this.props.votableType}
                                    visible={voteVisible}
                                    color={voteColor}
                                    toggleCrypto={this.toggleCrypto}
                                    openCryptoModal={this.openCryptoModal}
                                    agg
                                />
                            )}

                            <CryptoVoteTotal
                                voteTotal={this.state.cryptoVoteTotal}
                                votableId={this.props.votableId}
                                votableType={this.props.votableType}
                                visible={voteVisible}
                                color={voteColor}
                                toggleCrypto={this.toggleCrypto}
                                openCryptoModal={this.openCryptoModal}
                            />
                        </div>
                    ) : (
                        <div className={styles.valueContainer}>
                            <VoteTotal
                                voteTotal={this.state.upvoteTotal}
                                votableId={this.props.votableId}
                                votableType={this.props.votableType}
                                visible={voteVisible}
                                toggleCrypto={this.toggleCrypto}
                            />
                        </div>
                    )}

                    <div
                        className={cn(styles.voteButton, styles.voteDown, buttonVisibleClass)}
                        onClick={e => this.handleVote(-1, e)}
                    >
                        <i
                            className={'vote vote-down fa fa-caret-down fa-lg'}
                            data-votable-id={this.votableId}
                            data-votable-type={this.props.votableType}
                        ></i>
                    </div>
                </>
            </div>
        )
    }
}

export default Connector(stateSelector)(Vote)

export { VoteTotal, CryptoVoteTotal }

function VoteTotal(props) {
    const voteTotal = props.voteTotal
    const mobileClass = isMobile ? styles.isMobile : null

    const onClick = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (props.toggleCrypto) props.toggleCrypto()
    }

    return (
        <div
            className={cn(styles.voteValue, props.visible)}
            data-votable-id={props.votableId}
            data-votable-type={props.votableType}
            onClick={onClick}
        >
            {voteTotal}
        </div>
    )
}

function CryptoVoteTotal(props) {
    const voteTotal = props.voteTotal
    const displayVote = `${Math.round((voteTotal || 0) * 100)}¢`
    const mobileClass = isMobile ? styles.isMobile : ''
    const aggStyles = props.agg ? styles.agg : ''

    const onClick = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (props.toggleCrypto && voteTotal === 0) props.toggleCrypto()
        else if (props.openCryptoModal) props.openCryptoModal()
    }

    return (
        <div
            className={cn(styles.voteValue, props.visible, mobileClass, 'crypto', aggStyles)}
            data-votable-id={props.votableId}
            data-votable-type={props.votableType}
            onClick={onClick}
            style={props.color}
        >
            {displayVote}
        </div>
    )
}
