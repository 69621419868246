import throttle from 'lodash/throttle'
import { isMobile } from 'react-device-detect'
const SLOW_OFFSET = 150
const OFFSET = 100
const SIDE_OFFSET = 50
const SLOW_PX_DIFF = 4
const PX_DIFF = 8
var dragoverTarget
var animations = []
var slideDelay

const goUp = (containerElement, initialItem) => {
    if (!document.body.contains(initialItem) && !dragoverTarget) {
        containerElement.isScrolling = false
        return
    }
    if (containerElement.scrollTop > PX_DIFF) {
        containerElement.scrollTop -= PX_DIFF
        if (containerElement.isScrolling) {
            animations.push(window.requestAnimationFrame(() => goUp(containerElement, initialItem)))
        }
    }
}

const goDown = (containerElement, initialItem) => {
    if (!document.body.contains(initialItem) && !dragoverTarget) {
        containerElement.isScrolling = false
        return
    }
    if (containerElement.scrollTop + PX_DIFF + window.innerHeight < containerElement.scrollHeight) {
        containerElement.scrollTop += PX_DIFF
        if (containerElement.isScrolling) {
            animations.push(
                window.requestAnimationFrame(() => goDown(containerElement, initialItem)),
            )
        }
    }
}

const goUpSlow = (containerElement, initialItem) => {
    if (!document.body.contains(initialItem) && !dragoverTarget) {
        containerElement.isScrolling = false
        return
    }
    if (containerElement && containerElement.scrollTop > SLOW_PX_DIFF) {
        containerElement.scrollTop -= SLOW_PX_DIFF
        if (containerElement.isScrolling)
            animations.push(
                window.requestAnimationFrame(() => goUpSlow(containerElement, initialItem)),
            )

        // else if (containerElement.isScrolling)
        //     animations.push(window.requestAnimationFrame(() => goUp(containerElement, initialItem)));
    }
}

const goDownSlow = (containerElement, initialItem) => {
    if (!document.body.contains(initialItem) && !dragoverTarget) {
        containerElement.isScrolling = false
        return
    }
    if (
        containerElement.scrollTop + SLOW_PX_DIFF + window.innerHeight <=
        containerElement.scrollHeight
    ) {
        containerElement.scrollTop += SLOW_PX_DIFF
        if (containerElement.isScrolling)
            animations.push(
                window.requestAnimationFrame(() => goDownSlow(containerElement, initialItem)),
            )

        // else if (containerElement.isScrolling)
        //     animations.push(window.requestAnimationFrame(() => goDown(containerElement, initialItem)));
    }
}

const onMove = event => {
    let x, y
    if (event.type == 'drag') {
        x = event.clientX || (event.target && event.target.getBoundingClientRect().left)
        y = event.clientY || (event.target && event.target.getBoundingClientRect().top)
    } else {
        const touch = event.touches[event.touches.length - 1]
        x = touch.clientX
        y = touch.clientY
    }

    animations.forEach(id => window.cancelAnimationFrame(id))
    animations = []

    const isMouseOnTop = y < OFFSET

    const isMouseOnTopSlow = y < SLOW_OFFSET

    const isMouseOnBottom = y >= window.innerHeight - OFFSET

    const isMouseOnBottomSlow = y >= window.innerHeight - SLOW_OFFSET

    const isMouseOnLeft = x < SIDE_OFFSET

    const isMouseOnRight = x >= window.innerWidth - SIDE_OFFSET

    const throttleSlidePrev = throttle(swiper => swiper.slidePrev(), 100)
    const throttleSlideNext = throttle(swiper => swiper.slideNext(), 100)

    let container = event.target && event.target.closest('.scroll-container')

    if (container && (isMouseOnTop || isMouseOnBottom || isMouseOnLeft || isMouseOnRight)) {
        //container.isScrolling = true;

        if (isMouseOnTop) {
            window.requestAnimationFrame(() => goUp(container, event.target))
        } else if (isMouseOnBottom) {
            window.requestAnimationFrame(() => goDown(container, event.target))
        } else if (isMouseOnLeft) {
            if (!slideDelay)
                window.requestAnimationFrame(() => {
                    let swiper = document.querySelector('.swiper-container').swiper
                    if (isMobile && swiper.activeIndex > 1) {
                        throttleSlidePrev(swiper)
                    }
                    slideDelay = true
                    setTimeout(() => {
                        slideDelay = false
                    }, 1000)
                })
        } else {
            if (!slideDelay)
                window.requestAnimationFrame(() => {
                    let swiper = document.querySelector('.swiper-container').swiper
                    if (isMobile && swiper.activeIndex < 3) {
                        throttleSlideNext(swiper)
                    }
                    slideDelay = true
                    setTimeout(() => {
                        slideDelay = false
                    }, 1000)
                })
        }
    } else if ((container && isMouseOnBottomSlow) || isMouseOnTopSlow) {
        //container.isScrolling = true;
        if (isMouseOnBottomSlow) {
            window.requestAnimationFrame(() => goDownSlow(container, event.target))
        } else {
            window.requestAnimationFrame(() => goUpSlow(container, event.target))
        }
    } else if (
        container &&
        !isMouseOnTop &&
        !isMouseOnBottom &&
        !isMouseOnLeft &&
        !isMouseOnRight &&
        !isMouseOnBottomSlow &&
        !isMouseOnTopSlow
    ) {
        container.isScrolling = false
    }
}

const onDragOver = e => {
    dragoverTarget = e.target
}

const throttleOnMove = throttle(onMove, 20)
const throttleDragOver = throttle(onDragOver, 20)

const addEventListenerForAutoScroll = item => {
    item.addEventListener('touchmove', throttleOnMove)
    item.addEventListener('drag', throttleOnMove)
    document.addEventListener('dragover', throttleDragOver)
}

const removeEventListenerForAutoScroll = item => {
    dragoverTarget = null
    item.removeEventListener('touchmove', throttleOnMove)
    item.removeEventListener('drag', throttleOnMove)
    document.removeEventListener('dragover', throttleDragOver)
}

export { addEventListenerForAutoScroll, removeEventListenerForAutoScroll }
