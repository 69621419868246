import React from 'react'
import PanelHeader from 'components/panels/PanelHeader'
import Action from 'components/quests/Action'
import styles from 'components/panels/history-panel.module.scss'
import cn from 'classnames'
import useGetHistory from 'refactor/hooks/api/useGetHistory'
import SearchHeader from 'components/panels/SearchHeader'

function HistoryPanel(props) {
    const { actions } = useGetHistory()

    return (
        <div className={cn(styles.historyPanelComp, 'history-panel-comp', 'h100')}>
            <div className={cn(styles.panelHeaderContainer)}>
                <PanelHeader showHeader />
            </div>

            <div className={cn(styles.searchHeaderContainer)}>
                <SearchHeader />
            </div>

            <div className={cn(styles.actionContainer)}>
                {actions.map(action => {
                    return <Action key={action.id} action={action} />
                })}
            </div>
        </div>
    )
}

export default HistoryPanel
