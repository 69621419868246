import useStore from 'state/knovStore'
import { isMobile } from 'react-device-detect'

/*
TODO Very BRITTLE highlight logic speaad acroos multiple files and two swipers (default, and animation placeholder in useAnimatedSlide).

The idea is to add a center-panel class to the transform-container of the currently active center panel (on desktop). As the center slides the center-panel class needs to be dynamically removed from the previous center panel and added to the new center panel.

This is complicated by the fact the we animate the slide by overlatying a temporary swiper instance whose center-panel class placement also has to be correctly managed to make the animation look correct.

We do all of this by:

1. Checking getCenterIndex() on PanelContainer mount and when rendering for PanelContainer.animationPanels in PanelContainer.tsx to set center-panel class.
2. Calling highlightCenterPanel() when swiping via swiperApi.slideTo.
3. Calling clearCenterHighlights() after sliding the animation swiper in useAnimatedSlide, and relying on step 1 to declaratively set the class in case of animationPanels.
4. Calling highlightCenterPanel() in useSlideEffects after animated sliding.

A much easier way to do this would be to keep swiper.activeIndex in global state and re-render when this changes with center-panel class set declaratively in PanelContainer.tsx .transofrm-container but this currently causes the slide animation to jitter so we are doing this imperatively atm.
*/

export function clearCenterHighlights() {
    const elements = document.getElementsByClassName('center-panel')
    Array.from(elements).forEach(el => el.classList.remove('center-panel'))
}

export function highlightCenterPanel(centerIndex: number) {
    if (isMobile) return

    //clearCenterHighlights()

    // When this is called from swiperApi.slideTo, we can run into the error that getPanelRef returns undefined as it is not yet set (in the ref callback for <Panel /> in PanelController.jsx) as it is still unmounted.
    // This is addressed by setting lastInsertedPanel in zustand with the useSlideEffects hook subscribing and using a useLayoutEffect to call slideTo on lastInsertedPanel once it has mounted.
    // This is also brittle to re-renders which will blow away the classlist, and requires react.memo(PanelContainer) to work so no render occurs after this since props shouldnt? change.

    const panels = useStore.getState().panels.getPanels()
    //console.log('css animation new center panel', centerIndex, panels[centerIndex], panels[centerIndex].getPanelRef(), panels[centerIndex].getPanelRef()?.querySelector('.transform-container'))
    const newCenter = panels[centerIndex]?.getPanelRef()
    const transformContainer = newCenter?.querySelector('.transform-container')

    if (transformContainer) {
        // This messes up the slide animation using transform.
        //transformContainer.style.transform = ''
    }
    //console.log('transformContainer after', transformContainer)
}
