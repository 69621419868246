import usePanelContext from 'refactor/hooks/usePanelContext'
import useStore from 'state/knovStore'
import { getSwiperRef, getCenterIndex } from 'state/imperativeApis/swiperApi'
import { isMobile } from 'react-device-detect'

export default function useRemoveRight() {
    const { panel } = usePanelContext()
    const panelId = panel?.panelId

    return function removeRight() {
        if (!panelId) return

        const panels = useStore.getState().panels
        const getIndexById = panels.getIndexById
        const panelIndex = getIndexById(panelId)
        const rightPanelIndex = getIndexById(panelId) + 1
        const clickedRightPanel = panelIndex > getCenterIndex()

        // Make sure there is a non empty panel to remove.
        if (rightPanelIndex && panels.state.length > rightPanelIndex - 1) {
            if (clickedRightPanel) {
                panels.removePanelAtIndex(rightPanelIndex)
            } else {
                const viewIndex = panels.state.length - 3
                const slideDirection =
                    !isMobile &&
                    getSwiperRef()?.current?.activeIndex === viewIndex &&
                    panelIndex === viewIndex
                        ? 'right'
                        : 'left'
                useStore.getState().panels.startAnimatedRemove(rightPanelIndex, slideDirection)
            }
        }
    }
}
