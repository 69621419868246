import React from 'react'
import ReactTooltip from 'react-tooltip'
import CopyTooltip from 'components/shared/CopyTooltip'
import { insertToClipboard } from 'utils'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'

export default function CopyLinkButton({ children, copyContent, tip = 'Copy.', contextStyles }) {
    const showConfirmationTooltip = (tipped, confirmationText = 'Copied!', tooltipText) => {
        if (window.isPanelScrolling) return

        if (tipped.current) {
            tipped.current.setAttribute('data-delay-show', 0)
            tipped.current.setAttribute('data-tip', confirmationText)
            ReactTooltip.rebuild()
            // ReactTooltip.hide(tipped.current)
            ReactTooltip.show(tipped.current)
            tipped.current.setAttribute('data-delay-show', 1000)
            tipped.current.setAttribute('data-tip', tooltipText)
            if (isMobile) setTimeout(() => ReactTooltip.hide(tipped.current), 1000)
        }
    }

    const tooltipText = tip ?? 'Copy'
    const tipped = React.createRef()

    const onClick = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const copy = copyContent()
        if (copy) {
            insertToClipboard(copy)
            showConfirmationTooltip(tipped, 'Copied!', tooltipText)
        }
    }

    return (
        <div className={cn(contextStyles)} onClick={onClick}>
            <CopyTooltip tip="Copied!" offset={0} ref={tipped}>
                {children}
            </CopyTooltip>
        </div>
    )
}
