import React from 'react'
import useStore from 'state/knovStore'
import { focusActiveNewAnswer, focusSideNewAnswer } from 'state/imperativeApis/editorApi'
import WithTooltip from 'components/shared/WithTooltip'
import questModel from 'components/quests/questModel'
import { cacheQuest } from 'state/cache'
import useStreamFilters from 'components/filters/useStreamFilters'
import { getCenterPanel } from 'state/imperativeApis/swiperApi'

export default function NewQuestButton(props) {
    const panelId = props.panelId
    const { selectNewFilter } = useStreamFilters(panelId, { append: props.append })

    const onClick = ev => {
        ev.stopPropagation()
        ev.preventDefault()

        const panels = useStore.getState().panels
        const panel = panelId ? panels.getPanel(panelId) : getCenterPanel()

        if (panel) {
            // On mobile you can center an empty panel with no filter, in this case default to private perms.
            let newFilter = panel?.filter || getCenterPanel()?.filter || { private: true }
            const draftQuest = questModel.newQuestFromFilter({}, newFilter)
            cacheQuest(draftQuest)

            selectNewFilter({ questId: draftQuest.id })
            if (!panelId) {
                history.pushState({}, null, '/new')
            }
        }
        if (props.callback) props.callback()
    }

    return (
        <WithTooltip tip="New Thread" place={props.place}>
            {React.cloneElement(props.children, {
                onClick: onClick,
            })}
        </WithTooltip>
    )
}
