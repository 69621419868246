import React from 'react'
import useStore from 'state/knovStore'
import styles from 'components/answers/answer-controls.module.scss'
import cn from 'classnames'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'

export default function RecordScreenButton(props) {
    const openScreenRecorderModal = useStore(
        state => state.actions.modalActions.openScreenRecorderModal,
    )

    const onVideo = () => {
        if (!gon.currentUser) {
            $('#auth-modal').modal('show')
            return
        }

        openScreenRecorderModal(props.quest, props.videoHandler, props.answer)
    }

    const answerHoverStyles = props.answerHover ? styles.hoverBtn : null
    const mobileStyles = isMobile ? styles.mobileBtn : null

    return (
        <WithTooltip tip="Record screen.">
            <div
                className={cn(
                    styles.editMediaBtn,
                    answerHoverStyles,
                    styles.videoHover,
                    styles.recordScreenBtn,
                    'quest-btn video-modal-btn',
                    mobileStyles,
                )}
                onClick={onVideo}
            >
                <i className="fa fa-desktop"></i>
            </div>
        </WithTooltip>
    )
}
