import React from 'react'
import cn from 'classnames'
import ReactTooltip from 'react-tooltip'
import CopyTooltip from 'components/shared/CopyTooltip'
import { insertToClipboard } from 'utils'

export default function CopyLinkButton({
    getPublicLink,
    isMobile = false,
    styles = {},
    tooltip = false,
    showLabel = true,
}) {
    const showConfirmationTooltip = (tipped, confirmationText = 'Copied!', tooltipText) => {
        if (window.isPanelScrolling) return

        if (tipped.current) {
            tipped.current.setAttribute('data-delay-show', 0)
            tipped.current.setAttribute('data-tip', confirmationText)
            ReactTooltip.rebuild()
            // ReactTooltip.hide(tipped.current)
            ReactTooltip.show(tipped.current)
            tipped.current.setAttribute('data-delay-show', 1000)
            tipped.current.setAttribute('data-tip', tooltipText)
            if (isMobile) setTimeout(() => ReactTooltip.hide(tipped.current), 1000)
        }
    }

    const tooltipText = 'Copy Treechat Link'
    const tipped = React.createRef()
    const iconClass = 'fa fa-link'

    const mobileAnswerBtn = isMobile ? styles.mobileAnswerBtn : null

    const inner = (
        <div
            className={cn(styles.answerBtn, mobileAnswerBtn)}
            onClick={async ev => {
                ev.preventDefault()
                ev.stopPropagation()
                const publicLink = await getPublicLink(ev)
                insertToClipboard(publicLink)
                showConfirmationTooltip(tipped, 'Copied!', tooltipText)
            }}
        >
            <i className={iconClass}></i>

            {showLabel && (
                <span className={cn('control-label', styles.controlLabel)}>copy link</span>
            )}
        </div>
    )

    return (
        <div className={cn(styles.answerControl, 'copy-link-button-comp')}>
            {tooltip ? (
                <div className="copy-button-comp">
                    <CopyTooltip tip={tooltipText} offset={0} ref={tipped}>
                        {inner}
                    </CopyTooltip>
                </div>
            ) : (
                inner
            )}
        </div>
    )
}
