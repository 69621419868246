import React, { ReactNode } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import PanelContainer from 'components/panels/PanelContainer'
import Panel from 'components/panels/Panel'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import EmptyPanel from 'components/panels/EmptyPanel'
import HistoryPanel from 'components/panels/HistoryPanel'
import useStore from 'state/knovStore'
import { clearCenterHighlights } from 'state/imperativeApis/cssAnimations'
import { isMobile } from 'react-device-detect'

export default function useAnimatedSlide() {
    const panels = useStore.getState().panels.state

    const animatedPanelInsert = useStore.getState().panels.animatedPanelInsert
    const completeAnimatedInsert = useStore.getState().panels.completeAnimatedInsert

    const animatedPanelRemove = useStore.getState().panels.animatedPanelRemove
    const completeAnimatedRemove = useStore.getState().panels.completeAnimatedRemove

    const renderAnimationSwiper = (slides: ReactNode[], direction: 'left' | 'right') => {
        if (animatedPanelInsert) {
            return (
                <Swiper
                    slidesPerView={3}
                    initialSlide={direction === 'left' ? 0 : 1}
                    style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
                    onSwiper={animatedSwiper => {
                        animatedSwiper.once('slideChangeTransitionEnd', () => {
                            completeAnimatedInsert()
                            clearCenterHighlights()
                        })
                        setTimeout(() => {
                            if (!window.pause) {
                                animatedSwiper.slideTo(direction === 'left' ? 1 : 0)
                            }
                        })
                    }}
                >
                    {slides}
                </Swiper>
            )
        } else if (animatedPanelRemove) {
            return (
                <Swiper
                    slidesPerView={isMobile ? 1 : 3}
                    initialSlide={direction === 'left' ? 0 : 1}
                    style={{ position: 'absolute', top: 0, left: 0, right: 0 }}
                    onSwiper={animatedSwiper => {
                        animatedSwiper.once('slideChangeTransitionEnd', () => {
                            completeAnimatedRemove()
                            clearCenterHighlights()
                        })
                        setTimeout(() => {
                            animatedSwiper.slideTo(direction === 'left' ? 1 : 0)
                        })
                    }}
                >
                    {slides}
                </Swiper>
            )
        }
    }

    const renderRightAnimationInsertPanels = (insertIndex: number) => {
        const animatedOverlayPanels = [
            <SwiperSlide key={Math.random()} />,
            <SwiperSlide key={Math.random()} />,
        ]
        const insertingCenter = getCenterIndex() === insertIndex
        if (insertingCenter) {
            animatedOverlayPanels.push(
                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[insertIndex]}
                        index={insertIndex}
                        hide={false}
                        animationPanel
                        isEmpty={panels[insertIndex].empty}
                        animationStyles={panels[insertIndex].getAnimationStyles()}
                    >
                        {panels[insertIndex].empty ? (
                            <EmptyPanel />
                        ) : panels[insertIndex].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,

                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[insertIndex + 1]}
                        index={insertIndex + 1}
                        hide={false}
                        animationPanel
                        isEmpty={panels[insertIndex + 1].empty}
                        animationStyles={panels[insertIndex + 1].getAnimationStyles()}
                    >
                        {panels[insertIndex + 1].empty ? (
                            <EmptyPanel />
                        ) : panels[insertIndex + 1].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,
            )
        } else {
            animatedOverlayPanels.push(
                <SwiperSlide key={Math.random()} />,
                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[insertIndex]}
                        index={insertIndex}
                        hide={false}
                        animationPanel
                        isEmpty={panels[insertIndex].empty}
                        animationStyles={panels[insertIndex].getAnimationStyles()}
                    >
                        {panels[insertIndex].empty ? (
                            <EmptyPanel />
                        ) : panels[insertIndex].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,
            )
        }

        return renderAnimationSwiper(animatedOverlayPanels, 'right')
    }

    const renderLeftAnimationInsertPanels = (insertIndex: number) => {
        const animatedOverlayPanels = []
        const insertingCenter = getCenterIndex() === insertIndex
        if (insertingCenter) {
            animatedOverlayPanels.push(
                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[insertIndex - 1]}
                        index={insertIndex - 1}
                        hide={false}
                        animationPanel
                        isEmpty={panels[insertIndex - 1].empty}
                        animationStyles={panels[insertIndex - 1].getAnimationStyles()}
                    >
                        {panels[insertIndex - 1].empty ? (
                            <EmptyPanel />
                        ) : panels[insertIndex - 1].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,
                <SwiperSlide key={Math.random()} />,
                <SwiperSlide key={Math.random()} />,
                <SwiperSlide key={Math.random()} />,
            )
        } else {
            animatedOverlayPanels.push(
                <SwiperSlide key={Math.random()}>
                    {!panels[insertIndex - 2].empty && (
                        <PanelContainer
                            panel={panels[insertIndex - 2]}
                            index={insertIndex - 2}
                            hide={false}
                            animationPanel
                            isEmpty={panels[insertIndex - 2].empty}
                            animationStyles={panels[insertIndex - 2].getAnimationStyles()}
                        >
                            {panels[insertIndex - 2].empty ? (
                                <EmptyPanel />
                            ) : panels[insertIndex - 2].filter?.history ? (
                                <HistoryPanel />
                            ) : (
                                <Panel />
                            )}
                        </PanelContainer>
                    )}
                </SwiperSlide>,

                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[insertIndex - 1]}
                        index={insertIndex - 1}
                        hide={false}
                        animationPanel
                        isEmpty={panels[insertIndex - 1].empty}
                        animationStyles={panels[insertIndex - 1].getAnimationStyles()}
                    >
                        {panels[insertIndex - 1].empty ? (
                            <EmptyPanel />
                        ) : panels[insertIndex - 1].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,

                <SwiperSlide key={Math.random()} />,
                <SwiperSlide key={Math.random()} />,
            )
        }

        return renderAnimationSwiper(animatedOverlayPanels, 'left')
    }

    const renderAnimationRemovePanels = (removeIndex: number, direction: 'left' | 'right') => {
        const animatedOverlayPanels = []
        if (removeIndex < getCenterIndex()) {
            // Removing left panel, left panels slide right.
            animatedOverlayPanels.push(
                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[removeIndex - 1]}
                        index={removeIndex - 1}
                        hide={false}
                        animationPanel
                        isEmpty={panels[removeIndex - 1].empty}
                        animationStyles={panels[removeIndex - 1].getAnimationStyles()}
                    >
                        {panels[removeIndex - 1].empty ? (
                            <EmptyPanel />
                        ) : panels[removeIndex - 1].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,

                <SwiperSlide key={Math.random()} />,
                <SwiperSlide key={Math.random()} />,
                <SwiperSlide key={Math.random()} />,
            )
        } else if (removeIndex === getCenterIndex() && direction === 'left') {
            // Removing center panel, right panels slide left.
            // When isMobile and renderAnimationpanels is activated we are always dealing with the center panel, and 1 slider per view, so we only need to render 2 animation slides instead of 4 to perform the slide animation.
            animatedOverlayPanels.push(
                !isMobile && <SwiperSlide key={Math.random()} />,

                <SwiperSlide key={Math.random()} />,

                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[removeIndex + 1]}
                        index={removeIndex + 1}
                        hide={false}
                        animationPanel
                        isEmpty={panels[removeIndex + 1].empty}
                        animationStyles={panels[removeIndex + 1].getAnimationStyles()}
                    >
                        {panels[removeIndex + 1].empty ? (
                            <EmptyPanel />
                        ) : panels[removeIndex + 1].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,

                !isMobile && (
                    <SwiperSlide key={Math.random()}>
                        <PanelContainer
                            panel={panels[removeIndex + 2]}
                            index={removeIndex + 2}
                            hide={false}
                            animationPanel
                            isEmpty={panels[removeIndex + 2].empty}
                            animationStyles={panels[removeIndex + 2].getAnimationStyles()}
                        >
                            {panels[removeIndex + 2].empty ? (
                                <EmptyPanel />
                            ) : panels[removeIndex + 2].filter?.history ? (
                                <HistoryPanel />
                            ) : (
                                <Panel />
                            )}
                        </PanelContainer>
                    </SwiperSlide>
                ),
            )
        } else if (removeIndex === getCenterIndex() && direction === 'right') {
            // Removing center panel at the end panels.length - 2 (bc of appended empty panel), left panels slide right.
            animatedOverlayPanels.push(
                !isMobile && (
                    <SwiperSlide key={Math.random()}>
                        <PanelContainer
                            panel={panels[removeIndex - 2]}
                            index={removeIndex - 2}
                            hide={false}
                            animationPanel
                            isEmpty={panels[removeIndex - 2].empty}
                            animationStyles={panels[removeIndex - 2].getAnimationStyles()}
                        >
                            {panels[removeIndex - 2].empty ? (
                                <EmptyPanel />
                            ) : panels[removeIndex - 2].filter?.history ? (
                                <HistoryPanel />
                            ) : (
                                <Panel />
                            )}
                        </PanelContainer>
                    </SwiperSlide>
                ),

                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[removeIndex - 1]}
                        index={removeIndex - 1}
                        hide={false}
                        animationPanel
                        isEmpty={panels[removeIndex - 1].empty}
                        animationStyles={panels[removeIndex - 1].getAnimationStyles()}
                    >
                        {panels[removeIndex - 1].empty ? (
                            <EmptyPanel />
                        ) : panels[removeIndex - 1].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,

                <SwiperSlide key={Math.random()} />,

                !isMobile && <SwiperSlide key={Math.random()} />,
            )
        } else {
            // Removing right panel, right panels slide left.
            animatedOverlayPanels.push(
                <SwiperSlide key={Math.random()} />,

                <SwiperSlide key={Math.random()} />,

                <SwiperSlide key={Math.random()} />,

                <SwiperSlide key={Math.random()}>
                    <PanelContainer
                        panel={panels[removeIndex + 1]}
                        index={removeIndex + 1}
                        hide={false}
                        animationPanel
                        isEmpty={panels[removeIndex + 1].empty}
                        animationStyles={panels[removeIndex + 1].getAnimationStyles()}
                    >
                        {panels[removeIndex + 1].empty ? (
                            <EmptyPanel />
                        ) : panels[removeIndex + 1].filter?.history ? (
                            <HistoryPanel />
                        ) : (
                            <Panel />
                        )}
                    </PanelContainer>
                </SwiperSlide>,
            )
        }

        animatedOverlayPanels.filter(panel => panel !== null && panel !== undefined)
        return renderAnimationSwiper(animatedOverlayPanels, direction)
    }

    const hidePanelDuringAnimation = index => {
        let show = true
        if (animatedPanelInsert?.direction === 'right') {
            show = index < animatedPanelInsert.index
        }
        if (animatedPanelInsert?.direction === 'left') {
            show = index >= animatedPanelInsert.index
        }
        if (animatedPanelRemove?.direction === 'right') {
            show = index > animatedPanelRemove.index
        }
        if (animatedPanelRemove?.direction === 'left') {
            show = index < animatedPanelRemove.index
        }
        return !show
    }

    return {
        hidePanelDuringAnimation,

        renderAnimatedInsertPanels: () => {
            if (animatedPanelInsert?.direction === 'right') {
                return renderRightAnimationInsertPanels(animatedPanelInsert.index)
            }
            if (animatedPanelInsert?.direction === 'left') {
                return renderLeftAnimationInsertPanels(animatedPanelInsert.index)
            }
            return null
        },

        renderAnimatedRemovePanels: () => {
            if (animatedPanelRemove) {
                return renderAnimationRemovePanels(
                    animatedPanelRemove.index,
                    animatedPanelRemove.direction,
                )
            }
            return null
        },
    }
}
