/* message passing system to communicate between the app in the webview and expo/react native */
import { Subject, timeoutWith, throwError, take, filter, timeout as rxtimeout } from 'rxjs'

export enum MsgSource {
    Web = 'web',
    Mobile = 'mobile',
}

export enum MsgTypes {
    Reply = 'reply',
    Default = 'default',
    Ping = 'ping',
    Notification = 'notification',
    ShareUrl = 'share',
    ShareFile = 'shareFile',
    Creds = 'credentials',
}

export class Message {
    id: string
    source: MsgSource
    content: string
    type?: MsgTypes
    inReplyTo?: string // The ID of the message to which this message is a reply
    responsePayload?: any

    constructor(
        content: string,
        source: MsgSource,
        type: MsgTypes = MsgTypes.Default,
        inReplyTo?: string,
        responsePayload?: any,
    ) {
        this.id = String(Date.now()) // Unique ID
        this.content = content
        this.type = type
        this.source = source
        this.inReplyTo = inReplyTo // Initialize if provided
        this.responsePayload = responsePayload
    }

    createReply(responsePayload?: any): Message {
        const reply = new Message(responsePayload, this.source, MsgTypes.Reply, this.id)
        // alert(`created reply: ${reply.toJson()}`)
        return reply
    }

    toJson(): string {
        return JSON.stringify({
            id: this.id,
            content: this.content,
            source: this.source,
            type: this.type,
            inReplyTo: this.inReplyTo,
            responsePayload: this.responsePayload,
        })
    }

    static fromJson(input: string | object): Message | null {
        const obj = typeof input === 'string' ? JSON.parse(input) : input

        if (Message.isValidMessage(obj)) {
            const newMsg = new Message(
                obj.content,
                obj.source,
                obj.type,
                obj.inReplyTo,
                obj.responsePayload,
            )
            newMsg.id = obj.id
            return newMsg
        } else {
            console.error('Invalid input:', obj)
            return null
        }
    }

    private static isValidMessage(obj: any): obj is Message {
        return (
            obj &&
            typeof obj.content === 'string' &&
            Object.values(MsgSource).includes(obj.source) &&
            (typeof obj.type === 'undefined' || Object.values(MsgTypes).includes(obj.type))
        )
    }
}

export function replyTo(
    eventBus$: Subject<Message>,
    originalMessage: Message,
    source: MsgSource,
    responsePayload?: any,
): void {
    const replyMessage = originalMessage.createReply(responsePayload)
    replyMessage.source = source
    replyMessage.type = MsgTypes.Reply
    // alert(`replying with: ${replyMessage.toJson()}`)
    eventBus$.next(replyMessage)
}

/**
 * Sends a message and awaits a reply, with configurable timeout and retry logic.
 *
 * @param eventBus$ - An RxJS Subject used as the event bus for sending and receiving messages.
 * @param message - The message to send, containing a unique ID.
 * @param timeout - The time (in milliseconds) to wait for a reply before considering it a failure. Default is 500ms.
 * @param retries - The number of times to retry sending the message if a reply is not received within the timeout period. Default is 0.
 *
 * @returns A Promise that resolves with the reply message if successful or rejects with an error if all attempts fail.
 *
 * The function works as follows:
 * 1. Sends the message using the event bus.
 * 2. Waits for a reply that matches the message's unique id w/in the timeout
 *    specified.
 * 3. If a reply is received, the Promise is resolved with the reply message.
 * 4. If a reply is not received within the timeout and there are remaining
 *    retries, attempt to resend the message and wait again.
 * 5. If all retries are exhausted without receiving a reply, the Promise is
 *    rejected with a timeout error.
 * 6. If an error occurs in the observable pipeline, and there are remaining
 *    retries, the function will attempt to resend the message and wait again.
 * 7. If all retries are exhausted after an error in the observable, the
 *    Promise is rejected with the error.
 */
export function sendAndAwaitReply(
    eventBus$: Subject<Message>,
    message: Message,
    options: { timeout?: number; retries?: number } = {},
): Promise<any> {
    const { timeout = 500, retries = 2 } = options

    return new Promise((resolve, reject) => {
        let attempts = 0

        function attemptSend() {
            // set up a timeout to reject the promise if no reply is received
            const timeoutHandle = setTimeout(() => {
                clearTimeout(timeoutHandle)
                if (attempts++ >= retries) {
                    reject(new Error('Reply Timeout'))
                } else {
                    attemptSend()
                }
            }, timeout)

            eventBus$
                .pipe(
                    // only pass messages whose inReplyTo matches the message's
                    // id to .subscribe
                    filter(msg => msg.type === MsgTypes.Reply),
                    filter(msg => msg.inReplyTo === message.id),
                    take(1),
                )
                .subscribe(
                    replyMsg => {
                        // if we go there we got a reply, so resolve the promise
                        clearTimeout(timeoutHandle)
                        resolve(replyMsg)
                    },
                    err => {
                        // reject the promise and clean up
                        clearTimeout(timeoutHandle)
                        if (attempts++ >= retries) {
                            reject(err)
                        } else {
                            attemptSend()
                        }
                    },
                )

            // send the message on to the eventbus
            eventBus$.next(message)
        }

        attemptSend()
    })
}
