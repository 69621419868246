import { Quill } from 'react-quill-new'

const Delta = Quill.import('delta')
const Clipboard = Quill.import('modules/clipboard')

const URL_REGEX = /https?:\/\/[^\s]+/g

export class KnovClipboard extends Clipboard {
    // adapted from https://stackoverflow.com/a/51255601
    async onPaste(range, e) {
        if (e?.text?.match(URL_REGEX)) {
            const range = this.quill.getSelection()
            const delta = this.quill.getContents()

            // remove the trailing \n quill likes to add if present
            let lastOp = delta.ops[delta.ops.length - 1]
            if (lastOp.insert && typeof lastOp.insert === 'string')
                lastOp.insert = lastOp.insert.replace(/\n$/, '')

            const index = range.index
            // NOTE: this mirrors the logic in auto_links.js
            delta.ops.push({ insert: e.text, attributes: { link: e.text } })
            this.quill.setContents(delta, Quill.sources.USER)
            this.quill.setSelection(index + e.text.length, 0) // move cursor to the end of the link
            this.quill.focus()
        } else if (e?.clipboardData?.files?.[0]?.type == 'image/png') {
            e.preventDefault()
            const range = this.quill.getSelection()
            const file = e.clipboardData.files[0]
            const blob = new Blob([file], { type: file.type })
            const url = await blobToDataUrl(blob)
            // console.log('URL is now: ', url)
            const index = range.index
            setTimeout(() => {
                const delta = new Delta().retain(index).insert({ image: url })
                this.quill.updateContents(delta, Quill.sources.USER)
                this.quill.focus()
            })
        } else {
            return Clipboard.prototype.onPaste.call(this, range, e)
        }
    }
}

/** converts a data url into a browser Blob object
 * from: https://github.com/quilljs/quill/issues/1089#issuecomment-614313509
 **/
export function dataUrlToBlob(dataUrl) {
    // Split the base64 string in data and contentType
    const blocks = dataUrl.split(';')
    // Get the content type of the image
    const contentType = blocks[0].split(':')[1]
    // get the real base64 content of the file
    const b64data = blocks[1].split(',')[1]

    let byteCharacters = atob(b64data)
    let byteArrays = []

    const sliceSize = 512

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        let slice = byteCharacters.slice(offset, offset + sliceSize)

        let byteNumbers = new Array(slice.length)
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i)
        }

        let byteArray = new Uint8Array(byteNumbers)

        byteArrays.push(byteArray)
    }

    let blob = new Blob(byteArrays, { type: contentType })
    return blob
}

function blobToDataUrl(blob) {
    return new Promise((resolve, _) => {
        const reader = new FileReader()
        reader.onloadend = () => resolve(reader.result)
        reader.readAsDataURL(blob)
    })
}
