import React from 'react'
import SpaceIcon from 'components/spaces/SpaceIcon'
import useStore from 'state/knovStore'
import styles from 'components/shared/loadingscreen.module.scss'

export default function LoadingScreen({ spaceId }) {
    const space = useStore.getState().getSpace(spaceId)

    return (
        <>
            <div className={styles.loading}>
                {space ? (
                    <SpaceIcon
                        space={space}
                        contextStyles={styles.loadingSpaceIcon}
                        initialStyles={styles.initialStyles}
                    />
                ) : (
                    <i className="fa fa-circle-o-notch fa-spin fa-lg" />
                )}
            </div>
        </>
    )
}
