import React from 'react'
import styles from './filter-options-control.module.scss'
import FilterIcon from 'components/filters/FilterIcon'
import WithTooltip from 'components/shared/WithTooltip'
import StreamName from 'components/filters/StreamName'
import cn from 'classnames'

export default function FilterOptionsControl({
    panel,
    filter,
    showFilterOptions,
    setShowFilterOptions,
    contextStyles,
    showLogo,
    showName,
    showHover,
    disable,
}) {
    const closeStyles = showFilterOptions ? styles.closeStyles : null
    const panelStyles = panel === 'middle' ? null : styles.rightPanel
    const isSignedIn = gon?.currentUser
    const memeChevronStyles = filter?.meme ? styles.memeChevron : null
    const onHoverStyles = showHover ? styles.onHover : null

    const Control = (
        <div className={styles.flexContainer}>
            {showName && (
                <div className={styles.streamNameContainer}>
                    <StreamName filter={filter} panel={panel} contextStyles={contextStyles} />
                </div>
            )}

            {!disable && isSignedIn && (
                <div className={styles.expandFilterBtnContainer}>
                    <div
                        className={cn(
                            styles.expandFilterBtn,
                            panelStyles,
                            closeStyles,
                            memeChevronStyles,
                        )}
                    >
                        {showFilterOptions ? (
                            <i className="fa fa-angle-up" />
                        ) : (
                            <i className="fa fa-angle-down" />
                        )}
                    </div>
                </div>
            )}
        </div>
    )

    return (
        <div
            className={cn(
                styles.filterOptionsControlComp,
                !disable && styles.pointer,
                onHoverStyles,
            )}
            onClick={() => !disable && isSignedIn && setShowFilterOptions(!showFilterOptions)}
        >
            {showLogo && (
                <div className={styles.filterIconContainer}>
                    <FilterIcon
                        user={gon.currentUser}
                        showNumNoti
                        filter={filter}
                        panel={panel}
                        type={'control'}
                        contextStyles={cn(styles.searchHeaderIcon, memeFilterIconStyles)}
                        initialStyles={styles.initialStyles}
                        fontIcon={styles.fontIcon}
                    />
                </div>
            )}
            {isSignedIn ? (
                <WithTooltip
                    disable={disable}
                    tip={'Choose stream.'}
                    contextStyles={styles.withTooltip}
                >
                    {Control}
                </WithTooltip>
            ) : (
                Control
            )}
        </div>
    )
}
