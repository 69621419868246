import React from 'react'
import cn from 'classnames'
import styles from './user-icon.module.scss'
import Gravatar from 'react-gravatar'
import Bot from 'assets/knov.svg'
import SpaceIcon from 'components/spaces/SpaceIcon'

export default function UserIcon(props, ref) {
    const { user, showKnovigator, virtualUserIcon } = props
    const clickableStyles = props.clickable ? styles.clickable : null
    let userPic

    if (showKnovigator) {
        userPic = (
            <div className="user-icon">
                <SpaceIcon contextStyles={styles.spaceIcon} />
            </div>
        )
    } else if (props.agentIcon) {
        userPic = (
            <div className={cn(styles.userIconComp, props.contextStyles)}>
                <img src={Bot} />
            </div>
        )
    } else if (virtualUserIcon) {
        userPic = (
            <div className={cn(styles.userIconComp, props.contextStyles)}>
                <img src={virtualUserIcon} />
            </div>
        )
    } else if (user) {
        const queryString = props.avatarQuery ? `?${props.avatarQuery}` : ''
        const avatarUrl = `${window.knovApiUrl}/api/v1/users/${user.id}/avatar${queryString}` // add updated_at to break browser cache.
        userPic = (
            <div className={cn(styles.userIconComp, props.contextStyles)}>
                <img src={avatarUrl} className={styles.userImage} />
            </div>
        )
    } else if (user?.md5_email) {
        userPic = (
            <div className={cn(styles.userIconComp, clickableStyles, props.contextStyles)}>
                <Gravatar md5={user.md5_email} />
            </div>
        )
    } else {
        userPic = <div className={cn(props.contextStyles, styles.userPlaceholder)} />
    }

    return <div className="user-icon-comp">{userPic}</div>
}
