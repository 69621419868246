import React from 'react'
import api from '../../api/api'
import useStore from 'state/knovStore'
import TeamLogo from 'components/shared/icons/TeamLogo'
import styles from 'components/quests/perms-options.module.scss'
import perms from 'lib/perms'
import cn from 'classnames'
import PublicIcon from 'components/shared/icons/PublicIcon'
import PrivateIcon from 'components/shared/icons/PrivateIcon'
import * as cache from 'state/cache'
import QuestOptionsHeader from 'components/quests/QuestOptionsHeader'

export default function PermsOptions(props) {
    const space = useStore(state => state.getActiveSpace)()
    const quest = props.quest
    const amOwner = perms.amQuestOwner(quest)

    const publishQuest = async perms => {
        props.close()
        if (!amOwner) return

        if (quest.is_draft) {
            const permedQuest = { ...quest, updated_at: Date.now(), ...perms }
            cache.cacheQuest(permedQuest)
        } else {
            // Clear team and rely on team_id to determine perms on the server.
            delete perms.team
            api.updateQuestPerms(props.quest.id, perms)
        }
    }

    // Access setters.
    const publishQuestToTeam = async team => {
        publishQuest({
            public: false,
            private: false,
            team_id: team.id,
            team: team,
        })
    }

    const setQuestPublic = async () => {
        publishQuest({
            public: true,
            private: false,
            team_id: null,
            team: null,
        })
    }

    const setQuestPrivate = async () => {
        publishQuest({
            public: false,
            private: true,
            team_id: null,
            team: null,
        })
    }

    const setQuestLink = async () => {
        publishQuest({
            public: false,
            private: false,
            team_id: null,
            team: null,
        })
    }

    const defaultOptions = [
        {
            type: 'publicPrivateLink',
            name: 'Home',
            value: 'PRIVATE',
            desc: 'Publish thread to Home stream.',
            tip: 'Home threads can be viewed only by you and anyone you @mention.',
            logo: <PrivateIcon contextStyles={styles.privateIcon} />,
            callback: () => setQuestPrivate(),
        },
        {
            type: 'publicPrivateLink',
            name: 'Public',
            value: 'PUBLIC',
            desc: 'Publish thread to Public stream.',
            tip: 'Public threads can be viewed by anyone.',
            logo: <PublicIcon contextStyles={styles.publicIcon} />,
            callback: () => setQuestPublic(),
        },
        {
            type: 'publicPrivateLink',
            name: 'Link',
            value: 'LINK',
            desc: 'Publish thread to Link stream.',
            tip: 'Link threads can be viewed by anyone with the thread url.',
            logo: (
                <div className={styles.purpleIcon}>
                    <i className="fa fa-link" />
                </div>
            ),
            callback: () => setQuestLink(),
        },
    ]

    const teamOptions = (() => {
        let options = []

        const userTeams = gon.currentUser ? gon.currentUser.space_teams : []
        let availableTeams
        if (props.quest.team) {
            availableTeams = userTeams.filter(team => team.id !== props.quest.team.id)
        } else {
            availableTeams = userTeams
        }
        for (const team of availableTeams) {
            options.push({
                type: 'team',
                name: team.name,
                value: team,
                desc: `Publish quest to ${team.name} stream.`,
                logo: team.team_image ? team.team_image.url : null,
                callback: () => publishQuestToTeam(team),
            })
        }

        return options
    })()

    const questOpt = (() => {
        let perms = 'PRIVATE'
        if (props.quest.team) {
            perms = 'TEAM'
        } else if (props.quest.public && !props.quest.private) {
            perms = 'PUBLIC'
        } else if (!props.quest.public && !props.quest.private && !props.quest.team_id) {
            perms = 'LINK'
        }
        if (perms === 'TEAM') return { value: 'TEAM' }
        else return defaultOptions.concat(teamOptions).find(opt => opt.value === perms)
    })()

    // render
    return (
        <div className={styles.permsOptionsComp}>
            {props.showHeader && (
                <QuestOptionsHeader title="Publish to Stream" close={props.close} />
            )}

            <div className={styles.permsOptionsContainer}>
                <div className={styles.permsOptions}>
                    {defaultOptions
                        //.filter(opt => opt.value !== questOpt.value)
                        .map((opt, ix) => {
                            return (
                                <div key={ix} className={styles.permsOptionsContainer}>
                                    <PermsOption
                                        opt={opt}
                                        isSelected={opt.value === questOpt.value}
                                    />
                                </div>
                            )
                        })}

                    {teamOptions.length > 0 && (
                        <div className={styles.separatorContainer}>
                            <div className={styles.separator}></div>
                        </div>
                    )}

                    {teamOptions
                        //.filter(opt => opt.value !== questOpt.value)
                        .map((opt, ix) => {
                            return (
                                <div key={ix} className={styles.permsOptionsContainer}>
                                    <PermsOption
                                        opt={opt}
                                        isSelected={opt.value === questOpt.value}
                                    />
                                </div>
                            )
                        })}
                </div>
            </div>
        </div>
    )
}

function PermsOption(props) {
    const opt = props.opt
    const team = opt.value

    const iconStyles = opt?.type === 'publicPrivateLink' ? styles.purpleIcon : null
    const selectedStyles = props.isSelected ? styles.selected : null

    return (
        <div className={cn(styles.permsOptionComp, selectedStyles)} onClick={opt.callback}>
            <span className={styles.permsIcon}>
                {opt.type === 'publicPrivateLink' ? (
                    opt.logo
                ) : (
                    <TeamLogo team={team} contextStyles={cn(styles.permsIcon, iconStyles)} />
                )}
            </span>

            <span className={styles.name}>{opt.name}</span>

            <span className={styles.description}>{opt.desc}</span>
        </div>
    )
}
