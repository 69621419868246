import React, { useState, useCallback, useRef } from 'react'
import api from 'api/api'
import styles from 'components/answers/vote-control.module.scss'
import cn from 'classnames'

export default function VoteControl({ answer, isUserSignedIn }) {
    const [voteTotal, setVoteTotal] = useState(answer.vote_total || 0)

    const addVoteTotal = useCallback(
        ev => {
            ev.stopPropagation()
            ev.preventDefault()

            if (isUserSignedIn) {
                setVoteTotal(voteTotal + 1)
                api.upvoteAnswer(answer.id)
            }
        },
        [voteTotal],
    )

    return (
        <div className={styles.voteControl}>
            <VoteAmount voteTotal={voteTotal} />
            <VoteButton addVoteTotal={addVoteTotal} />
        </div>
    )
}

function VoteButton({ addVoteTotal }) {
    const voteButtonRef = useRef(null)

    const animateVote = (ref, scale = 1.5) => {
        const duration = 100
        const comp = ref.current
        comp.style.transition = `all ${duration / 1000}s ease-in-out`
        //comp.style.backgroundColor = '#ffab46'
        //comp.style.color = 'white'
        comp.style.transform = `scale(${scale})`
        new Promise(resolve => setTimeout(resolve, duration))
            .then(() => {
                comp.style.backgroundColor = ''
                comp.style.color = ''
                comp.style.transform = ''
                return new Promise(resolve => setTimeout(resolve, duration))
            })
            .then(() => {
                comp.style.transition = ''
            })
    }

    return (
        <div
            className={styles.voteButton}
            onClick={useCallback(
                ev => {
                    addVoteTotal(ev)
                    animateVote(voteButtonRef)
                },
                [addVoteTotal],
            )}
            ref={voteButtonRef}
        >
            <i className="fa fa-caret-up" />
        </div>
    )
}

function VoteAmount({ voteTotal }) {
    return (
        <div className={cn(styles.voteAmount, voteTotal > 0 && styles.voteAmountBar)}>
            <i className="fa fa-caret-up" />
            {voteTotal}
        </div>
    )
}
