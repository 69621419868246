import React from 'react'
import { CurrencyTabs } from '../CurrencyTabs'
import styles from '../lock-vote.module.scss'

interface SettingsTabProps {
    useSats: boolean
}

export function SettingsTab({ useSats }: SettingsTabProps) {
    return (
        <>
            <div className={styles.popupDescription}>Configure displayed currency.</div>

            <CurrencyTabs useSats={useSats} />
        </>
    )
}
