import { useQuery } from '@tanstack/react-query'
import api from 'api/api'
import { Answer } from 'types/answers'
import { cacheAnswer, getCachedAnswer } from 'state/cache'

type Options = {
    cacheOnly?: boolean
}

export default function useGetAnswer(id: string, options: Options = {}) {
    const cachedAnswer = getCachedAnswer(id)
    //console.log('use get answer use get answerrrrrrrrrrrrrr', id, cachedAnswer)

    const result = useQuery({
        queryKey: ['answer', id],
        queryFn: async () => {
            const answer = await api.getAnswer(id)
            if (answer) {
                cacheAnswer(answer)
                return answer
            } else {
                return null
            }
        },

        enabled: !options.cacheOnly && id !== undefined && !cachedAnswer?.is_draft,
        staleTime: Infinity,
        gcTime: Infinity,
        retry: 0,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        networkMode: 'always',
    })

    return result.data
}
