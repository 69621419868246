import React from 'react'
import cn from 'classnames'
import styles from './file-embed.module.scss'
import { isMobile } from 'react-device-detect'

export default React.memo(function FileEmbed(props) {
    const answerId = props.answerId
    const canDelete = props.canDelete

    const deleteFile = attachmentId => {
        if (attachmentId && answerId && canDelete) {
            props.deleteFile(attachmentId, answerId)
        } else {
            props.removeFile()
        }
    }

    const file = props.file

    return (
        <div className={cn(styles.fileEmbed)} onClick={ev => ev.stopPropagation()}>
            <div className={styles.downloadBtn}>
                <div className={styles.icon}>
                    <i className="fa fa-paperclip" />
                </div>

                <a className={styles.name} href={file.url} download={true} target="_blank">
                    {file.name}
                </a>

                {props.showDelete && (
                    <div className={styles.delete}>
                        <i className="fa fa-close" onClick={() => deleteFile(file.id)} />
                    </div>
                )}
            </div>
        </div>
    )
})
