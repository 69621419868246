import React from 'react'
import cn from 'classnames'
import iconStyles from 'components/shared/icons/icons.module.scss'

export default function LinkIcon(props) {
    return (
        <div className={cn(iconStyles.iconComp, iconStyles.fontIconComp, props.contextStyles)}>
            <i className="fa fa-link fa-lg" />
        </div>
    )
}
