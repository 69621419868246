import React from 'react'
import SpaceIcon from 'components/spaces/SpaceIcon'
import Button from 'components/shared/buttons/Button'
import api from 'api/api'
import styles from 'components/spaces/space-invitation.module.scss'

export default function SpaceInvitation(props) {
    const invitation = props.invitation
    const space = invitation?.space

    const accepted = invitation?.standing && gon.currentUser?.spaces?.some(s => s.id === space.id)

    return (
        <div className={styles.spaceInvitationComp}>
            {invitation ? (
                <>
                    <div className={styles.spaceIconContainer}>
                        <SpaceIcon
                            space={space}
                            contextStyles={styles.invitationIcon}
                            initialStyles={styles.initialStyles}
                        />
                    </div>

                    {accepted ? (
                        <>
                            <div className={styles.inviteMessage}>
                                {`@${gon.currentUser?.name} is already a member of this space!`}
                            </div>

                            <div className={styles.backButtonContainer}>
                                <Button
                                    icon="fa fa-arrow-right"
                                    text={'Proceed to Space'}
                                    type={'primary'}
                                    onClick={() => (location.href = '/')}
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className={styles.inviteMessage}>
                                You've been invited to space{' '}
                                <span className={styles.spaceName}>{space.name}</span>.
                            </div>

                            <div className={styles.acceptButtonContainer}>
                                <AcceptButton invitation={invitation} />
                            </div>
                        </>
                    )}
                </>
            ) : (
                <div className={styles.noInvitation}>Unable to locate pending invitation.</div>
            )}
        </div>
    )
}

function AcceptButton(props) {
    const invitation = props.invitation
    const handleAccept = async () => {
        const data = await api.acceptSpaceInvitation(invitation.id)
        if (data.redirect) {
            if (!gon.currentUser)
                sessionStorage.setItem('attempted_path', `${location.pathname}${location.search}`)
            location.href = data.url
        }
    }

    return (
        <div className={styles.acceptButtonComp} onClick={handleAccept}>
            <Button text={'Accept'} type={'primary'} />
        </div>
    )
}

// Create standing invite.
//  - New person.
//  - Non-logged in user.
//      - How do we tell b/w new person and non-logged in user?
//  - Logged in user.
//      - Person not in space.
//      - Person already in space.
