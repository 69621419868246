import React, { useRef, useEffect, useState, useCallback } from 'react'
import CommonEditor from 'components/shared/CommonEditor'
import styles from 'components/panels/search-bar.module.scss'
import cn from 'classnames'
import { getText } from 'lib/value'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useStreamFilters from 'components/filters/useStreamFilters'
import { assignMiddleSearchBarRef, assignRightSearchBarRef } from 'state/imperativeApis/editorApi'

function SearchBar(props) {
    const placeholder = props.placeholder || 'Search stream...'
    const { panelId, filter } = usePanelContext()
    const query = filter?.query

    const { setQuery } = useStreamFilters(panelId)

    const [editorQuery, setEditorQuery] = useState(query)
    const onChange = useCallback(value => {
        setEditorQuery(value)
    }, [])

    const richEditor = useRef()
    useEffect(() => {
        if (props.panel === 'middle') assignMiddleSearchBarRef(richEditor.current)
        else assignRightSearchBarRef(richEditor.current)
    }, [])

    const searchHandler = order => {
        const editorValue = richEditor.current?.getEditor()?.getContents()
        const query = getText(editorValue)?.trim()
        //console.log('searchHandler', query, editorValue)
        setQuery(query, order)
        logEv('SEARCH', { filter })
    }

    const clearSearch = () => {
        setEditorQuery('')
        setQuery('')
    }

    const activeStyles = editorQuery ? styles.active : ''

    return (
        <div className={cn(styles.searchBarComp, 'search-bar-comp')}>
            {false && (
                <div className={styles.searchIcon}>
                    <i className="fa fa-search" />
                </div>
            )}

            <div className={cn(styles.commonEditorContainer, 'new-quest-overflow-container')}>
                <CommonEditor
                    // Make sure to init value  when switching streams/memes.
                    key={JSON.stringify(filter)}
                    ref={richEditor}
                    value={editorQuery}
                    placeholder={placeholder}
                    postHandler={() => searchHandler('relev')}
                    onChange={onChange}
                    //users={this.props.users}
                    //tags={this.props.tags}
                    //ignoreQuestMentions={true}
                    type="search"
                />
            </div>

            <div className={styles.searchButtonsContainer}>
                {editorQuery && (
                    <div className={styles.cancelSearchButton} onClick={clearSearch}>
                        <i className="fa fa-remove" />
                    </div>
                )}

                <div
                    className={cn(styles.searchButton, activeStyles)}
                    onClick={() => searchHandler('relev')}
                >
                    <i className="fa fa-search" />
                </div>

                {false && (
                    <div
                        className={cn(styles.searchButton, activeStyles)}
                        onClick={() => searchHandler('semantic')}
                    >
                        <i className="fa fa-magic" />
                    </div>
                )}
            </div>
        </div>
    )
}

export default SearchBar
