import React, { useEffect, useRef } from 'react'
import ReactTooltip from 'react-tooltip'
import { getTooltipRef } from '../../state/imperativeApis/tooltipApi'
import cn from 'classnames'

const show = tipped => ReactTooltip.show(tipped)
const hide = tipped => ReactTooltip.hide(tipped)

export default function WithTooltip(props) {
    const tipped = useRef(null)

    useEffect(() => {
        if (!props.disable)
            return () => {
                hide(tipped.current)
                getTooltipRef()?.current?.clearTimer?.()
            }
    }, [props.disable])

    const onMouseEnter = () => {
        if (props.disable) return

        show(tipped.current)
    }

    const onMouseLeave = () => {
        if (props.disable) return

        hide(tipped.current)
        getTooltipRef()?.current?.clearTimer?.()
    }

    return props.disable ? (
        props.children
    ) : (
        <span
            ref={tipped}
            className={cn('with-tool-tip-comp', props.contextStyles)}
            data-tip={props.tip}
            data-effect="solid"
            data-place={props.place || 'bottom'}
            data-offset={`{'left': ${props.offset || 0}}`}
            data-delay-show={1000}
            data-arrow-color="rgba(0,0,0,0)"
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {props.children}
        </span>
    )
}
