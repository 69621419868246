import React from 'react'
import Modal from 'react-modal'
import PlayAll from '../modals/PlayAll'
import useStore from 'state/knovStore'

Modal.setAppElement('#knovigator-body')

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        width: '100%',
        padding: 0,
        background: 'rgb(238, 238, 238)',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
    },
}

export default function StoryModal(props) {
    const isStoryOpen = useStore(state => state.isStoryOpen)
    const closeStoryModal = useStore(state => state.actions.modalActions.closeStoryModal)
    const modalParams = useStore(state => state.modalParams) || props.modalParams

    const close = e => {
        closeStoryModal()
    }

    return (
        <Modal
            isOpen={isStoryOpen}
            onRequestClose={closeStoryModal}
            style={customStyles}
            contentLabel="Knov Modal"
        >
            <div
                style={{
                    position: 'absolute',
                    cursor: 'pointer',
                    display: 'flex',
                    top: '40px',
                    right: '30px',
                    zIndex: '1000',
                    fontSize: '20px',
                    textAlign: 'center',
                }}
                onClick={close}
            >
                <i className="fa fa-close" />
            </div>

            <div
                style={{
                    alignSelf: 'center',
                    width: '100%',
                    height: '100%',
                }}
            >
                {getModalContent(modalParams, closeStoryModal)}
            </div>
        </Modal>
    )
}

function getModalContent(modalParams, closeModal) {
    if (!modalParams) return null

    switch (modalParams.type) {
        case 'PlayAll':
            return (
                <PlayAll
                    modalParams={modalParams}
                    closeModal={closeModal}
                    height={'100vh'}
                    width={'100%'}
                />
            )
    }
}
