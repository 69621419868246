import React from 'react'
import { highlightCenterPanel } from 'state/imperativeApis/cssAnimations'
import useStore from 'state/knovStore'
import { isMobile } from 'react-device-detect'

const swiperRef = React.createRef()

// TODO - improve swiperApi so that all swiper actions run through this api
export function getSwiperRef() {
    return swiperRef
}

export function getNumPanels() {
    return swiperRef.current?.slides.length
}

export function getLeftIndex() {
    return swiperRef.current?.activeIndex
}

export function getCenterIndex() {
    return isMobile ? swiperRef.current?.activeIndex : swiperRef.current?.activeIndex + 1
}

export function getRightIndex() {
    return isMobile ? swiperRef.current?.activeIndex : swiperRef.current?.activeIndex + 2
}

export function isPanelLeft(panelId) {
    const panels = useStore.getState().panels
    const panelIx = panels.getIndexById(panelId)
    return panelIx === getLeftIndex()
}

export function isPanelCenter(panelId) {
    const panels = useStore.getState().panels
    const panelIx = panels.getIndexById(panelId)
    return panelIx === getCenterIndex()
}

export function isPanelRight(panelId) {
    const panels = useStore.getState().panels
    const panelIx = panels.getIndexById(panelId)
    return panelIx === getRightIndex()
}

export function getLeftPanel() {
    const panelState = useStore.getState().panels.state
    return panelState[getLeftIndex()]
}

export function getCenterPanel() {
    const panelState = useStore.getState().panels.state
    return panelState[getCenterIndex()]
}

export function getRightPanel() {
    const panelState = useStore.getState().panels.state
    return panelState[getRightIndex()]
}

export function slideTo(index, speed) {
    //console.log('slideTo', index, speed === 0 ? 'instant' : 'slide')
    if (speed !== 0) {
        highlightCenterPanel(index)
    }
    swiperRef.current?.slideTo(isMobile ? index : index - 1, speed)
}

export function slidePrev(offset = 1) {
    console.log('swiper api slide right to index', getCenterIndex() - offset)
    slideTo(getCenterIndex() - offset, 0)
}

export function slideNext(offset = 1) {
    console.log('swiper api slide left to index', getCenterIndex() + offset)
    slideTo(getCenterIndex() + offset, 0)
}

export function getPanelsToLeft(index) {
    const panels = useStore.getState().panels.state
    return panels.slice(0, index)
}

export function getPanelsToRight(index) {
    const panels = useStore.getState().panels.state
    return panels.slice(index + 1)
}
