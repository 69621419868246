import React from 'react'
import cn from 'classnames'
import styles from './sidebar-button.module.scss'
import SpaceIcon from 'components/spaces/SpaceIcon'
import useStore from 'state/knovStore'
import WithTooltip from 'components/shared/WithTooltip'

export default function SidebarButton(props) {
    const toggleSidebar = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (props.toggleSidebar) props.toggleSidebar()
    }

    const activeSpaceId = useStore(state => state.activeSpaceId)
    const userSpaces = useStore(state => state.userSpaces)
    const spaceNotiCounts = useStore(state => state.spaceNotiCounts) || {}
    const unseenNotiCount = Object.keys(spaceNotiCounts)
        .filter(spaceId => spaceId !== activeSpaceId?.toString())
        .reduce((acc, spaceId) => acc + spaceNotiCounts[spaceId], 0)

    const knovigatorSpace = useStore(state => state.knovigatorSpace)
    const space = userSpaces?.find(space => space.id === activeSpaceId) || knovigatorSpace

    return (
        <div
            className={cn(
                styles.sidebarButtonComp,
                props.showSidebar && styles.showSidebar,
                props.customClass,
            )}
            onClick={toggleSidebar}
        >
            {props.showSidebar ? (
                <i className="fa fa-left-arrow" />
            ) : (
                space && (
                    <>
                        {unseenNotiCount > 0 && (
                            <div className={styles.unseenNotiContainer}>
                                <UnseenNoti />
                            </div>
                        )}

                        <WithTooltip tip="Toggle sidebar">
                            <SpaceIcon space={space} contextStyles={styles.spaceIcon} />
                        </WithTooltip>
                    </>
                )
            )}
        </div>
    )
}

function UnseenNoti(props) {
    return <div className={styles.unseenNotiComp}></div>
}
