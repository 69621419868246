import React from 'react'

export default function Member(props) {
    const { content, shouldHandleDelete } = props

    return (
        <div key={content} className={'user-tag is-info'}>
            {content}
            {shouldHandleDelete && (
                <div className="small-delete">
                    <i
                        className="fa fa-times-circle"
                        style={{ marginLeft: '5px', cursor: 'pointer' }}
                        onClick={props.handleDelete}
                    />
                </div>
            )}
        </div>
    )
}
