import React from 'react'
import cn from 'classnames'
import styles from 'components/shared/icons/public-icon.module.scss'
import useStore from 'state/knovStore'
import OnchainLogo from 'public/onchain.png'

export default function PublicIcon(props) {
    // This is the id... should be renamed. Render when changes.
    useStore(state => state.activeSpaceId)
    const activeSpace = useStore.getState().getActiveSpace() || useStore.getState().knovigatorSpace

    return (
        <div className={cn(styles.publicIcon, props.contextStyles)}>
            <img src={OnchainLogo} />
        </div>
    )
}
