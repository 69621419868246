import React from 'react'
import NewQuestButton from 'components/quests/NewQuestButton'
import NewQuestButtonUIMain from 'components/quests/NewQuestButtonUIMain'
import StreamControl from 'components/panels/StreamControl'
import {
    NotificationStream,
    SearchStream,
    PeopleStream,
    StarredStream,
    HistoryStream,
} from 'components/filters/FilterIcon'
import cn from 'classnames'
import useStore from 'state/knovStore'
import styles from 'components/panels/control.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'
import { getCenterPanel } from 'state/imperativeApis/swiperApi'

export default function Control({ showFilterOptions, setShowFilterOptions }) {
    useStore(state => state.activeIndex)
    const panel = getCenterPanel()
    const filter = panel?.filter
    const numNoti = useStore(state => state.numNoti)

    return (
        <div className={styles.controlComp}>
            <div className={cn(styles.lowerLevelContainer, isMobile && styles.mobileControl)}>
                <div className={styles.controlGroup}>
                    <div className={styles.notiStreamContainer}>
                        <NotificationStream
                            filter={filter}
                            panel={panel}
                            showNumNoti
                            numNoti={numNoti}
                            contextStyles={cn(styles.notiControlIcon)}
                            canActive
                            activeStyles={styles.notiActive}
                            showIcon
                            tip="Notifications"
                            place="top"
                            append
                        />
                    </div>
                </div>

                <div className={styles.peopleControlContainer}>
                    <div className={styles.peopleControl}>
                        <WithTooltip tip="People" place="top">
                            <PeopleStream
                                filter={filter}
                                panel={panel}
                                contextStyles={cn(styles.peopleControl)}
                            />
                        </WithTooltip>
                    </div>
                </div>

                <div className={styles.starControlContainer}>
                    <div className={styles.starControl}>
                        <WithTooltip tip="Starred" place="top">
                            <StarredStream
                                filter={filter}
                                panel={panel}
                                contextStyles={cn(styles.starControl)}
                            />
                        </WithTooltip>
                    </div>
                </div>

                <div className={styles.controlGroup}>
                    <div className={styles.streamControlContainer}>
                        <WithTooltip tip="Choose Stream" place="top">
                            <StreamControl
                                panel={panel}
                                filter={filter}
                                showFilterOptions={showFilterOptions}
                                setShowFilterOptions={setShowFilterOptions}
                                contextStyles={cn(styles.streamControl)}
                            />
                        </WithTooltip>
                    </div>
                </div>

                <div className={styles.historyControlContainer}>
                    <div className={styles.historyControl}>
                        <WithTooltip tip="History" place="top">
                            <HistoryStream
                                filter={filter}
                                panel={panel}
                                contextStyles={cn(styles.historyControl)}
                            />
                        </WithTooltip>
                    </div>
                </div>

                <div className={styles.searchStreamContainer}>
                    <SearchStream
                        filter={filter}
                        panel={panel}
                        contextStyles={cn(styles.searchIcon)}
                        tip="Search"
                        place="top"
                        append
                    />
                </div>

                <div className={styles.newQuestContainer}>
                    <NewQuestButton panel={panel} filter={filter} place="top" append={true}>
                        <NewQuestButtonUIMain
                            label="New"
                            showIcon
                            //showLabel
                            contextStyles={styles.newQuestButton}
                            filter={filter}
                            user={gon.currentUser}
                        />
                    </NewQuestButton>
                </div>
            </div>
        </div>
    )
}
