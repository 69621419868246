import React, { useEffect, useState } from 'react'
import Member from 'components/teams/Member'
import useStore from 'state/knovStore'
import CreatableSelect from 'react-select/creatable'
import api from '../../api/api'
import cn from 'classnames'
import styles from 'components/teams/team-settings.module.scss'
import WithTooltip from 'components/shared/WithTooltip'
import ReactTooltip from 'react-tooltip'
import { isMobile } from 'react-device-detect'
import { getTooltipRef } from 'state/imperativeApis/tooltipApi'
import TeamLogo from 'components/shared/icons/TeamLogo'
import SettingsHeader from 'components/shared/settings/SettingsHeader'
import Button from 'components/shared/buttons/Button'
import { PurpleButton } from 'components/shared/buttons/Button'

const emailRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/

export default function Team(props) {
    const [team, setTeam] = useState(props.team)
    useEffect(function getTeam() {
        if (!props.team && props.teamId) {
            api.getTeam(props.teamId).then(team => {
                setTeam(team)
                setTeamName(team.name)
            })
        }
    }, [])

    const [selectedOptions, setSelectedOptions] = useState([])
    const [teamName, setTeamName] = useState(props.team?.name)

    const [users, setUsers] = useState(null)
    useEffect(
        function getUsers() {
            if (team)
                api.getUsers({ space_id: team.space_id }).then(users => {
                    setUsers(users)
                })
        },
        [team],
    )

    const tooltipRef = getTooltipRef()

    const openStreamImageUpdateModal = useStore(
        state => state.actions.modalActions.openStreamImageUpdateModal,
    )
    const handleChange = selectedOption => {
        setSelectedOptions(selectedOption)
    }

    const filterOption = (option, inputValue) => {
        const { value } = option
        return value.toLowerCase().startsWith(inputValue.toLowerCase())
    }

    const handleSubmit = e => {
        e.preventDefault()
        setSelectedOptions(null)
        api.addToTeam(
            {
                emails: selectedOptions.filter(o => o?.__isNew__).map(o => o?.value),
                ids: selectedOptions
                    .filter(o => !o?.__isNew__)
                    .map(a => users.find(u => u?.name === a?.value)?.id),
            },
            team.id,
        ).then(data => {
            setTeam(data.team)
        })
    }

    const handleDeleteMember = async userId => {
        const apiTeam = await api.removeFromTeam(team.id, userId)
        setTeam(apiTeam)
    }

    const handleDeleteInvite = async email => {
        const apiTeam = await api.deleteInvite(email, team.id)
        if (apiTeam) {
            setTeam(apiTeam)
        }
    }

    const handleNotiOption = async period => {
        const apiTeam = await api.updateTeam(team.id, { notify_period: period })
        if (apiTeam) {
            setTeam(apiTeam)
        }
    }

    const handlePublicOption = async isPublic => {
        const apiTeam = await api.updateTeam(team.id, { public: isPublic })
        if (apiTeam) {
            setTeam(apiTeam)
        }
    }

    const memberListComponent = team?.users?.map(user => {
        return (
            <Member
                key={user.id}
                shouldHandleDelete={gon.currentUser.id == team.admin_id && user.id != team.admin_id}
                handleDelete={() => handleDeleteMember(user.id)}
                content={`@${user.name}`}
            />
        )
    })

    const invitationListComponent = team?.invitations?.map(user => {
        return (
            <Member
                key={user.id}
                content={user.email}
                shouldHandleDelete={true}
                handleDelete={() => handleDeleteInvite(user.email)}
            />
        )
    })

    const instantAppStyles = team?.notify_period === 'instant_app' ? styles.chosenNoti : null
    const instantEmailStyles = team?.notify_period === 'instant_email' ? styles.chosenNoti : null
    const oneDayStyles =
        team?.notify_period === 'one_day_email' || team?.notify_period === null
            ? styles.chosenNoti
            : null
    const threeDayStyles = team?.notify_period === 'three_day_email' ? styles.chosenNoti : null
    const oneWeekStyles = team?.notify_period === 'one_week_email' ? styles.chosenNoti : null
    const showSaveBtn = teamName !== team?.name

    return (
        <div className={styles.teamSettingsContainer}>
            <div
                className={cn(
                    'team-comp',
                    styles.teamSettingsComp,
                    isMobile && styles.mobileTeamSettingsComp,
                )}
                style={{ position: 'relative' }}
            >
                <div className={styles.backButtonContainer}>
                    <Button
                        type="default"
                        icon="fa fa-arrow-left"
                        text="Back to Treechat"
                        onClick={() => (window.location.href = '/')}
                    />
                </div>

                <div className={styles.settingsHeaderContainer}>
                    {team && (
                        <SettingsHeader
                            header="Stream Settings"
                            name={team?.name}
                            IconComponent={
                                team ? (
                                    <TeamLogo
                                        team={team}
                                        contextStyles={styles.bigLogo}
                                        initialStyles={cn(styles.initialStyles, styles.bigInitials)}
                                    />
                                ) : null
                            }
                            openImageUpdateModal={() =>
                                openStreamImageUpdateModal(team, newTeam => setTeam(newTeam))
                            }
                            handleSave={async name => {
                                const apiTeam = await api.updateTeam(team.id, { name })
                                setTeam(apiTeam)
                            }}
                        />
                    )}
                </div>

                <div className={styles.notiOptionsContainer}>
                    <div className={styles.notiOptionsComp}>
                        <div className={styles.notiLabel}>Choose Access:</div>

                        <div className={styles.notiOptionsContent}>
                            <WithTooltip tip="Only invited members can access this stream.">
                                <div
                                    className={cn(
                                        styles.notiOption,
                                        !team?.public && styles.chosenNoti,
                                    )}
                                    onClick={ev => handlePublicOption(false)}
                                >
                                    <span>
                                        <i className="fa fa-lock"></i>
                                    </span>{' '}
                                    Private
                                </div>
                            </WithTooltip>

                            <WithTooltip tip="Anyone can access this stream.">
                                <div
                                    className={cn(
                                        styles.notiOption,
                                        team?.public && styles.chosenNoti,
                                    )}
                                    onClick={ev => handlePublicOption(true)}
                                >
                                    <span>
                                        <i className="fa fa-globe"></i>
                                    </span>{' '}
                                    Public
                                </div>
                            </WithTooltip>
                        </div>
                    </div>
                </div>

                <div className={styles.notiOptionsComp}>
                    <div className={styles.notiLabel}>Choose notification frequency:</div>

                    <div className={styles.notiOptionsContent}>
                        <WithTooltip tip="Instantly notify stream members of new activity inside the application">
                            <div
                                className={cn(styles.notiOption, instantAppStyles)}
                                onClick={ev => handleNotiOption('instant_app')}
                            >
                                <span>
                                    <i className="fa fa-bell-o"></i>
                                </span>{' '}
                                Instant
                            </div>
                        </WithTooltip>

                        {/*
                        <WithTooltip tip='Instantly notify stream members of new activity via email'>
                            <div className={cn(styles.notiOption, instantEmailStyles)} onClick={ev => handleNotiOption('instant_email')}>
                                <span><i className='fa fa-envelope-o'></i></span> Instant Email
                            </div>
                        </WithTooltip>
                        */}

                        <WithTooltip tip="Summarize stream activity daily for members">
                            <div
                                className={cn(styles.notiOption, oneDayStyles)}
                                onClick={ev => handleNotiOption('one_day_email')}
                            >
                                <span>
                                    <i className="fa fa-envelope-o"></i>
                                </span>{' '}
                                per day
                            </div>
                        </WithTooltip>

                        <WithTooltip tip="Summarize stream activity every three days via email">
                            <div
                                className={cn(styles.notiOption, threeDayStyles)}
                                onClick={ev => handleNotiOption('three_day_email')}
                            >
                                <span>
                                    <i className="fa fa-envelope-o"></i>
                                </span>{' '}
                                per 3 days
                            </div>
                        </WithTooltip>

                        <WithTooltip tip="Summarize stream activity once per week via email">
                            <div
                                className={cn(styles.notiOption, oneWeekStyles)}
                                onClick={ev => handleNotiOption('one_week_email')}
                            >
                                <span>
                                    <i className="fa fa-envelope-o"></i>
                                </span>{' '}
                                per week
                            </div>
                        </WithTooltip>
                    </div>
                </div>

                <h5>Invite new team member (type email or username then tab):</h5>
                <div>
                    <form className="invite-form" onSubmit={handleSubmit}>
                        <CreatableSelect
                            isMulti
                            value={selectedOptions}
                            onChange={handleChange}
                            placeholder={
                                users
                                    ? 'Enter a username or email address and hit tab...'
                                    : 'Loading...'
                            }
                            filterOption={filterOption}
                            formatCreateLabel={v =>
                                emailRegex.test(v)
                                    ? `Invite "${v}" by email`
                                    : 'Please enter a valid Knovigator username or email address'
                            }
                            options={users
                                ?.filter(u => !team?.users?.map(tu => tu?.id).includes(u?.id))
                                .filter(u => u?.id != gon?.currentUser?.id)
                                .map(u => ({ value: u?.name, label: u?.name }))}
                        />

                        <div className={styles.inviteBtnContainer}>
                            <button
                                type="submit"
                                style={{ border: 'none', background: 'none', padding: 0 }}
                            >
                                <PurpleButton text="Invite" />
                            </button>
                        </div>
                    </form>
                </div>

                <h5>Outstanding email invites: {team?.invitations?.length > 0 ? '' : 'None'}</h5>
                <div className="user-tag-container">{invitationListComponent}</div>

                <h5>Team members:</h5>
                <div className="user-tag-container">{memberListComponent}</div>

                <ReactTooltip
                    ref={tooltip => (tooltipRef.current = tooltip)}
                    effect="solid"
                    place="bottom"
                    delayShow={1000}
                    arrowColor="rgba(0,0,0,0)"
                    globalEventOff={isMobile ? 'touchstart' : undefined}
                />
            </div>
        </div>
    )
}
