import React from 'react'
import WithTooltip from 'components/shared/WithTooltip'
import styles from 'components/answers/answer-controls.module.scss'
import cn from 'classnames'
import { isMobile } from 'react-device-detect'

export default React.forwardRef((props, ref) => {
    const answerHoverStyles = props.answerHover ? styles.hoverBtn : null
    const mobileStyles = isMobile ? styles.mobileBtn : null

    return (
        <div
            className={cn(
                styles.editMediaBtn,
                answerHoverStyles,
                styles.imgHover,
                styles.addImgBtn,
                mobileStyles,
            )}
            onClick={ev => ev.stopPropagation()}
        >
            <label htmlFor={`answer-img-file-input-${props.btnId}`}>
                <WithTooltip tip="Take photo.">
                    <div className="answer-btn">
                        <i id={`answer-image-btn`} className="fa fa-camera image-upload-control" />
                    </div>
                </WithTooltip>

                <input
                    style={{
                        height: 0,
                        top: 30000,
                        left: 30000,
                        position: 'absolute',
                        display: 'none',
                        width: 0,
                    }}
                    id={`answer-img-file-input-${props.btnId}`}
                    ref={ref}
                    className="answer-img"
                    name="answer_image"
                    type="file"
                    accept="image/*"
                    capture="camera"
                    data-answer-id={props.answer?.id}
                    onChange={props.imgHandler}
                />
            </label>
        </div>
    )
})
