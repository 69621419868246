import React, { useState } from 'react'
import useStore from 'state/knovStore'
import api from 'api/api'
import styles from 'components/quests/star-quest-button.module.scss'
import cn from 'classnames'

export default function StarQuestButton({ quest, contextStyles }) {
    const [isHovering, setIsHovering] = useState(false)
    const isStarred = useStore(state => state.starredQuestIds[quest?.id])

    function starHandler(e) {
        e.preventDefault()
        e.stopPropagation()

        if (!isStarred) {
            useStore.getState().set(draft => {
                draft.starredQuestIds[quest.id] = true
            })
            api.starQuest(quest.id)
        } else {
            useStore.getState().set(draft => {
                delete draft.starredQuestIds[quest.id]
            })
            api.unstarQuest(quest.id)
        }
    }

    return (
        <div
            className={cn(styles.starQuestButton, contextStyles)}
            onClick={starHandler}
            onMouseEnter={() => setIsHovering(true)}
            onMouseLeave={() => setIsHovering(false)}
        >
            <i
                className={cn('fa', {
                    'fa-star': isStarred || isHovering,
                    'fa-star-o': !isStarred && !isHovering,
                })}
            ></i>
        </div>
    )
}
