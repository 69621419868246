import React, { useEffect } from 'react'
import cn from 'classnames'
import styles from '../lock-vote.module.scss'
import { SignalType } from 'types/signals'
import { UpvalueTab } from '../tabs/UpvalueTab'
import { LockTab } from '../tabs/LockTab'
import { SettingsTab } from '../tabs/SettingsTab'
import { BoostTab } from '../tabs/BoostTab'
import { isMobile } from 'react-device-detect'
import useStore from 'state/knovStore'
import { SATS_BEFORE_CONFIRM, toBitcoin } from 'lib/bsv-util'

interface SignalOptionsModalProps {
    popupRef: React.RefObject<HTMLDivElement>
    onClose: () => void
    showBoost: boolean
    activeTab: 'upvalue' | 'boost' | 'lock' | 'settings'
    setActiveTab: (tab: 'upvalue' | 'boost' | 'lock' | 'settings') => void
    customUpvalueSats: number | null
    customLockSats: number | null
    customBlocks: number
    setCustomUpvalueSats: (sats: number | null) => void
    setCustomLockSats: (sats: number | null) => void
    setCustomBlocks: (blocks: number) => void
    useSats: boolean
    quickLockRef: React.MutableRefObject<any>
    lockBlocks: number
    handleLockOrUpvalue: (sats: number, blocks?: number, type?: SignalType) => void
    animateLock: (ref: any, scale?: number) => void
    answer: { user: { name: string } }
}

export function SignalOptionsModal({
    popupRef,
    onClose,
    showBoost,
    activeTab,
    setActiveTab,
    customUpvalueSats,
    customLockSats,
    customBlocks,
    setCustomUpvalueSats,
    setCustomLockSats,
    setCustomBlocks,
    useSats,
    quickLockRef,
    lockBlocks,
    handleLockOrUpvalue,
    animateLock,
    answer,
}: SignalOptionsModalProps) {
    useEffect(() => {
        if (!popupRef.current) return

        const updatePosition = () => {
            const popup = popupRef.current
            if (!popup) return

            const rect = popup.getBoundingClientRect()
            const viewportHeight = window.innerHeight

            // If popup extends beyond viewport bottom
            if (rect.bottom > viewportHeight) {
                const overflowAmount = rect.bottom - viewportHeight
                popup.style.transform = `translateY(-${overflowAmount + 70}px)`
            } else {
                popup.style.transform = 'none'
            }
        }

        updatePosition()
        window.addEventListener('resize', updatePosition)

        return () => {
            window.removeEventListener('resize', updatePosition)
        }
    }, [])

    return (
        <div
            ref={popupRef}
            className={cn(styles.signalOptionsPopup, isMobile && styles.mobileSignalOptionsPopup)}
            onClick={e => e.stopPropagation()}
        >
            <div className={styles.popupMainHeader}>
                Signal Options
                <div className={styles.popupCloseButton} onClick={onClose}>
                    <i className="fa fa-times" />
                </div>
            </div>

            <div className={styles.toggleTabs}>
                <div
                    className={cn(
                        styles.toggleTab,
                        showBoost ? styles.boostTab : styles.upvalueTab,
                        (activeTab === 'upvalue' || activeTab === 'boost') &&
                            (showBoost ? styles.activeBoostTab : styles.activeUpvalueTab),
                    )}
                    onClick={() => setActiveTab(showBoost ? 'boost' : 'upvalue')}
                >
                    <i className="fa fa-arrow-up" />
                    <span className={styles.toggleTabLabel}>UPVALUE</span>
                </div>
                <div
                    className={cn(
                        styles.toggleTab,
                        styles.lockTab,
                        activeTab === 'lock' && styles.activeLockTab,
                    )}
                    onClick={() => setActiveTab('lock')}
                >
                    <i className="fa fa-lock" />
                    <span className={styles.toggleTabLabel}>LOCK</span>
                </div>
                <div
                    className={cn(
                        styles.toggleTab,
                        styles.settingsTab,
                        activeTab === 'settings' && styles.activeSettingsTab,
                    )}
                    onClick={() => setActiveTab('settings')}
                >
                    <i className="fa fa-cog" />
                    <span className={styles.toggleTabLabel}>SETTINGS</span>
                </div>
            </div>

            {activeTab === 'boost' && (
                <BoostTab
                    description="Repost message and reward Treechat."
                    customSats={customUpvalueSats}
                    setCustomSats={setCustomUpvalueSats}
                    useSats={useSats}
                    quickLockRef={quickLockRef}
                    handleLockOrUpvalue={(sats, blocks) =>
                        handleLockOrUpvalue(sats, blocks, 'boost')
                    }
                    animateLock={animateLock}
                />
            )}

            {activeTab === 'upvalue' && (
                <UpvalueTab
                    description={`Repost message and reward ${answer.user.name}.`}
                    customSats={customUpvalueSats}
                    setCustomSats={setCustomUpvalueSats}
                    useSats={useSats}
                    quickLockRef={quickLockRef}
                    handleLockOrUpvalue={(sats, blocks) => {
                        if (sats > SATS_BEFORE_CONFIRM) {
                            useStore
                                .getState()
                                .actions.modalActions.openConfirmModal(
                                    `Upvalue ${toBitcoin(sats)} bitcoin?`,
                                    'Upvalue',
                                    () => handleLockOrUpvalue(sats, blocks, 'upvalue'),
                                )
                        } else handleLockOrUpvalue(sats, blocks, 'upvalue')
                    }}
                    animateLock={animateLock}
                />
            )}

            {activeTab === 'lock' && (
                <LockTab
                    description="Repost message and lock coins."
                    customSats={customLockSats}
                    customBlocks={customBlocks}
                    setCustomSats={setCustomLockSats}
                    setCustomBlocks={setCustomBlocks}
                    useSats={useSats}
                    quickLockRef={quickLockRef}
                    lockBlocks={lockBlocks}
                    handleLockOrUpvalue={(sats, blocks) =>
                        handleLockOrUpvalue(sats, blocks, 'lock')
                    }
                    animateLock={animateLock}
                />
            )}

            {activeTab === 'settings' && <SettingsTab useSats={useSats} />}
        </div>
    )
}
