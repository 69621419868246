import React, { useEffect, useState, useContext } from 'react'
import { WithHeader, WithSeeMore } from '../ReactInstaStories'
import { isMobile } from 'react-device-detect'
import ProgressCtx from '../contexts/Progress'

import useStore from '../../../state/knovStore'

export const renderer = ({ story, action, isPaused, config, messageHandler }) => {
    const [loaded, setLoaded] = useState(false)
    const [controls, setControls] = useState(false)
    const { width, height, loader, storyStyles } = config
    const closeStoryModal = useStore(state => state.actions.modalActions.closeStoryModal)
    const { pause, setCount, videoDuration } = useContext(ProgressCtx)

    const isLastStory = document.querySelectorAll('div[style*="width: 0px;"]').length === 0

    // const { currentId, next, videoDuration, pause } = useContext(ProgressCtx)
    let computedStyles = Object.assign(
        Object.assign({}, styles.storyContent, {
            visibility: loaded ? 'visible' : 'hidden',
        }),
        storyStyles || {},
    )
    let vid = React.useRef(null)
    let fakeVid = React.useRef(null)
    const storyControls = document.querySelector('.story-control')

    useEffect(() => {
        const duration =
            (fakeVid.current && fakeVid.current.durration) ||
            (vid.current && vid.current.durration) ||
            videoDuration / 1000

        if (loaded && duration && duration == duration && duration != Infinity) {
            setCount((vid.current.currentTime / duration) * 100)
        }
        if (vid.current && loaded) {
            if (pause) {
                vid.current.pause()
            } else
                vid.current.play().catch(e => {
                    if (e.name == 'NotAllowedError') {
                        // setMuted(true);
                        if (!controls) {
                            setControls(true)
                        }
                        action('pause')
                    } else {
                        if (vid.current) vid.current.play()
                    }
                })
        }
    }, [!pause && loaded, controls])

    useEffect(() => {
        action('pause')
        let controlWidth
        if (isMobile) {
            controlWidth = '66px'
            if (storyControls) {
                storyControls.style.width = controlWidth
                storyControls.style.marginBottom = '100px'
            }
        } else {
            controlWidth = `20%`
            if (storyControls) {
                storyControls.style.width = controlWidth
            }
        }
        messageHandler('UPDATE_VIDEO_DURATION', { duration: Infinity })
    }, [])

    useEffect(() => {
        if (
            fakeVid.current &&
            fakeVid.current.duration != Infinity &&
            fakeVid.current.duration == fakeVid.current.duration &&
            !loaded
        ) {
            updateVideoDuration()
        }
    }, [fakeVid.current && fakeVid.current.duration])

    const updateVideoDuration = () => {
        messageHandler('UPDATE_VIDEO_DURATION', { duration: fakeVid.current.duration })
        setLoaded(true)
        fakeVid.current && fakeVid.current.remove()
        fakeVid.current = undefined
        action('play')
        vid.current.onCanPlay = () => {
            action('play')
        }

        // for some reason for the controls to activate the video needs to play?
    }

    const pauseVideo = () => {
        if (vid.current && !vid.current.paused) action('pause')
    }

    const onCanPlay = e => {
        if (fakeVid.current && fakeVid.current.duration === Infinity) {
            fakeVid.current.currentTime = 7 * 24 * 60 * 1000
            fakeVid.current.onseeked = () => {
                fakeVid.current.onseeked = undefined
                onCanPlay()
            }
        } else if (!loaded) {
            vid.current.oncanplay = () => action('play')
            vid.current.currentTime = 0
            updateVideoDuration()
        }
    }

    return (
        <WithHeader story={story} globalHeader={config.header}>
            <WithSeeMore story={action}>
                <div style={styles.videoContainer}>
                    <video
                        ref={vid}
                        style={computedStyles}
                        playsInline={true}
                        preload="metadata"
                        onWaiting={() => {
                            if (loaded && vid.current.currentTime > 0.1) pauseVideo()
                        }}
                        controls={controls}
                        className={controls ? 'with-controls' : ''}
                        onPlay={() => {
                            if (controls) action('play')
                        }}
                        onPause={() => {
                            if (controls) action('pause')
                        }}
                    >
                        {story.mp4_recording_url && (
                            <source src={story.mp4_recording_url + '#0.1'} type="video/mp4" />
                        )}
                        <source src={story.recording_url + '#0.1'} type="video/webm" />
                    </video>

                    <video
                        playsInline
                        ref={fakeVid}
                        preload="metadata"
                        muted
                        style={{ display: 'none' }}
                        onLoadedMetadata={onCanPlay}
                    >
                        {story.mp4_recording_url && (
                            <source src={story.mp4_recording_url + '#0.1'} type="video/mp4" />
                        )}
                        <source src={story.recording_url + '#0.1'} type="video/webm" />
                    </video>

                    {!loaded && (
                        <div
                            style={{
                                position: 'absolute',
                                width: '100%',
                                height: '100%',
                                left: 0,
                                top: 0,
                                background: 'rgb(238, 238, 238)',
                                zIndex: 9,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#ccc',
                            }}
                        />
                    )}
                </div>
            </WithSeeMore>
        </WithHeader>
    )
}
const styles = {
    storyContent: {
        height: '100%',
        maxWidth: '100%',
    },
    videoContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: '100%',
        background: 'rgb(238, 238, 238)',
    },
}
export const tester = story => {
    return {
        condition: story.type === 'video',
        priority: 3,
    }
}
export default { renderer, tester }
