import React, { useEffect, useRef, useLayoutEffect } from 'react'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import useStore from 'state/knovStore'
import useQuestChannel from 'state/channels/useQuestChannel'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useGetQuest from 'refactor/hooks/api/useGetQuest'
import {
    isPanelLeft,
    isPanelCenter,
    isPanelRight,
    getSwiperRef,
} from 'state/imperativeApis/swiperApi'
import Quest from 'components/quests/Quest'
import styles from 'components/panels/panel.module.scss'
import api from 'api/api'
import queryClient from 'api/queryClient'
import PrivateQuest from 'components/quests/PrivateQuest'

interface QuestContainerProps {
    filter: {
        questId: string
        answerId?: string
    }
}

export default function QuestContainer({ filter }: QuestContainerProps) {
    const { panel, scrollContainerRef, animationPanel } = usePanelContext()
    const panelId = panel?.panelId
    const questId = filter?.questId
    const { quest, isFetching } = useGetQuest(questId)

    useQuestChannel(questId)

    const newAnswerRef = useRef(null)

    useEffect(function onMount() {
        ;(async () => {
            const isOnScreen = isMobile
                ? isPanelCenter(panelId)
                : isPanelLeft(panelId) || isPanelCenter(panelId) || isPanelRight(panelId)
            if (!animationPanel && isOnScreen && !quest?.is_draft) {
                await api.userViewsQuest(filter.questId)
                const actionsQueryKey = ['actions']
                queryClient.invalidateQueries({ queryKey: actionsQueryKey })
                queryClient.refetchQueries({ queryKey: actionsQueryKey })

                // Break React Query cache for this quest ID
                queryClient.refetchQueries({ queryKey: ['quest', questId] })
            }
        })()
        logEv('QUEST VIEW', { questId })
    }, [])

    const ANSWER_HEIGHT = isMobile ? 140 : 125
    const SCROLL_OFFSET = 1500

    useEffect(
        function initScroll() {
            if (
                !animationPanel &&
                quest?.sorted_answers &&
                useStore.getState().panels.lastAction?.insert?.panelId === panelId
            ) {
                const answerId = panel?.filter?.answerId

                if (isMobile && answerId) {
                    const swiper = getSwiperRef()?.current
                    swiper?.on('slideChangeTransitionEnd', function highlightAnswer() {
                        const scrollContainer = scrollContainerRef.current
                        const answerElement = answerId
                            ? scrollContainer.querySelector(`.answer-in-view-${answerId}`)
                            : null

                        const answerIndex = quest.sorted_answers.findIndex(
                            answer => answer.id === answerId,
                        )
                        const scrollHeight = answerIndex * ANSWER_HEIGHT

                        requestAnimationFrame(() => {
                            scrollContainer.scrollTop = Math.max(0, scrollHeight - SCROLL_OFFSET)

                            answerElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
                        })

                        swiper?.off('slideChangeTransitionEnd', highlightAnswer)
                    })
                } else {
                    const scrollContainer = scrollContainerRef.current
                    const answerElement = answerId
                        ? scrollContainer.querySelector(`.answer-in-view-${answerId}`)
                        : null

                    if (answerElement) {
                        const answerIndex = quest.sorted_answers.findIndex(
                            answer => answer.id === answerId,
                        )
                        const scrollHeight = answerIndex * ANSWER_HEIGHT
                        scrollContainer.scrollTop = Math.max(0, scrollHeight - SCROLL_OFFSET)

                        requestAnimationFrame(() => {
                            answerElement.scrollIntoView({ behavior: 'smooth', block: 'center' })
                        })
                    } else {
                        if (isMobile) {
                            return
                            const scrollHeight = scrollContainer.scrollHeight
                            scrollContainer.scrollTop = Math.max(0, scrollHeight - SCROLL_OFFSET)
                            const swiper = getSwiperRef()?.current
                            swiper?.on('slideChangeTransitionEnd', function focusNewAnswer() {
                                console.log('>>>>>focusNewAnswer', panelId)
                                requestAnimationFrame(() => {
                                    scrollContainer.scrollTo({
                                        top: scrollHeight,
                                        behavior: 'smooth',
                                    })
                                    setTimeout(() => {
                                        newAnswerRef.current?.focusEditor()
                                    }, 500)
                                })

                                swiper?.off('slideChangeTransitionEnd', focusNewAnswer)
                            })
                        } else {
                            return
                            const scrollHeight = scrollContainer.scrollHeight
                            scrollContainer.scrollTop = Math.max(0, scrollHeight - SCROLL_OFFSET)
                            requestAnimationFrame(() => {
                                scrollContainer.scrollTo({ top: scrollHeight, behavior: 'smooth' })
                                setTimeout(() => {
                                    newAnswerRef.current?.focusEditor()
                                }, 500)
                            })
                        }
                    }
                }
            }
        },
        [quest?.id],
    )

    if (quest?.id === '__PRIVATE__') {
        return <PrivateQuest />
    }

    return !quest && isFetching ? (
        <Loading />
    ) : (
        <Quest
            panelId={panelId}
            panel={panel}
            isActive={true}
            showAll={true}
            quest={quest}
            shouldPlay={window.location.href.endsWith('#play')}
            filter={filter}
            query={filter?.query}
            ix={0}
            newAnswerRef={newAnswerRef}
        />
    )
}

function Loading() {
    return (
        <div className={styles.loader}>
            <div className={cn(styles.loadingIcon)}>
                <i className="fa fa-circle-o-notch fa-spin fa-lg" />
            </div>
        </div>
    )
}

function logEv(event: string, data: any) {
    // Implement your logging logic here
    console.log(event, data)
}
