import React, { useContext, useState, useEffect, useRef } from 'react'
import Progress from './Progress'
import ProgressCtx from './contexts/Progress'
import GlobalContext from './contexts/Global'
import StoriesContext from './contexts/Stories'
var animationFrameId
export default () => {
    const { currentId, next, videoDuration, pause, count, setCount } = useContext(ProgressCtx)
    const { defaultInterval, onStoryEnd, onStoryStart, onAllStoriesEnd } = useContext(GlobalContext)
    const { stories } = useContext(StoriesContext)
    var interval
    let countCopy = count

    if (pause) {
        cancelAnimationFrame(animationFrameId)
    }

    var lastTime

    useEffect(() => {
        setCount(0)
        lastTime = null
    }, [currentId, stories])

    useEffect(() => {
        if (!pause) {
            animationFrameId = requestAnimationFrame(incrementCount)
        } else {
            cancelAnimationFrame(animationFrameId)
        }
        return () => {
            cancelAnimationFrame(animationFrameId)
        }
    }, [currentId, pause])

    const incrementCount = () => {
        if (pause) return false

        if (countCopy === 0) storyStartCallback()
        setCount(count => {
            interval = getCurrentInterval()
            let now, delta
            if (performance) now = performance.now()
            else now = Date.now()
            if (now && lastTime) delta = 1000 / (now - lastTime)
            else delta = 60
            lastTime = now
            countCopy = count + 100 / ((interval / 1000) * delta)
            return count + 100 / ((interval / 1000) * delta)
        })
        if (countCopy < 100) {
            animationFrameId = requestAnimationFrame(incrementCount)
        } else {
            storyEndCallback()
            if (currentId === stories.length - 1) {
                allStoriesEndCallback()
            }
            cancelAnimationFrame(animationFrameId)
            next()
        }
    }
    const getCurrentInterval = () => {
        if (stories[currentId].type === 'video') return videoDuration
        if (typeof stories[currentId].duration === 'number') return stories[currentId].duration
        return defaultInterval
    }

    const storyStartCallback = () => {
        onStoryStart && onStoryStart(currentId, stories[currentId])
    }
    const storyEndCallback = () => {
        onStoryEnd && onStoryEnd(currentId, stories[currentId])
    }
    const allStoriesEndCallback = () => {
        onAllStoriesEnd && onAllStoriesEnd(currentId, stories)
    }

    const styles = {
        progressArr: {
            display: 'flex',
            justifyContent: 'center',
            maxWidth: '100%',
            flexWrap: 'row',
            position: 'absolute',
            width: '98%',
            padding: 5,
            paddingTop: 7,
            alignSelf: 'center',
            zIndex: 99,
            filter: 'drop-shadow(0 1px 8px #222)',
        },
    }

    return React.createElement(
        'div',
        { style: styles.progressArr },
        stories.map((_, i) =>
            React.createElement(Progress, {
                key: i,
                count: count,
                width: 1 / stories.length,
                active: i === currentId ? 1 : i < currentId ? 2 : 0,
            }),
        ),
    )
}
