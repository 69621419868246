import React from 'react'
import { isMobile } from 'react-device-detect'

// TODO - always use these functions to trigger focus and blur everywhere in the app so it is easy to debug and
//  track who is triggering them
export function focus(focusTarget) {
    if (isMobile) {
        return
    }
    if (
        typeof focusTarget.focus === 'function' ||
        typeof focusTarget.current?.focus === 'function'
    ) {
        const target = typeof focusTarget.focus === 'function' ? focusTarget : focusTarget.current
        target.focus()
    }
}

export function blur(blurTarget) {
    if (isMobile) {
        return
    }
    if (typeof blurTarget.blur === 'function' || typeof blurTarget.current?.blur === 'function') {
        const target = typeof blurTarget.blur === 'function' ? blurTarget : blurTarget.current
        target.blur()
    }
}
