import React, { useRef } from 'react'
import { useDrop } from 'react-dnd'
import { throttle } from 'lodash'
import { ItemTypes } from './ItemTypes'
import NewAnswer from './NewAnswer'

export default props => {
    const ref = useRef(null)
    const [, drop] = useDrop({
        accept: ItemTypes.ANSWER,
        drop(item, monitor) {
            if (
                !ref.current ||
                (!(!props.quest.default_sort_col || props.quest.default_sort_col !== 'vote') &&
                    (!props.quest.side_quest || item.answer.id !== props.quest.parent_id))
            ) {
                return { error: true }
            }
            // Don't replace items with themselves
            if (
                item.initial_quest_id !== props.quest.id &&
                gon.currentUser.id === props.quest.user_id &&
                gon.currentUser.id === item.quest_user_id &&
                (!props.quest.side_quest || item.answer.id !== props.quest.parent.id)
            ) {
                if (!props.quest.default_sort_col || props.quest.default_sort_col !== 'vote')
                    props.appendAnswerToQuest(item.name)
                item.delete_local(item.initial_ix)
            }
            item.quest_id = props.quest.id
        },
    })

    drop(ref)
    return (
        <div>
            <div ref={ref}>
                <NewAnswer ref={props.newAnswerRef} {...props} />
            </div>
        </div>
    )
}
