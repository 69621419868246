import React from 'react'
import cn from 'classnames'
import { BITCOIN_UNIT, SATOSHI_UNIT } from 'lib/bsv-util'
import useStore from 'state/knovStore'
import api from 'api/api'
import styles from './lock-vote.module.scss'

interface CurrencyTabsProps {
    useSats: boolean
}

export function CurrencyTabs({ useSats }: CurrencyTabsProps) {
    const handleUnitChange = (unit: typeof BITCOIN_UNIT | typeof SATOSHI_UNIT) => {
        useStore.setState({ bsvUnit: unit })
        api.updateUserSpaceOptions({ bsvUnit: unit })
    }

    return (
        <div className={styles.unitTabs}>
            <div
                className={cn(
                    styles.unitTab,
                    styles.satoshiUnit,
                    useSats && styles.activeSatoshiUnit,
                )}
                onClick={() => handleUnitChange(SATOSHI_UNIT)}
            >
                SATOSHIS
            </div>
            <div
                className={cn(
                    styles.unitTab,
                    styles.bitcoinUnit,
                    !useSats && styles.activeBitcoinUnit,
                )}
                onClick={() => handleUnitChange(BITCOIN_UNIT)}
            >
                BITCOINS
            </div>
        </div>
    )
}
