import React, { useState, useEffect } from 'react'
import { debugCopy } from 'utils'

export default function Debug(props) {
    const quest = props.quest
    const answer = props.answer
    const draftAnswer = props.draftAnswer
    const position = props.position
    const ix = props.ix
    const answerUpdatedAt = props.answerUpdatedAt

    const [flashingId, setFlashingId] = useState(null)

    useEffect(() => {
        if (flashingId) {
            const timer = setTimeout(() => {
                setFlashingId(null)
            }, 1000)
            return () => clearTimeout(timer)
        }
    }, [flashingId])

    const handleClick = id => {
        setFlashingId(id)
        debugCopy(id)
    }

    const flashingStyle = id => ({
        fontWeight: flashingId === id ? 'bold' : 'normal',
        animation: flashingId === id ? 'flash 0.5s 2' : 'none',
    })

    return (
        <div
            style={{
                background: '#eaf0ff',
                marginBottom: 15,
                marginLeft: 15,
                fontSize: 14,
            }}
        >
            <div
                style={{ paddingTop: 5 }}
                onClick={e => {
                    e.stopPropagation()
                    handleClick(quest?.id)
                }}
            >
                <strong style={{ marginLeft: 10 }}>Q: </strong>
                <span style={flashingStyle(quest?.id)}>{quest?.id}</span>
            </div>

            <div
                onClick={e => {
                    e.stopPropagation()
                    handleClick(answer?.id)
                }}
            >
                <strong style={{ marginLeft: 10 }}>A: </strong>
                <span style={flashingStyle(answer?.id)}>{answer?.id}</span>
            </div>

            {draftAnswer && (
                <div
                    onClick={e => {
                        e.stopPropagation()
                        handleClick(draftAnswer?.id)
                    }}
                >
                    <strong style={{ marginLeft: 10 }}>D: </strong>
                    <span style={flashingStyle(draftAnswer?.id)}>{draftAnswer?.id}</span>
                </div>
            )}

            {position && (
                <div>
                    <strong style={{ marginLeft: 10 }}>Pos:</strong> {position}
                </div>
            )}

            {!!ix ||
                (ix >= 0 && (
                    <div>
                        <strong style={{ marginLeft: 10 }}>Ix:</strong> {ix}
                    </div>
                ))}

            {answerUpdatedAt && (
                <div style={{ paddingBottom: 5 }}>
                    <strong style={{ marginLeft: 10 }}>Upd:</strong> {answerUpdatedAt}
                </div>
            )}
        </div>
    )
}
