import { useQuery } from '@tanstack/react-query'
import api from 'api/api'

export default function useBlockheight() {
    const result = useQuery({
        queryKey: ['blockheight'],
        queryFn: api.getBlockHeight,
        staleTime: 7.5 * 60 * 1000, // about half a block's time to keep it fresh-ish
        gcTime: Infinity,
        retry: 0,
        refetchOnMount: true, // refetch on mount if data is stale
        refetchOnWindowFocus: true, // refetch on focus if data is stale
        refetchOnReconnect: true, // refetch if connection is re-established
    })

    return parseInt(result.data?.blockheight, 10) || 0
}
