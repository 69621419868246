import usePanelContext from 'refactor/hooks/usePanelContext'
import useStore from 'state/knovStore'
//import usePrefetchQuest from "refactor/hooks/usePrefetchQuest";
import useSlideToPanel from 'refactor/hooks/useSlideToPanel'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import { isMobile } from 'react-device-detect'

export default function useInsertLeft(panelId) {
    const { panelId: nativePanelId } = usePanelContext()
    const anchorPanelId = panelId || nativePanelId

    const insertPanelLeft = useStore(store => store.panels.insertPanelLeft)
    const getIndexById = useStore(store => store.panels.getIndexById)
    //const prefetchQuest = usePrefetchQuest()
    const slideToPanel = useSlideToPanel()

    return function insertLeft(newPanel: Parameters<typeof insertPanelLeft>[1]) {
        if (newPanel.filter?.questId) {
            //prefetchQuest(newPanel.filter.questId)
        }
        const indexBeforeChange = getIndexById(anchorPanelId)
        const clickedLeftPanel = indexBeforeChange < getCenterIndex()
        // This gets in a race condition with insertion caused shift, which only happens when inserting left (bc of the way array indexing works). insertion caused shift runs an instant slideTo which clobbers this one.
        //if(clickedLeftPanel) {
        //    console.log('clicked left panel slide to panel')
        //    slideToPanel()
        //}
        setTimeout(() => {
            insertPanelLeft(anchorPanelId, newPanel, { animate: !isMobile && !clickedLeftPanel })
        })
    }
}
