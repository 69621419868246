import React, { useState, useEffect, useRef } from 'react'
import ReactQuill, { Quill } from 'react-quill-new'
import api from 'api/api'
import useStore from 'state/knovStore'
import { map, uniq, debounce, defer, partial, compact, cloneDeep } from 'lodash'
import { KnovClipboard, dataUrlToBlob } from 'components/shared/quill/quillHelpers'
import { extractUrls } from 'components/shared/LinkPreview'
import { updatePopupProps, getPopupProps } from 'components/AutocompletePopup'
import { isEqual } from 'lodash'
import questModel from 'components/quests/questModel'

// import 'quill-mention'
// import 'quill-mention/dist/quill.mention.css'
import AutoLinks from '../../lib/quill/auto_links'
import fuzzysort from 'fuzzysort'
import { isMobile } from 'react-device-detect'
import cn from 'classnames'
import { isEmpty } from 'lib/value'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import { getCenterIndex } from 'state/imperativeApis/swiperApi'
import useStreamFilters from 'components/filters/useStreamFilters'

Quill.register('modules/autoLinks', AutoLinks)
Quill.register('modules/clipboard', KnovClipboard)

const Embed = Quill.import('blots/embed')

class CustomMentionBlot extends Embed {
    static create(data) {
        const node = super.create()
        node.addEventListener(
            'click',
            e => {
                e.preventDefault()
                e.stopPropagation()
                // dispatch a custom event with mention data to the autocomplete popup
                // since we aren't in react context and thus can't access the zustand store
                // or streamFilter hook here
                const event = new Event('mention-clicked', { bubbles: true, cancelable: true })
                event.value = data
                event.event = e
                window.dispatchEvent(event)
            },
            false,
        )
        const denotationChar = document.createElement('span')
        denotationChar.className = 'ql-mention-denotation-char'
        denotationChar.innerHTML = data.denotationChar
        node.appendChild(denotationChar)
        node.innerHTML += data.value
        return CustomMentionBlot.setDataValues(node, data)
    }

    static setDataValues(element, data) {
        const domNode = element
        Object.keys(data).forEach(key => {
            domNode.dataset[key] = data[key]
        })
        return domNode
    }

    static value(domNode) {
        return domNode.dataset
    }
}

CustomMentionBlot.blotName = 'mention'
CustomMentionBlot.tagName = 'span'
CustomMentionBlot.className = 'mention'

const Link = Quill.import('formats/link')

class MyLink extends Link {
    static create(value) {
        let node = Link.create(value)
        value = Link.sanitize(value)
        node.setAttribute('href', value)

        if (value.startsWith('@v2quest')) {
            // NOTE: @v2quest indicates a meme link
            node.removeAttribute('target')
            node.className = 'mention-link'
            node.onclick = e => {
                e.preventDefault()
                e.stopPropagation()

                const { panelId, editMode } = node.closest('.answer-comp')?.dataset
                if (editMode === 'true') return

                const memeText = node?.innerText

                const panels = useStore.getState().panels
                // Inserting vs Appending?
                const indexBeforeChange = panels.getIndexById(panelId)
                const clickedRightPanel = indexBeforeChange > getCenterIndex()
                // Optimization: start the slide animation before inserting the panel.
                panels.insertPanelRight(
                    panelId,
                    { filter: { all: true, meme: memeText } },
                    { animate: !isMobile && !clickedRightPanel },
                )
            }
        } else {
            node.setAttribute('target', '_blank')
            node.onclick = e => {
                e.stopPropagation()
                return true
            }
        }
        return node
    }
}

Quill.register(MyLink)
Quill.register(CustomMentionBlot)

const squashImage = ev => {
    if (ev.target && ev.target.className.includes('medium-zoom-image')) {
        ev.preventDefault()
        ev.stopPropagation()
    }
}

/** helper to remove trailing links from the delta json for aesthetics. meant
 *  to be called only when not in edit mode (lest the user be unable to edit
 *  their links)
 *
 * lots of corner cases and gotchas with this function, when making changes
 * here make sure to test the following scenarios:
 *
 * - msg contains only a link
 * - embed of a msg containing only a link
 * - a link followed by text
 * - text followed by a link
 * - a meme (or other "link", eg a mention) at the end of msg's text
 **/
function cleanTrailingLinks(contents) {
    if (!contents) return
    const lastOpIdx = contents?.ops?.length - 1
    const secondToLastIdx = contents?.ops?.length - 2
    const cleanContents = {
        ...contents,
        ops: compact(
            contents?.ops?.reduce(
                // if the 2nd to last item in the ops list (2nd to last because
                // quill always keeps a \n as the last) is a url and the last
                // item is whitespace then insert a `null` (which will later be
                // removed by compact()), otherwise copy the value over
                (acc, cur, idx) => {
                    const link =
                        idx === secondToLastIdx
                            ? contents?.ops?.[secondToLastIdx]?.attributes?.link
                            : null
                    return [
                        ...acc,
                        link &&
                        !link.startsWith('@v2quest_') &&
                        (contents?.ops?.[lastOpIdx]?.insert || '')?.trim() === ''
                            ? null
                            : cur,
                    ]
                },
                [],
            ),
        ),
    }
    return cleanContents
}

const confirmKeys = ['Enter', 'Tab', 'ArrowRight'] // a confirm key means pick from the autocomplete menu
const continueKeys = [' ']
const cancelKeys = ['Escape']
const navKeys = ['ArrowDown', 'ArrowUp']
const popupTrigger: string = '@'
const allowedChars = /[A-Za-z0-9_]+/

/**
 * A custom `getText()` method that returns a textual representation of the
 * contents of the given quill editor, replacing any embeds with
 * `replacementChr` (as opposed to quill's native .getText() which completely
 * elides all embeds) Useful for preventing off by 1 error hell that happens
 * when doing text processing on quill contents when using `quill.getText()`
 *
 * lightly adapted from https://stackoverflow.com/a/55965372
 * */
function getText(quill, replacementChr = ' ') {
    return quill?.getContents().ops.reduce((text, op) => {
        if (typeof op.insert === 'string') {
            // If the op is a string insertion, just concat
            return text + op.insert
        } else {
            // Otherwise it's a block. Represent this as a newline,
            // which will preserve the length of 1, and also prevent
            // searches matching across the block
            return text + replacementChr
        }
    }, '')
}

function getPopupInfo(quill) {
    const extractItemVal = item => item?.value?.replace(new RegExp(`^${popupTrigger}`), '')
    const quillText: string = quill?.getText()
    const text = getText(quill)
    const cursorPosIdx = quill?.getSelection()?.index
    const textBeforeCursor = text?.slice(0, cursorPosIdx)
    const popupStartIdx = textBeforeCursor?.lastIndexOf(popupTrigger)
    const textBetweenCursorAndStart = textBeforeCursor?.slice(popupStartIdx + 1, cursorPosIdx)
    const popupInputMatch = textBetweenCursorAndStart?.match(allowedChars)
    const popupInput = popupInputMatch?.[0] ?? ''

    let popupShouldShow = false

    if (popupStartIdx !== -1 && popupInputMatch?.input === popupInputMatch?.[0]) {
        popupShouldShow = true
    }

    const info = {
        popupTrigger,
        popupShouldShow,
        popupInputMatch,
        extractItemVal,
        text,
        quillText,
        textBeforeCursor,
        textBetweenCursorAndStart,
        popupStartIdx,
        popupInput,
        cursorPosIdx,
    }
    //console.log(`POPUP INFO: <${popupInput}> ${popupShouldShow}`, info)
    return info
}

function getPopupCoords(quill) {
    // TODO: use quill's own .getBounds() instead of the dom one
    const domSelection = window.getSelection().getRangeAt(0).getBoundingClientRect()
    let { top, left } = domSelection

    if (top == 0 && left == 0) {
        // if poupTrigger is the first char in the <input> getSelection()'s
        // getBoundingClientRect() always returns 0, so use the editor
        // element's getBoundingClientRect() instead
        const coords = quill.container.getBoundingClientRect()
        top = coords?.top
        left = coords?.left
    }
    return { top, left }
}

function autocompletePopupHandler(quill, values) {
    const {
        popupTrigger,
        extractItemVal,
        popupShouldShow,
        text,
        textBeforeCursor,
        popupStartIdx,
        popupInput,
    } = getPopupInfo(quill)

    const { top, left } = getPopupCoords(quill)
    updatePopupProps({
        ...getPopupProps(),
        // make sure the finalizeSelection() handler (the one owned by the quill
        // instance who has just had a keyup ev) is passed into the autocomplete
        // component
        popupTrigger,
        finalizeSelection,
        extractItemVal,
        ...(popupShouldShow
            ? {
                  showing: true,
                  y: top,
                  x: left,
                  inp: popupInput,
                  values,
              }
            : { showing: false }),
    })

    function finalizeSelection(item, opts = { mention: true }) {
        const popupSelection = extractItemVal(item)

        if (!popupSelection) {
            // this shouldn't happen, but don't crash if somehow we are trying to
            // finalize a selection that doesn't exist
            return
        }

        const popupSelectionWithoutTrigger = popupSelection?.replace(
            // TODO: make sure we escape popupTrigger here in case one with meaning in regexes is chosen
            new RegExp(`^${popupTrigger}`),
            '',
        )
        let deltaToInsert, selectionOffset
        if (opts.mention) {
            const mention = {
                mention: {
                    value: popupSelectionWithoutTrigger,
                    denotationChar: popupTrigger,
                    content: popupSelectionWithoutTrigger,
                    id: item?.id,
                },
                attributes: {
                    link: `/users/${popupSelectionWithoutTrigger}`,
                    value: `<a href="/users/${popupSelectionWithoutTrigger}" target="_blank">${popupSelectionWithoutTrigger}`,
                },
            }
            deltaToInsert = new Delta()
                .retain(popupStartIdx)
                .insert(mention)
                .insert(' ')
                .delete(popupTrigger?.length + popupInput?.length)
            selectionOffset = popupStartIdx + 1 + 1
        } else {
            deltaToInsert = new Delta().retain(popupStartIdx + item.value.length).insert(' ')
            selectionOffset = popupStartIdx + item.value.length + 1
        }

        quill.updateContents(deltaToInsert, Quill.sources.USER)

        defer(() => {
            // the `+ 1 + 1` below is cause first 1 is the mention blot, and 2nd 1 is the space suffix
            quill.setSelection(selectionOffset, 0, Quill.sources.USER)
            updatePopupProps({ showing: false })
        })
    }
}

const CommonEditor = React.forwardRef<ReactQuill, { [key: string]: any }>((props, ref) => {
    const edit = props?.readOnly ? '' : 'edit'
    const localQuillRef = ref as React.RefObject<ReactQuill>

    const [value, setValue] = useState(props.value) //if (props.type === 'search') console.log('value', value)
    const localQuillEditor = localQuillRef?.current?.getEditor()
    const filter = props.quest ? questModel.getFilter(props.quest) : null
    const users = useStore(state => state.users || [])
        .filter(user => {
            if (filter?.team_ids) {
                return user?.space_team_ids?.includes(filter?.team_ids?.[0])
            }
            return true
        })
        .map(user => ({
            ...user,
            value: `@${user.name}`,
            content: `@${user.name}`,
            link: `/users/${user.name}`,
        }))

    const textChangeHandlerRef = useRef(null)
    const { selectUser } = useStreamFilters()

    useEffect(
        function initGlobalQuillHandlers() {
            const handleKeyDown = e => {
                const { showing: popupShowing } = getPopupProps()

                // when popup is up make sure special keys are handled by
                // the popup's handler and don't bubble up to the newanswer
                // handler
                if (
                    popupShowing &&
                    (confirmKeys?.includes(e?.key) ||
                        continueKeys?.includes(e?.key) ||
                        cancelKeys?.includes(e?.key) ||
                        navKeys?.includes(e?.key))
                ) {
                    e.preventDefault()
                    e.stopPropagation()

                    if (confirmKeys?.includes(e?.key)) {
                        updatePopupProps({ doSelect: e?.key })
                    }

                    if (continueKeys?.includes(e?.key)) {
                        updatePopupProps({ doContinueKey: e?.key })
                    }

                    if (navKeys?.includes(e?.key)) {
                        updatePopupProps({ doNavKey: e?.key })
                    }

                    if (cancelKeys?.includes(e?.key)) {
                        updatePopupProps({ doCancel: e?.key })
                    }
                }
            }

            // handle data url imgs getting added. adapted from [1]
            // [1]: https://github.com/quilljs/quill/issues/1089#issuecomment-614313509
            async function handleTextChange(delta, oldDelta, source) {
                Array.from(
                    localQuillEditor.container.querySelectorAll('img[src^="data:"]:not(.loading)'),
                ).forEach(async img => {
                    await processBase64Img(img.getAttribute('src'))
                    img.remove()
                })
            }
            if (localQuillEditor) {
                localQuillEditor?.root.addEventListener('keydown', handleKeyDown)
                localQuillEditor.on('text-change', handleTextChange)
            }

            const handleMentionClick = debounce(event => {
                const userId = event?.value?.id
                console.log('MENTION CLICKED: ', event?.value)
                selectUser(userId)
            }, 200)

            window.addEventListener('mention-clicked', handleMentionClick)

            // Cleanup function to remove event listener when component unmounts
            return () => {
                if (localQuillEditor) {
                    localQuillEditor?.root.removeEventListener('keydown', handleKeyDown)
                    localQuillEditor.off('text-change', handleTextChange)
                }
                window.removeEventListener('mention-clicked', handleMentionClick)
            }
        },
        [localQuillEditor, updatePopupProps, getPopupProps, selectUser],
    )

    const debouncedSetLinkPreviewsRef = useRef(null)
    useEffect(
        function cleanupLinkPreviewsOnUnmount() {
            // Return the cleanup function to be called on unmount
            return () => {
                if (debouncedSetLinkPreviewsRef.current) {
                    debouncedSetLinkPreviewsRef.current.cancel()
                    debouncedSetLinkPreviewsRef.current = null
                }
            }
        },
        [debouncedSetLinkPreviewsRef],
    )

    useEffect(function () {
        if (props.captureSetValue) props.captureSetValue(setValue)
    }, [])

    useEffect(
        function setValueFromProps() {
            setValue(props.value)
        },
        [props.value],
    )

    function handleKnovQuestLinks(knovEmbedLinks) {
        const answer = props.answer
        const shortCircuit =
            answer?.is_transcription || answer?.mp4_recording_url || answer?.recording_url
        if (shortCircuit) return // don't do this for transcriptions
        // convert quest URLs to obscureIds, and then request quests from backend
        const questPromises = knovEmbedLinks
            ?.map(link => (link || '')?.split('/').pop())
            ?.map(api.getQuest)

        // and finally add the embedded quests to the draft
        return Promise.all(questPromises).then(embeds => {
            embeds = embeds.map(e => e?.parent).filter(e => !!e)
            props?.setEmbeds?.(embeds)
        })
    }

    /* Quill (unhelpfully in our case) converts images pasted from the
     * clipboard into base64 encoded data urls which were polluting the db with
     * massive json objects. This function converts them to standard browser
     * file/blob objects, and then passes them to the parent's imgHandler fn
     * for normal processing */
    async function processBase64Img(base64Str) {
        if (typeof base64Str !== 'string' || base64Str.length < 100) {
            return base64Str
        }
        const blob = dataUrlToBlob(base64Str)

        // make a synthetic event to pass to the parent's imgHandler
        const pseudoEv = {
            preventDefault: () => {},
            stopPropagation: () => {},
            target: { files: [blob] },
        }
        props.imgHandler(pseudoEv)
    }

    function onChange(content, delta, source, editor) {
        if (source === 'user') {
            if (localQuillRef.current) {
                const quill = localQuillRef?.current?.getEditor()

                const { knovEmbedLinks } = extractUrls(editor.getText())

                autocompletePopupHandler(quill, users)
                handleKnovQuestLinks(knovEmbedLinks)
                // TODO: extract the meme handling code below to it's own function
                const memeMatches = [...getTextAndEmbeds(quill).matchAll(/\[\[(\w|\s)+\]\]/g)]
                memeMatches.map(async (val, index) => {
                    const match = val[0]
                    const fullContent = match.substring(2, match.length - 2)
                    const content = fullContent

                    if (content) {
                        const format = quill.getFormat(val.index + 2, 1)
                        const formatSecond = quill.getFormat(val.index + 3, 1) //to allow editing links from the front
                        const formatLink = format?.link || formatSecond?.link

                        if (
                            formatLink &&
                            formatLink.split('_') &&
                            formatLink.split('_').splice(2).join('_') != content
                        ) {
                            quill.formatText(val.index + 2, fullContent.length, {
                                link: `${formatLink.split('_')[0]}_${
                                    formatLink.split('_')[1]
                                }_${content.trim()}`,
                            })
                            if (
                                formatLink.split('_').splice(2).join('_').length <
                                fullContent.length
                            )
                                quill.setSelection(quill.getSelection().index + 1, 0)
                        } else if (!formatLink) {
                            quill.formatText(val.index + 2, fullContent.length, {
                                link: `@v2quest_self_${content.trim()}`,
                            })
                        }
                    }
                })
                const text = getTextAndEmbeds(quill)
                // There are a couple issues with counting words
                // this way but we'll fix these later
                const re = /\B\!\w+/g
                let match
                while ((match = re.exec(text)) != null) {
                    quill.formatText(match.index, match[0].length, 'bold', true)
                    quill.removeFormat(match.index - 1, 1)
                    if (match.index + match[0].length < quill.getLength() - 1)
                        quill.removeFormat(match.index + match[0].length, 1)
                }

                // TODO: extract the answerPreviewHandling code below to it's own function
                // defer needed to prevent react state update from interfering
                // with quill
                // debounced to avoid hammering the backend's preview
                // endpoint on every keystroke
                debouncedSetLinkPreviewsRef.current = debounce(
                    () => {
                        const { nonKnovLinks } = extractUrls(editor.getText())
                        // the ref will be null if the component has been unmounted
                        if (debouncedSetLinkPreviewsRef?.current)
                            props.setLinkPreviews(uniq(nonKnovLinks))
                    },
                    500,
                    { trailing: true },
                )

                if (props.setLinkPreviews) {
                    if (debouncedSetLinkPreviewsRef?.current)
                        defer(debouncedSetLinkPreviewsRef.current)
                }
            }

            setValue(editor.getContents())
            if (props.onChange) props.onChange(editor.getContents())
        }
    }

    function onKeyDown(quillRef, ev) {
        const enterPostsImmediatelyEnabled = gon?.currentUser?.features?.some?.(
            f => f.name === 'enter_key_posts_immediately',
        )
        const isMac = navigator.platform.includes('Mac')
        const modifierKey = isMac ? ev.metaKey : ev.ctrlKey

        // determine if this key combo should trigger a post
        let shouldSubmit = false
        if (ev.key === 'Enter') {
            if (props.type === 'search') {
                shouldSubmit = true
            } else if (enterPostsImmediatelyEnabled) {
                // post on enter or cmd/ctrl+enter, newline on shift+enter
                shouldSubmit = !ev.shiftKey || modifierKey
            } else {
                // post on shift+enter or cmd/ctrl+enter, newline on enter
                shouldSubmit = ev.shiftKey || modifierKey
            }
        }

        // handle post if conditions are met
        if (shouldSubmit && props.postHandler && props.type !== 'import') {
            props.postHandler()
            ev.preventDefault()
            ev.stopPropagation()
            return false
        }
    }

    const isMobileClass = isMobile ? 'is-mobile' : ''

    const trailingLinksCleanedValue = cleanTrailingLinks(value)
    const shouldHide =
        // hide the answer when cleanTrailingLinks() removed something but
        // we're not rendering in an embed and not currently being edited
        !isEqual(value, trailingLinksCleanedValue) && !props.isEmbed && props.readOnly
    const showableValue = shouldHide ? trailingLinksCleanedValue : value

    // deepcopy the quill modules config so that different instances of the editor
    // don't clobber each other
    let editorModules = cloneDeep(quillModulesConfig)

    if (
        gon?.currentUser?.features?.some(f => f.name === 'enter_key_posts_immediately') ||
        props.type === 'search'
    ) {
        delete editorModules.keyboard.bindings.enter.shiftKey
    }

    if (props.type === 'import') delete editorModules.keyboard.bindings.enter

    return (
        <div style={props.readOnly && isEmpty(showableValue) ? { display: 'none' } : {}}>
            <div
                className={cn(`common-editor-comp ${edit} fs-mask`, isMobileClass, props.type)}
                data-paste-image={props.uiId}
                onClick={squashImage}
            >
                <ReactQuill
                    ref={localQuillRef}
                    theme={null}
                    value={showableValue}
                    onChange={onChange}
                    placeholder={edit ? props.placeholder : ''}
                    readOnly={props.readOnly}
                    onKeyDown={onKeyDown.bind(null, localQuillRef?.current)}
                    modules={editorModules}
                    formats={formats}
                    tabIndex={-1}
                    style={props.style}
                    {...(props.onFocus && { onFocus: props.onFocus })}
                    {...(props.onBlur && { onBlur: props.onBlur })}
                />
            </div>
        </div>
    )
})

const formats = [
    'bold',
    'italic',
    'link',
    'color',
    'mention',
    'header',
    'code',
    'strike',
    'script',
    'underline',
    'blockquote',
    'indent',
    'list',
    'align',
    'direction',
    'code-block',
    'formula',
    'image',
    'video',
]

const Delta = Quill.import('delta')

let quillModulesConfig = {
    keyboard: {
        bindings: {
            tab: false,
            enter: {
                key: 'Enter',
                shiftKey: true,
                handler: function () {
                    return false
                },
            },
        },
    },
    autoLinks: true,
    clipboard: {
        matchVisual: false,
    },
}

const getTextAndEmbeds = quill => {
    return map(quill.getContents().ops, 'insert')
        .map(el => {
            if (typeof el === 'string' || el instanceof String) {
                return el
            } else {
                return '@'
            }
        })
        .join('')
}

export default React.memo(
    React.forwardRef((props, ref) => (
        <ErrorBoundary label="CommonEditor">
            <CommonEditor ref={ref} {...props} />
        </ErrorBoundary>
    )),
)
