import { useEffect } from 'react'
import api from 'api/api'
import useStore from 'state/knovStore'
import CableApp from '../actioncable'

export default function useInitNotifications() {
    const activeSpaceId = useStore(state => state.activeSpaceId)
    const userSpaces = useStore(state => state.userSpaces)
    const set = useStore.getState().set

    const refreshNotificationsCount = async () => {
        if (gon.currentUser) {
            const numNoti = await api.getNotificationsCount(activeSpaceId)
            set({ numNoti })
        }
    }

    useEffect(() => {
        const user = gon.currentUser
        let channel
        if (user) {
            channel = CableApp.cable.subscriptions.create(
                { channel: 'UserChannel', userId: user.id },
                {
                    received: async data => {
                        if (data.message === 'new notification') {
                            refreshNotificationsCount()
                        }
                    },

                    connected: () => {
                        console.log('Connected to UserChannel:Notifications.')
                    },
                },
            )
        }
        return () => channel?.unsubscribe()
    }, [])

    useEffect(
        function getSpacesNotificationsOnSpaceChange() {
            userSpaces?.forEach(async s => {
                const numNoti = await api.getNotificationsCount(s.id)

                set(state => {
                    // Set for currently shown space.
                    if (activeSpaceId === s.id) state.numNoti = numNoti
                    // Set for other user spaces to show sidebar noti.
                    state.spaceNotiCounts = {
                        ...state.spaceNotiCounts,
                        [s.id]: numNoti,
                    }
                })
            })
        },
        [userSpaces, activeSpaceId],
    )

    useEffect(function initServiceWorker() {
        if (gon.currentUser && navigator && navigator.serviceWorker) {
            navigator.serviceWorker.addEventListener('message', async function (e) {
                const { pushReceived } = e.data
                console.log('pushReceived', pushReceived)

                if (pushReceived) {
                    refreshNotificationsCount()
                }
            })
        }
    }, [])
}
