import React from 'react'
import styles from 'components/quests/confirm.module.scss'

export default function Confirm({ modalParams, closeModal }) {
    const onConfirm = () => {
        if (modalParams.confirm) modalParams.confirm()
        closeModal()
    }

    const onCancel = () => {
        if (closeModal) closeModal()
    }

    return (
        <div className={styles.confirmComp}>
            <div className={styles.confirmMessage}>{modalParams.message}</div>
            <div className={styles.confirmActions}>
                <button className={styles.cancelButton} onClick={onCancel}>
                    Cancel
                </button>

                <button className={styles.confirmButton} onClick={onConfirm}>
                    {modalParams.confirmLabel}
                </button>
            </div>
        </div>
    )
}
