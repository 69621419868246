import React, { useEffect, useRef } from 'react'
import cn from 'classnames'
import styles from './right-sidebar.module.scss'
import { isMobile } from 'react-device-detect'

export default function RightSidebar({ children, show, onClose }) {
    const sidebarRef = useRef(null)

    useEffect(() => {
        function handleClickOutside(event) {
            if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
                onClose()
            }
        }

        if (show) {
            document.addEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [show, onClose])

    return (
        <div className={cn(styles.rightSidebar, show && styles.show)}>
            <div className={styles.overlay} />
            <div ref={sidebarRef} className={cn(styles.content, isMobile && styles.mobileContent)}>
                {children}
            </div>
        </div>
    )
}
