import React from 'react'
import model from 'components/quests/questModel'
import perms from 'lib/perms'
import styles from 'components/quests/Quest/thread-control.module.scss'
import cn from 'classnames'

export default function ThreadControl(props) {
    const quest = props.quest

    const onClickTypeOptions = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        props.setShowTypeOptions(!props.showTypeOptions)
    }

    const name = model.threadType(props.quest)?.name
    const header = props.isActiveQuest ? name?.toUpperCase() : name

    return (
        <div
            className={cn(styles.typeControlComp, props.contextStyles)}
            onClick={onClickTypeOptions}
        >
            {false && <i className={`fa ${model.threadType(props.quest)?.icon}`} />}

            <span className={cn(styles.typeLabel, props.fontStyles)}>{header}</span>

            {true && ( //perms.amQuestOwner(props.quest) && (
                <span className={styles.typeControl}>
                    <i className={'fa fa-angle-down'} />
                </span>
            )}
        </div>
    )
}
