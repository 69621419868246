import _ from 'lodash'

export const shallowMerge = (newQuest, existingQuest) => {
    return Object.assign({}, newQuest, {
        parent: existingQuest.parent,
        sorted_answers: existingQuest.sorted_answers,
    })
}

export const toJSObject = str => {
    try {
        return JSON.parse(str)
    } catch (e) {
        return str
    }
}

// Immer mutation:
export const updateInArray = arr => quest => {
    if (arr) {
        const ix = arr.findIndex(q => q.id === quest.id)
        if (ix >= 0) {
            arr[ix] = quest
        }
    }
    return quest
}

export const getQuestContent = quest => {
    if (!quest) return null

    let query
    if (quest.content) {
        query = quest.content
    } else {
        // This is for quests that are in the cache.
        if (quest.side_quest && quest.parent && quest.parent.content) {
            query = quest.parent.content
        } else if (quest.question && quest.question.content) {
            query = quest.question.content
        } else {
            query = ''
        }
    }
    return query === '#quest' ? '' : query
}

export const getActiveQuery = (query, quest, newAnswerQuery) => {
    let activeQuery = ''
    if (query) {
        activeQuery = query
    } else if (quest) {
        if (newAnswerQuery) {
            activeQuery = newAnswerQuery
        } else {
            activeQuery = getQuestContent(quest)
        }
    }
    return activeQuery
}

const handleCopyWithTextarea = text => {
    const el = document.createElement('textarea')
    el.value = text
    el.setAttribute('readonly', '')
    el.style.position = 'absolute'
    el.style.left = '-9999px'
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
}

export const insertToClipboard = text => {
    if (navigator.clipboard && !window?.KNOVIGATOR_IS_MOBILE) {
        navigator.clipboard.writeText(text.trim()).catch(() => handleCopyWithTextarea(text.trim()))
    } else {
        handleCopyWithTextarea(text)
    }
}

export function initials(teamName) {
    if (teamName?.length > 0) {
        const nameArr = teamName.split(' ')
        if (nameArr.length > 1) {
            return nameArr
                .slice(0, 2)
                .map(n => n.slice(0, 1))
                .join(' ')
        } else {
            return nameArr[0].slice(0, 2)
        }
    } else {
        return ''
    }
}

export function getEntityQuery(text) {
    //let match = text.match(/\[\[(.*)\]\]/)
    let match = text.split('[[')
    if (match.length === 1 || match[1].trim() === '') return match[0]

    match = match[match.length - 1].split(']]')
    return match[0]
}

/** thread/flow helper similar to clojure's -> operator **/
export function pipe<T>(inp: any, ...fns: Function[]): T {
    return [inp, ...fns].reduce((cum, fn) => fn(cum))
}

/* "wrap" within an array -- an easy way to loop around to the other end of an
 * array's index "ie if you have an array with length 3, using this function you
 * can index `4` and this will convert it to 0, or wrap around the other end
 * of the array). lightly adapted from [1].
 *
 * [1]: https://github.com/semibran/wrap-around/blob/master/index.js)*/
export function wrappedArrayGet(array, idx) {
    return array[wrapIndex(array.length, idx)]
}

// the original wrap() function from [1] above
export function wrapIndex(winSize, idx) {
    return idx >= 0 ? idx % winSize : ((idx % winSize) + winSize) % winSize
}

/** helper to discard the return value of the called function.
 * useful when calling functions for their side effects in a pipe()
 * pipeline.
 *
 * NOTE: since pipe() only supports one input param, if more than one input
 * param is passed to discardReturn() they will be discarded
 *
 * EXAMPLE:
 *
 * const addOne = x => x + 1
 * const addOneToWindow= x => { window.sideEffectX = x + 1 }
 * pipe(1, addOne, addOne) // 3
 * pipe(1, addOne, addOneToWindow, addOne) // error cause addOneToWindow returns undefined
 * pipe(1, addOne, discardReturn(addOneToWindow), addOne) // 3, and window.sideEffectX gets set
 * **/
export function discardReturn<T>(fn: (inp: T) => T) {
    return (inp: T): T => {
        fn(inp)
        return inp
    }
}

export function secondsSinceEpoch(utc) {
    return utc ? Math.floor(new Date(utc).getTime() / 1000) : null
}

export function milliSecondsSinceEpoch(utc) {
    return utc ? Math.floor(new Date(utc).getTime()) : null
}

export function isS3LinkExpired(s3Url) {
    console.log('checking expiration', s3Url)
    const url = new URL(s3Url)
    const searchParams = new URLSearchParams(url.search)

    const expires = searchParams.get('X-Amz-Expires')
    const dateString = searchParams.get('X-Amz-Date')

    if (!expires || !dateString) {
        return null
    }

    const currentDate = new Date()
    const amzDate = new Date(
        Date.UTC(
            parseInt(dateString.slice(0, 4)),
            parseInt(dateString.slice(4, 6)) - 1,
            parseInt(dateString.slice(6, 8)),
            parseInt(dateString.slice(9, 11)),
            parseInt(dateString.slice(11, 13)),
            parseInt(dateString.slice(13, 15)),
        ),
    )
    const expirationDate = new Date(amzDate.getTime() + parseInt(expires) * 1000)

    return currentDate > expirationDate
}

export function debugCopy(txt) {
    // use a setTimeout to prevent the browser from blocking copy if item not focused yet
    setTimeout(() => {
        navigator.clipboard.writeText(txt)
        console.log(`Copied '${txt}' to clipboard!`)
    }, 0)
}

export function idFromKey(key) {
    return key.split('-').slice(-5).join('-')
}

/**
 * Display a debug popover with the given text.
 *
 * Useful during mobile dev when you can't easily see console.logs
 *
 * @param text - The text to be displayed.
 */

export function debugPopover(
    text,
    {
        showCopyButton = false,
        showCloseButton = true,
        showOkButton = true,
        autoHideInterval = null,
    } = {},
) {
    const msgEl = document.createElement('div')
    msgEl.style.cssText = `
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0,0,0,0.5);
      opacity: 1;
      transition: opacity 0.3s ease-in-out;
    `

    const popupHtml = `
      <div style="
        position: relative;
        width: 300px;
        background: white;
        padding: 20px;
        word-wrap: break-word;
        overflow: auto;
        border: 1px solid black;
        border-radius: 5px;
        display: flex;
        flex-direction: column;
      ">
        <div style="
          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          margin-bottom: 20px;
        ">
          ${
              showCopyButton
                  ? '<button id="copyButton" style="padding: 10px; border-radius: 5px; cursor: pointer;">Copy to Clipboard</button>'
                  : ''
          }
          ${
              showCloseButton
                  ? '<button id="closeButton" style="background: none; border: none; font-size: 1.5em; cursor: pointer; position: absolute; top: 10px; right: 10px;">x</button>'
                  : ''
          }
        </div>
        <div style="padding: 10px; margin-bottom: 20px;">${text}</div>
        ${
            showOkButton
                ? '<button id="okButton" style="align-self: center; padding: 10px; border-radius: 5px; cursor: pointer;">OK</button>'
                : ''
        }
      </div>
    `

    msgEl.innerHTML = popupHtml
    document.body.appendChild(msgEl)

    const fadeOutAndRemove = () => {
        msgEl.style.opacity = '0'
        setTimeout(() => {
            if (document.body.contains(msgEl)) {
                document.body.removeChild(msgEl)
            }
        }, 300) // Match the transition duration
    }

    if (showCloseButton) {
        msgEl.querySelector('#closeButton').onclick = fadeOutAndRemove
    }

    if (showCopyButton) {
        msgEl.querySelector('#copyButton').onclick = () => {
            const textarea = document.createElement('textarea')
            textarea.textContent = text
            document.body.appendChild(textarea)
            textarea.select()
            try {
                if (document.execCommand('copy')) {
                    alert('Text copied to clipboard!')
                } else {
                    alert('Failed to copy text to clipboard.')
                }
            } catch (err) {
                alert(`Failed to copy text to clipboard. Error: ${err.message}`)
            }
            document.body.removeChild(textarea)
        }
    }

    if (showOkButton) {
        msgEl.querySelector('#okButton').onclick = fadeOutAndRemove
    }

    if (autoHideInterval !== null) {
        setTimeout(fadeOutAndRemove, autoHideInterval)
    }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyFunction = (...args: any[]) => any

export interface MemoizeDebouncedFunction<F extends AnyFunction> extends _.DebouncedFunc<F> {
    (...args: Parameters<F>): ReturnType<F> | undefined
    flush: (...args: Parameters<F>) => ReturnType<F> | undefined
    cancel: (...args: Parameters<F>) => void
}

/**Combines Lodash's _.debounce with _.memoize to allow for debouncing
 * based on parameters passed to the function during runtime.
 *
 * from: https://docs.actuallycolab.org/engineering-blog/memoize-debounce/
 *
 * @param func The function to debounce.
 * @param wait The number of milliseconds to delay.
 * @param options Lodash debounce options object.
 * @param resolver The function to resolve the cache key.
 */
export function memoizeDebounce<F extends AnyFunction>(
    func: F,
    wait = 0,
    options: _.DebounceSettings = {},
    resolver?: (...args: Parameters<F>) => unknown,
): MemoizeDebouncedFunction<F> {
    const debounceMemo = _.memoize<(...args: Parameters<F>) => _.DebouncedFunc<F>>(
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        (..._args: Parameters<F>) => _.debounce(func, wait, options),
        resolver,
    )

    function wrappedFunction(
        this: MemoizeDebouncedFunction<F>,
        ...args: Parameters<F>
    ): ReturnType<F> | undefined {
        return debounceMemo(...args)(...args)
    }

    const flush: MemoizeDebouncedFunction<F>['flush'] = (...args) => {
        return debounceMemo(...args).flush()
    }

    const cancel: MemoizeDebouncedFunction<F>['cancel'] = (...args) => {
        return debounceMemo(...args).cancel()
    }

    wrappedFunction.flush = flush
    wrappedFunction.cancel = cancel

    return wrappedFunction
}
