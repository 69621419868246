import React, { useState, useRef, useEffect } from 'react'
import styles from 'components/quests/ai-magic-wand-menu-button.module.scss'
import cn from 'classnames'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'
import usePanelContext from 'refactor/hooks/usePanelContext'
import { isPanelCenter } from 'state/imperativeApis/swiperApi'

export const ImgGenerators = {
    DALLE: {
        humanName: 'DALL-E 3',
        description: "OpenAI's flagship image generator. Fast and high quality",
        actionTagName: 'dalle',
    },
    SD3: {
        humanName: 'SD3',
        description:
            'Stable Diffusion version 3. High quality images, with excellent text reproduction',
        actionTagName: 'sd3',
    },
    FLUX11: {
        humanName: 'Flux 1.1 Pro',
        description:
            'Flux 1.1: updated version of Flux Pro. Generates high quality images, comparable to midjourney',
        actionTagName: 'flux11',
    },
    FLUX: {
        humanName: 'Flux Pro',
        description: 'Flux: generates high quality images, comparable to midjourney',
        actionTagName: 'flux',
    },
    FLUX_FAST: {
        humanName: 'Flux Fast',
        description: 'Faster version of Flux. Lower quality, but generates faster',
        actionTagName: 'fluxfast',
    },
    SD_LIGHTNING: {
        humanName: 'SD Lightning',
        description:
            'Lightning-fast version of Stable Diffusion. Lower quality, but generates faster',
        actionTagName: 'sdl',
    },
}

const Tools = {
    UBERSEARCH: {
        humanName: 'Treesearch',
        description: 'Write a short report based on a web search',
        actionTagName: 'treesearch',
    },
}

const defaultModel = ImgGenerators.DALLE.actionTagName

export default function AiMagicWandMenuButton({
    models = Object.values(ImgGenerators).map(model => model.actionTagName),
    contextStyles,
    imageStyles,
    onClick,
    setModel,
    getEditor,
    activeModel = defaultModel,
    ...props
}) {
    const { panelId, hide } = usePanelContext()
    const [showMenu, setShowMenu] = useState(false)
    const [hover, setHover] = useState(false)
    const [menuDirection, setMenuDirection] = useState('up')
    const buttonRef = useRef(null)
    const CONTROL_TOP = isPanelCenter(panelId) ? 85 : 0

    const toggleMenu = () => {
        setShowMenu(!showMenu)
    }

    useEffect(() => {
        if (showMenu && buttonRef.current) {
            const buttonRect = buttonRef.current.getBoundingClientRect()
            const spaceAbove = buttonRect.top
            const spaceBelow = window.innerHeight - buttonRect.bottom - CONTROL_TOP

            if (spaceBelow < 287 && spaceAbove > spaceBelow) {
                setMenuDirection('up')
            } else {
                setMenuDirection('down')
            }
        }
    }, [showMenu])

    const renderMenuItem = (item, isActive) => (
        <WithTooltip tip={item.description} key={item.actionTagName}>
            <div
                className={cn(styles.aiMagicWandMenuButtonMenuItem, isActive && styles.activeModel)}
                onClick={() => {
                    const editor = getEditor()
                    const actionTag = `!${item.actionTagName}`
                    const cursorPosition = editor?.selection?.savedRange?.index || 0
                    editor.insertText(0, `${actionTag} `, 'bold', true, 'user')
                    editor.setSelection(cursorPosition + actionTag.length + 1)
                    setShowMenu(false)
                }}
            >
                {item.humanName}
            </div>
        </WithTooltip>
    )

    const renderMenu = () => {
        return (
            <div
                className={cn(
                    styles.aiMagicWandMenuButtonMenu,
                    menuDirection === 'down' && styles.aiMagicWandMenuButtonMenuDown,
                )}
            >
                <div className={styles.menuSection}>
                    <h3 className={styles.menuSectionTitle}>Image Generation</h3>
                    {models.map(model => {
                        const modelInfo = Object.values(ImgGenerators).find(
                            m => m.actionTagName === model,
                        )
                        return renderMenuItem(modelInfo, activeModel === model)
                    })}
                </div>
                <div className={styles.menuDivider} />
                <div className={styles.menuSection}>
                    <h3 className={styles.menuSectionTitle}>Tools</h3>
                    {renderMenuItem(Tools.UBERSEARCH, false)}
                </div>
            </div>
        )
    }

    return (
        <div className={styles.aiMagicWandMenuButtonContainer}>
            <div
                className={cn(styles.aiMagicWandMenuButtonUIMainComp, contextStyles)}
                ref={buttonRef}
            >
                <WithTooltip tip="Use AI">
                    <div
                        className={cn(styles.toggleAiMagicWandMenuButton, hover && styles.hover)}
                        onClick={toggleMenu}
                        onMouseEnter={() => !isMobile && setHover(true)}
                        onMouseLeave={() => !isMobile && setHover(false)}
                    >
                        {/* toggle visibility instead of rendering due to weird loading artifact */}
                        <AiImageButtonIconSvg
                            className={styles.aiMagicWandMenuButtonIcon}
                            color={hover ? 'white' : 'currentColor'}
                        />
                    </div>
                </WithTooltip>

                <div className={cn(styles.separator, hover && styles.activeSeparator)} />

                <WithTooltip tip="Choose model">
                    <div
                        className={cn(styles.aiMagicWandMenuButtonModel, hover && styles.hover)}
                        onClick={toggleMenu}
                        onMouseEnter={() => !isMobile && setHover(true)}
                        onMouseLeave={() => !isMobile && setHover(false)}
                    >
                        <i className="fa fa-ellipsis-v" />
                    </div>
                </WithTooltip>
            </div>

            {showMenu && renderMenu()}
        </div>
    )
}

export function AiImageButtonIconSvg({
    color = 'currentColor',
    style = {},
    className = '',
    width = 24,
    height = 24,
}) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="none"
            stroke={color}
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            style={style}
            className={className}
        >
            <defs />
            <g transform="matrix(-1,0,0,1,24.00387,0)">
                <path d="M 21.64,3.64 20.36,2.36 a 1.21,1.21 0 0 0 -1.72,0 L 2.36,18.64 a 1.21,1.21 0 0 0 0,1.72 l 1.28,1.28 a 1.2,1.2 0 0 0 1.72,0 L 21.64,5.36 a 1.2,1.2 0 0 0 0,-1.72" />
                <path d="m 14,7 3,3" />
                <path d="m 5,6 v 4" />
                <path d="m 19,14 v 4" />
                <path d="M 10,2 V 4" />
                <path d="M 7,8 H 3" />
                <path d="M 21,16 H 17" />
                <path d="M 11,3 H 9" />
            </g>
        </svg>
    )
}
