import React, { useState, useEffect } from 'react'
import { setAccessToken, clearAccessTokens } from 'api/accessTokenMgmt'
import { Link, useNavigate } from 'react-router-dom'
import HeroImage from 'components/auth/HeroImage.jsx'
import flashStyles from 'components/shared/flash.module.scss'
import styles from 'components/auth/auth.module.scss'
import cn from 'classnames'
import useStore from 'state/knovStore'

export default function SignUp({
    registration_path = '/auth',
    stream_invite_token = '',
    space_invite_token = '',
    wallet = '',
}) {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        password: '',
        password_confirmation: '',
        honeypot: '',
    })
    const [emailSignup, setEmailSignup] = useState(wallet !== 'yours')

    let loginQuery = ''
    if (space_invite_token) {
        loginQuery = `?space_invite=${space_invite_token}`
    }
    if (stream_invite_token) {
        loginQuery = `?stream_invite=${stream_invite_token}`
    }

    const handleChange = e => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const [success, setSuccess] = useState()
    const [messages, setMessages] = useState([])

    const handleSubmit = async (e, bsvPubkey = null) => {
        clearAccessTokens()
        try {
            e.preventDefault()

            // validate username
            const usernameRegex = /^[a-zA-Z0-9_-]+$/
            if (!usernameRegex.test(formData?.name)) {
                setSuccess(false)
                setMessages([
                    'Username can only contain letters, numbers, underscores, and dashes.',
                ])
                return
            }

            const params = {
                name: formData?.name,
                email: formData?.email,
                password: formData?.password,
                password_confirmation: formData?.password,
                // need to pass it in directly rather than reading from state or we can miss it due to race condition
                ...(bsvPubkey && { bsv_pubkey: bsvPubkey }),
            }

            if (space_invite_token) {
                params.space_invite = space_invite_token
            }
            if (stream_invite_token) {
                params.stream_invite = stream_invite_token
            }

            const response = await fetch(`${window.knovApiUrl}${registration_path}`, {
                method: 'POST',
                headers: {
                    'Content-type': 'application/json',
                    Accept: 'application/json',
                },
                body: JSON.stringify(params),
            })

            const data = await response.json()
            if (response.ok) {
                // check if we received a synthetic password for panda wallet signups
                if (data.email && data.password) {
                    const modalActions = useStore.getState().actions.modalActions
                    modalActions.openMessageModal(
                        <div>
                            We generated a login for you to use when Panda Wallet is not available.
                            <br />
                            <br />
                            <strong>Email:</strong> {data.email}
                            <br />
                            <strong>Password:</strong> {data.password}
                            <br />
                            <br />
                            Please save these.
                        </div>,
                    )
                }

                const token = {
                    'access-token': response.headers.get('access-token'),
                    expiry: response.headers.get('expiry'),
                    uid: response.headers.get('uid'),
                    client: response.headers.get('client'),
                }

                console.log('Retrieved token val: ', token)
                setAccessToken(token)
                const path = data.path ?? '/'
                navigate(path, { replace: true })
            } else {
                setSuccess(false)
                if (data?.errors?.full_messages?.length > 0) {
                    setMessages(data.errors.full_messages)
                } else {
                    setMessages(['An unexpected error occurred.'])
                }
            }
        } catch (error) {
            console.error('Signup Error:', error)
            setSuccess(false)
            setMessages(['An error occurred during signup.'])
        }
    }

    const statusStyles = messages?.length > 0 && success ? flashStyles.success : flashStyles.error

    const handlePandaSignup = async event => {
        if (!window.panda) {
            alert(
                'Panda Wallet is not available. Please ensure the Panda Wallet extension is installed and try again.',
            )
            setEmailSignup(false)
            return
        }
        const bsvPubkey = await window.panda.connect()
        setFormData(formData => ({ ...formData, bsv_pubkey: bsvPubkey }))
        handleSubmit(event, bsvPubkey)
    }

    return (
        <div className="registrations_new landing">
            <div className="earth container-fluid">
                <div className="row">
                    <HeroImage />
                </div>
                <div className="sign-up-form" data-test-id="sign-up">
                    <div className="col-md-offset-2 col-md-8" id="signup-container">
                        <div style={{ height: `${messages.length * 20}px`, marginBottom: 10 }}>
                            {messages.map((message, index) => (
                                <div key={index} className={statusStyles}>
                                    {message}
                                </div>
                            ))}
                        </div>

                        <form
                            onSubmit={event =>
                                emailSignup ? handleSubmit(event) : event.preventDefault()
                            }
                        >
                            {/* Render error messages here */}
                            <div className="sign-up-fields">
                                <div className="field">
                                    <span className="field-label">Username</span>
                                    <input
                                        style={{
                                            fontSize: /* this prevents autozoom on mobile */ '16px',
                                        }}
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        onKeyDown={event => {
                                            if (event.key === 'Enter') {
                                                if (emailSignup) {
                                                    // Allow form submission
                                                } else {
                                                    event.preventDefault()
                                                    // Show a message
                                                    setMessages([
                                                        'Please click the Sign Up button to continue with Yours Wallet.',
                                                    ])
                                                }
                                            }
                                        }}
                                        autoFocus
                                        placeholder=""
                                        className="form-control"
                                    />
                                </div>

                                {emailSignup && (
                                    <>
                                        <div className="field">
                                            <span className="field-label">Email</span>
                                            <input
                                                style={{
                                                    fontSize:
                                                        /* this prevents autozoom on mobile */ '16px',
                                                }}
                                                type="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                autoFocus
                                                placeholder=""
                                                className="form-control"
                                                autoComplete="email"
                                            />
                                        </div>
                                        <div className="field">
                                            <span className="field-label">Password</span>
                                            <input
                                                type="password"
                                                name="password"
                                                style={{
                                                    fontSize:
                                                        /* this prevents autozoom on mobile */ '16px',
                                                }}
                                                value={formData.password}
                                                onChange={handleChange}
                                                autoComplete="new-password"
                                                className="form-control"
                                                placeholder="8 character minimum"
                                            />
                                        </div>
                                        <div className="field">
                                            <span className="field-label">Confirm Password</span>
                                            <input
                                                type="password"
                                                name="password_confirmation"
                                                value={formData.password_confirmation}
                                                onChange={handleChange}
                                                autoComplete="new-password"
                                                className="form-control"
                                                placeholder=""
                                            />
                                        </div>
                                        <div className="field" style={{ display: 'none' }}>
                                            <input
                                                type="text"
                                                name="honeypot"
                                                value={formData.honeypot}
                                                onChange={handleChange}
                                                autoComplete="off"
                                                className="form-control"
                                                placeholder="honeypot"
                                            />
                                        </div>
                                    </>
                                )}
                            </div>

                            <div className={styles.signupContainer}>
                                {emailSignup ? (
                                    <button type="submit" className={cn(styles.loginButton)}>
                                        Sign Up
                                    </button>
                                ) : (
                                    <button
                                        type="button"
                                        className={cn(
                                            styles.walletChoiceBtn,
                                            styles.yoursWallet,
                                            styles.signupBtn,
                                        )}
                                        onClick={handlePandaSignup} // updated to call handlePandaSignup
                                    >
                                        <img
                                            src="/yours512.png"
                                            alt="Yours Wallet Logo"
                                            className={styles.walletLogo}
                                        />

                                        <div className={styles.walletName}>Sign Up</div>
                                    </button>
                                )}
                            </div>
                        </form>

                        <div className={styles.or}>OR</div>

                        <div className={styles.signupContainer}>
                            {emailSignup ? (
                                <div
                                    className={cn(styles.walletChoiceBtn, styles.yoursWallet)}
                                    onClick={() => setEmailSignup(false)}
                                >
                                    <img
                                        src="/yours512.png"
                                        alt="Yours Wallet Logo"
                                        className={styles.walletLogo}
                                    />

                                    <div className={styles.walletName}>
                                        Sign up with Yours Wallet
                                    </div>
                                </div>
                            ) : (
                                <div
                                    className={cn(styles.loginButton, styles.walletChoiceBtn)}
                                    onClick={() => setEmailSignup(true)}
                                >
                                    <div className={styles.walletName}>
                                        <i className="fa fa-envelope"></i> Sign up with email
                                    </div>
                                </div>
                            )}
                        </div>

                        <div className="auth-links">
                            {'Already have an account?'}
                            <Link className="devise-shared-signup" to={`/login${loginQuery}`}>
                                {' '}
                                Login{' '}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
