import React from 'react'
import useStore from 'state/knovStore'
import styles from 'components/answers/answer-controls.module.scss'
import cn from 'classnames'
import WithTooltip from 'components/shared/WithTooltip'
import { isMobile } from 'react-device-detect'

export default function VideoButton(props) {
    const answerHoverStyles = props.answerHover ? styles.hoverBtn : null
    const openVideoRecorderModal = useStore(
        state => state.actions.modalActions.openVideoRecorderModal,
    )

    const onVideo = () => {
        if (!gon.currentUser) {
            $('#auth-modal').modal('show')
            return
        }

        openVideoRecorderModal(props.quest, props.videoHandler, props.answer)
    }

    const mobileStyles = isMobile ? styles.mobileBtn : null

    return (
        <WithTooltip tip="Record video.">
            <div
                className={cn(
                    styles.editMediaBtn,
                    answerHoverStyles,
                    styles.videoHover,
                    styles.videoBtn,
                    'quest-btn video-modal-btn',
                    mobileStyles,
                )}
                onClick={onVideo}
            >
                <i className="fa fa-video-camera"></i>
            </div>
        </WithTooltip>
    )
}
