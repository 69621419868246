import React, { useState } from 'react'
import ImageInput from 'components/shared/ImageInput'
import styles from 'components/modals/new-space.module.scss'
import { accessTokens } from 'api/accessTokenMgmt'
import useStore from 'state/knovStore'

export default function NewSpace({ modalParams, closeModal }) {
    const setActiveSpace = useStore.getState().setActiveSpace

    const [{ alt, src }, setImg] = useState({
        src: '',
        alt: '',
    })

    const handleImg = e => {
        if (e.target.files[0]) {
            setImg({
                src: URL.createObjectURL(e.target.files[0]),
                alt: e.target.files[0].name,
            })
        }
    }

    const handleSubmit = async e => {
        e.preventDefault()
        const form = new FormData(e.target)
        // TODO: move this to api.ts
        const response = await fetch(gon.api.spaces, {
            method: 'POST',
            body: form,
            headers: {
                ...accessTokens(),
                Accept: 'application/json',
            },
        })
        const data = await response.json()

        if (data?.space) {
            const space = data.space
            console.log('>>> Switching to space: ', space.id)
            closeModal()
            setActiveSpace(space.id, space)
        }
    }
    // Teach Autosuggest how to calculate suggestions for any given input value.

    return (
        <form onSubmit={handleSubmit} encType="multipart/form-data">
            <input type="hidden" name="authenticity_token" />
            <input type="hidden" name="owner_id" value={gon.currentUser?.id} />

            <div className={styles.spaceName}>
                <label htmlFor="name">Space Name</label>
                <input id="name" name="name" required={true} />
            </div>

            <ImageInput
                src={src}
                alt={alt}
                name="space_image"
                handleImg={handleImg}
                label="Space Logo"
            />

            <div className="btn-container">
                <button className="btn btn-primary reload-btn" type="submit">
                    Submit
                </button>
            </div>
        </form>
    )
}
