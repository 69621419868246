import React, { useEffect, useRef } from 'react'
import { JobLifecycle } from 'types/jobLifecycle'
import styles from './job-status.module.scss'

export default function JobStatus({ jobStatus }) {
    const { status, messages } = jobStatus
    const messagesEndRef = useRef(null)

    useEffect(() => {
        if (messagesEndRef.current) {
            // Delay scrolling to allow other animations to complete
            setTimeout(() => {
                messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
            }, 100) // Adjust the delay as needed
        }
    }, [messages.length])

    return (
        <div className={styles.jobStatusContainer}>
            <div className={styles.jobStatusContent}>
                <div className={styles.spinnerContainer}>
                    <div>
                        {status === JobLifecycle.FINISHED && (
                            <i className={`fa fa-check fa-lg ${styles.finishedIcon}`} />
                        )}
                        {status === JobLifecycle.FAILED && (
                            <i className={`fa fa-times fa-lg ${styles.failedIcon}`} />
                        )}
                        {status === JobLifecycle.STARTED && (
                            <i
                                className={`fa fa-circle-o-notch fa-spin fa-lg ${styles.spinningIcon}`}
                            />
                        )}
                    </div>
                </div>
                <div className={styles.messagesContainer}>
                    {messages &&
                        messages.map((msg, index) => (
                            <div
                                key={index}
                                className={`${styles.message} ${
                                    status === JobLifecycle.FAILED ? styles.errorMessage : ''
                                }`}
                                title={msg}
                            >
                                {msg}
                            </div>
                        ))}
                    <div ref={messagesEndRef} />
                </div>
            </div>
        </div>
    )
}
