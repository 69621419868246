import React, { useEffect } from 'react'
import Clipboard from 'clipboard'
import cn from 'classnames'
import styles from 'components/quests/export-quest.module.scss'
import { useState } from 'react'
import api from 'api/api'
import Button from 'components/shared/buttons/Button'
import { isMobile } from 'react-device-detect'

const MAX_DEPTH = 10

export default function ExportQuest(props) {
    useEffect(() => {
        new Clipboard('.copy-btn')
    }, [])

    const [depth, setDepth] = useState(0)
    const [indent, setIndent] = useState(false)

    useEffect(() => {
        const localDepth = window.localStorage.getItem('export_depth')
        if (localDepth !== depth) setDepth(localDepth)

        let localIndent = window.localStorage.getItem('export_indent')
        localIndent = localIndent === 'true' ? true : false
        if (localIndent !== indent) setIndent(localIndent)
    }, [])

    const onChangeDepth = ev => {
        let val = parseInt(ev.target.value, 10)
        if (val >= 0) {
            val = Math.min(val, MAX_DEPTH)
            window.localStorage.setItem('export_depth', val)
            setDepth(val)
        } else {
            window.localStorage.setItem('export_depth', 0)
            setDepth(0)
        }
    }

    const onChangeIndent = ev => {
        const checked = ev.target.checked
        window.localStorage.setItem('export_indent', checked)
        setIndent(checked)
    }

    const mobileExportControlStyles = isMobile ? styles.mobileExportControls : null

    return (
        <div className={cn('export-quest-comp', styles.exportQuestComp)}>
            <div className={cn(styles.textContainer, 'quest-text-container')}>
                {props.modalParams.loading ? (
                    <div className="loading-container">
                        <i className="fa fa-circle-o-notch fa-spin fa-lg"></i>
                    </div>
                ) : (
                    <DisplayCopyText
                        quest={props.modalParams.quest}
                        depth={depth}
                        indent={indent}
                    />
                )}
            </div>

            <div className={styles.exportControlsContainer}>
                <div className={cn(styles.exportControls, mobileExportControlStyles)}>
                    <div className={cn(styles.treeControls)}>
                        <div className={cn(styles.controlContainer, styles.depthInputContainer)}>
                            <input
                                type="number"
                                min="0"
                                max={MAX_DEPTH}
                                name="depth"
                                value={depth || 0}
                                onChange={onChangeDepth}
                            />
                            <label>depth</label>
                        </div>

                        <div className={styles.controlContainer}>
                            <input
                                type="checkbox"
                                name="indent"
                                checked={indent}
                                onChange={onChangeIndent}
                                disabled={depth < 1}
                            />
                            <label>indent</label>
                        </div>
                    </div>

                    <div className={cn(styles.btnsContainer)}>
                        <div
                            className={cn(styles.btnContainer, 'copy-btn')}
                            data-clipboard-target=".quest-text-container"
                        >
                            <Button type="primary" icon="fa fa-copy" text="Copy" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function DisplayCopyText(props) {
    const quest = props.quest

    return (
        <div style={{ overflowWrap: 'break-word' }}>
            <QuestTree
                quest={quest}
                depth={props.depth}
                maxDepth={props.depth}
                indent={props.indent ? 1 : 0}
                header
            />
        </div>
    )
}

function QuestTree(props) {
    const depth = props.depth
    const maxDepth = props.maxDepth
    const indent = props.indent
    const level = (maxDepth - depth) * indent
    const [quest, setQuest] = useState(props.quest)
    const parent = quest?.parent || props.answer

    useEffect(() => {
        async function getSideQuest() {
            if (!quest && parent) {
                const sideQuest = await api.getCommentQuest(parent.id)
                setQuest(sideQuest)
            }
        }
        getSideQuest()
    }, [])

    return (
        <>
            <Answer answer={parent} level={level - 1} header={props.header} />

            {quest ? (
                quest.sorted_answers
                    .filter(ans => !ans.archive)
                    .map(ans => {
                        if (ans.user?.system) return

                        if (depth > 0) {
                            return (
                                <QuestTree
                                    key={`q${ans.id}`}
                                    answer={ans}
                                    depth={depth - 1}
                                    maxDepth={maxDepth}
                                    indent={indent}
                                />
                            )
                        } else {
                            return <Answer key={`a${ans.id}`} answer={ans} level={level} />
                        }
                    })
            ) : (
                <span>Loading...</span>
            )}
        </>
    )
}

function TextBlock(props) {
    const level = props.level
    const textStyle = props.header ? styles.header : null

    const margin = `${Math.max(level * 30, 0)}px`

    return (
        <p className={textStyle} style={{ marginLeft: margin }}>
            {props.children}
        </p>
    )
}

function Answer(props) {
    const ans = props.answer
    const level = props.level

    return (
        <>
            {ans.content && (
                <TextBlock level={level} header={props.header}>
                    {ans.content}
                </TextBlock>
            )}

            {ans.url && <UrlExport answer={ans} level={level} />}

            {!props.isEmbed && ans.embeds && ans.embeds[0] && (
                <Answer answer={ans.embeds[0]} isEmbed level={level} />
            )}
        </>
    )
}

function UrlExport(props) {
    const ans = props.answer

    return (
        <>
            {ans.url && (
                <TextBlock level={props.level}>
                    {ans.url.title && (
                        <>
                            {ans?.url?.title}
                            <br />
                        </>
                    )}

                    {ans.url.address && (
                        <>
                            {ans?.url?.address}
                            <br />
                        </>
                    )}

                    {ans.url.selection && (
                        <>
                            <span style={{ whiteSpace: 'pre' }}>&quot;</span>
                            {ans?.url?.selection}
                            <span style={{ whiteSpace: 'pre' }}>&quot;</span>
                            <br />
                        </>
                    )}
                </TextBlock>
            )}
        </>
    )
}
