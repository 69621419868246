import React from 'react'
import styles from 'components/modals/import-text.module.scss'
import CommonEditor from 'components/shared/CommonEditor'
import Button from 'components/shared/buttons/Button'
import cn from 'classnames'
import api from 'api/api'
import { getText } from 'lib/value'
import useStore from 'state/knovStore'

export default function ImportText(props) {
    const richEditor = React.createRef()

    const createThread = async () => {
        const editor = richEditor.current?.getEditor()
        const deltaJson = editor?.getContents()
        const text = getText(deltaJson).trim()
        const quest = await api.postThread(text, deltaJson)
        if (quest) {
            // Center Quest
        }
        props.closeModal()
    }

    return (
        <div className={cn('import-text-comp', styles.importTextComp)}>
            <CommonEditor
                //ref={this.assignEditorRef}
                ref={richEditor}
                //assignedRef={richEditor}
                //value={''}
                //onChange={onChange}
                placeholder={'Paste text here...'}
                postHandler={createThread}
                //users={this.props.users}
                //tags={this.props.tags}
                //ignoreQuestMentions={true}
                type="import"
            />

            <div className={styles.importBtnContainer}>
                <div className={styles.rightBtnContainer}>
                    <Button type="primary" text="Import" onClick={createThread} />
                </div>
            </div>
        </div>
    )
}
