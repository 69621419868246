import React from 'react'
import { isMobile } from 'react-device-detect'

export default function WithSelection(props) {
    const squashEvent = ev => {
        //console.log('squash ev')
        ev.preventDefault()
        ev.stopPropagation()
    }

    /*
    const squashEventSelected = ev => {
        if (window.getSelection().toString().length > 0) {
            console.log('squash selected')
            //squashEvent(ev)
        }
    }
    */

    if (props.editMode) {
        return <div className="no-op-with-selection-comp">{props.children}</div>
    } else {
        // For mobile, selection only works on title messages. To get drag to work we must set draggable and noselect dynamically in the dnd wrapper.
        return (
            <div
                style={{
                    fontSize: 0,
                    letterSpacing: 0,
                    wordSpacing: 0,
                }}
            >
                {isMobile ? (
                    <>
                        {props.isTitle ? (
                            <span
                                className={'with-selection-comp title-selection'}
                                onMouseDown={ev => ev.stopPropagation()}
                                onTouchStart={ev => ev.stopPropagation()}
                            >
                                {props.children}
                            </span>
                        ) : (
                            <span className={'with-selection-comp'} draggable>
                                {props.children}
                            </span>
                        )}
                    </>
                ) : (
                    <div
                        className={'with-selection-comp'}
                        draggable
                        onDragStart={squashEvent}
                        // This is to prevent event from bubbling to toggleSelectedAnswer so that it won't clear selection, but will on blur.
                        //onMouseDown={ev => ev.stopPropagation()}
                        //onClick={ev => ev.stopPropagation()}
                        style={{
                            // display: 'inline-block',
                            userSelect: 'text',
                            margin: 0,
                        }} // This is so we limit the selection to only the text contents.
                    >
                        <span>{props.children}</span>
                    </div>
                )}
            </div>
        )
    }
}
