import React, { useState, useEffect, useLayoutEffect, useRef, useCallback } from 'react'
import Sidebar from '../shared/Sidebar'
import FilterOptions from 'components/filters/FilterOptions'
import Control from 'components/panels/Control'
import { isMobile } from 'react-device-detect'
import ReactTooltip from 'react-tooltip'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Virtual } from 'swiper'
import 'swiper/swiper-bundle.css'
import { DndProvider } from 'react-dnd'
import MultiBackend, { TouchTransition, MouseTransition } from 'react-dnd-multi-backend'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { TouchBackend } from 'react-dnd-touch-backend'
import Preview from 'react-dnd-preview'
import Answer from '../answers/Answer'
import PanelContainer from 'components/panels/PanelContainer'
import Panel from 'components/panels/Panel'
import EmptyPanel from 'components/panels/EmptyPanel'
import HistoryPanel from 'components/panels/HistoryPanel'
import useStore, { startingIndex } from 'state/knovStore'
import {
    getSwiperRef,
    getCenterIndex,
    slideTo,
    getPanelsToLeft,
    getPanelsToRight,
} from 'state/imperativeApis/swiperApi'
import { getTooltipRef } from 'state/imperativeApis/tooltipApi'
import AutocompletePopup from 'components/AutocompletePopup'
import ErrorBoundary from 'components/shared/ErrorBoundary'
import CableApp from '../../actioncable'
import { uniqBy } from 'lodash'
import * as cache from 'state/cache'
import styles from 'components/PanelController/panel-controller.module.scss'
import { throttle } from 'lodash'
import { pathFromFilter } from 'components/filters/useStreamFilters'
import api from 'api/api'
import { ChevronLeft, ChevronRight } from 'lucide-react'
import cn from 'classnames'
import SidebarButton from 'components/shared/SidebarButton'

import useSlideEffects from 'refactor/hooks/useSlideEffects'
import useAnimatedSlide from 'refactor/hooks/useAnimatedSlide'
import UserControl from 'components/panels/UserControl'
import { transformTo, SMALL, BIG } from 'lib/transform'
import useWalletDetails from 'api/useWalletDetails'
import { BsvWalletType } from 'wallets/wallets'
import RightSidebar from '../shared/RightSidebar'
import WalletHistory from 'components/wallet/WalletHistory'

let initialInnerHeight = window.innerHeight
SwiperCore.use([Virtual])

const HTML5toTouch = {
    backends: [
        {
            backend: HTML5Backend,
            transition: MouseTransition,
        },
        {
            backend: TouchBackend,
            options: {
                enableMouseEvents: true,
                delayTouchStart: 500,
                ignoreContextMenu: true,
            },
            preview: true,
            transition: TouchTransition,
        },
    ],
}

const generatePreview = ({ itemType, item, style }) => {
    let width
    isMobile ? (width = '100%') : (width = '33%')
    return (
        <div
            style={Object.assign(style, { zIndex: 2000, width, height: '100%' })}
            className={'noselect row'}
        >
            <div className="col-md-12">
                <Answer {...item} />
            </div>
        </div>
    )
}

export default React.memo(function PanelController() {
    //console.log('RENDER PANEL CONTROLLER')
    const swiperRef = getSwiperRef()
    const tooltipRef = getTooltipRef()

    const showSidebar = useStore(state => state.showSidebar)
    const setShowSidebar = useStore.getState().setShowSidebar

    const showRightSidebar = useStore(state => state.showRightSidebar)
    const setShowRightSidebar = useStore.getState().setShowRightSidebar

    const [showFilterOptions, setShowFilterOptions] = useState(false)
    const [slideUpdates, triggerTouchSlideUpdate] = useState(0)
    const [keyboardOpen, setKeyboardOpen] = useState(false)

    const canSlidePrev = useCallback(() => !swiperRef?.current?.isBeginning, [])
    const canSlideNext = useCallback(() => !swiperRef?.current?.isEnd, [])

    // ensure the wallet details are loaded when app starts
    const currentUserBsvWalletType = useStore(state => state.currentUserBsvWalletType)
    useWalletDetails(
        window.panda
            ? gon.currentUser?.options?.bsv_wallet_type || currentUserBsvWalletType
            : BsvWalletType.SHUALLET,
    )

    const slidePrev = useCallback(() => {
        if (canSlidePrev()) {
            slideTo(getCenterIndex() - 1)
        }
    }, [])

    const slideNext = useCallback(() => {
        if (canSlideNext()) {
            slideTo(getCenterIndex() + 1)
        }
    }, [])

    const handleSidebarClose = useCallback(() => {
        // We check the global state bc the sidebar state is not updated immediately when the sidebar is closed due to throttle.
        if (useStore.getState().showSidebar) {
            setShowSidebar(false)
        }
    }, [])

    const prevDeltaX = useRef(0)
    const isSwiping = useRef(false)
    const handleTouchPadSwipe = useCallback(
        throttle(
            ev => {
                ev.preventDefault()
                ev.stopPropagation()

                const { deltaX, deltaY } = ev
                const isHorizontalScroll = Math.abs(deltaX) > Math.abs(deltaY)

                const isIncreasingDelta = Math.abs(deltaX) - Math.abs(prevDeltaX.current) > 1
                const isDecreasingDelta = prevDeltaX.current - Math.abs(deltaX) > 1

                if (isHorizontalScroll) {
                    //console.log('horizontal wheel event', Math.abs(deltaX), prevDeltaX.current, isSwiping.current)
                    prevDeltaX.current = Math.abs(deltaX)

                    if (isDecreasingDelta || Math.abs(deltaX) <= 1) {
                        //console.log('FINISHED')
                        isSwiping.current = false
                    } else if (isIncreasingDelta && !isSwiping.current) {
                        isSwiping.current = true
                        //prevDeltaX.current = 100
                        // Assumes we are drag the content (not the viewport) when swiping.
                        if (deltaX < 0) {
                            useStore.getState().showRightSidebar
                                ? useStore.getState().setShowRightSidebar(false)
                                : slidePrev()
                        } else if (deltaX > 0) {
                            const showSidebarGlobal = useStore.getState().showSidebar
                            showSidebarGlobal ? handleSidebarClose() : slideNext()
                        }
                    }
                }
            },
            50,
            {
                leading: true,
                trailing: false,
            },
        ),
        [showSidebar, showRightSidebar, handleSidebarClose, slideNext, slidePrev],
    )

    useEffect(() => {
        if (isMobile || !swiperRef.current) return

        const onSlideStart = ev => {
            document.removeEventListener('wheel', handleTouchPadSwipe)
        }
        swiperRef.current.on('slideChangeTransitionStart', onSlideStart)

        const onSlideEnd = ev => {
            document.addEventListener('wheel', handleTouchPadSwipe, { passive: false })
        }
        swiperRef.current.on('slideChangeTransitionEnd', onSlideEnd)

        document.addEventListener('wheel', handleTouchPadSwipe, { passive: false })

        return () => {
            document.removeEventListener('wheel', handleTouchPadSwipe)
            swiperRef.current.off('slideChangeTransitionStart', onSlideStart)
            swiperRef.current.off('slideChangeTransitionEnd', onSlideEnd)
            isSwiping.current = false
            prevDeltaX.current = 0
        }
    }, [])

    useEffect(() => {
        let channel
        const currentUser = gon?.currentUser
        if (currentUser) {
            channel = CableApp.cable.subscriptions.create(
                { channel: 'UserChannel', userId: currentUser.id },
                {
                    received: async data => {
                        if (data.message === 'history updated') {
                            const stateQuestHistory = useStore.getState().questHistory
                            if (!cache.getCachedQuest(data.quest?.id)) {
                                cache.cacheQuest(data.quest)
                            }
                            useStore.getState().set({
                                questHistory: uniqBy([data.quest, ...stateQuestHistory], 'id'),
                            })
                        }
                    },
                },
            )
        }
        return () => {
            if (channel && channel.unsubscribe) {
                channel.unsubscribe()
            }
        }
    }, [])

    useEffect(() => {
        const user = gon.currentUser
        let channel
        if (user) {
            channel = CableApp.cable.subscriptions.create(
                { channel: 'UserChannel', userId: user.id },
                {
                    received: async data => {
                        if (data.message === 'new quest notification added') {
                            // TODO PANEL REFACTOR
                            // show new noti
                        }
                    },
                },
            )
        }

        return () => {
            if (channel && channel.unsubscribe) {
                channel.unsubscribe()
            }
        }
    }, [])

    const isExpoAndroid = window.KNOVIGATOR_IS_MOBILE && /Android/i.test(navigator.userAgent)
    const isOldMobile = isMobile && !window.visualViewport

    useEffect(() => {
        // Handle keyboard for expo android or if no viewport available.
        if (isExpoAndroid || isOldMobile) {
            function keyboardCheck() {
                if (window.innerHeight < initialInnerHeight) {
                    setKeyboardOpen(true)
                } else {
                    setKeyboardOpen(false)
                }
            }

            window.addEventListener('resize', keyboardCheck)

            return () => {
                if (keyboardCheck) window.removeEventListener('resize', keyboardCheck)
            }
        }
    }, [])

    const panelControllerRef = useRef(null)
    const lastActiveIndexRef = useRef(null)

    useEffect(() => {
        if (!isExpoAndroid && isMobile && window.visualViewport) {
            const visualViewport = window.visualViewport
            let initialVisualViewportHeight = visualViewport.height

            const keyboardCheck = throttle(
                () => {
                    const isKeyboardOpen = initialVisualViewportHeight - visualViewport.height > 100
                    setKeyboardOpen(isKeyboardOpen)
                },
                100,
                { leading: false, trailing: true },
            )

            visualViewport.addEventListener('resize', keyboardCheck)

            return () => {
                visualViewport.removeEventListener('resize', keyboardCheck)
            }
        }
    }, [])

    useEffect(function newModalVideoOnMount() {
        new ModalVideo('#play-footer-demo i', { channel: 'youtube', autoplay: 1 })
    }, [])

    const toggleSidebar = () => {
        setShowSidebar(!showSidebar)
    }

    const blurSidebar = ev => {
        if (showSidebar && document.getElementById('sidebar') !== ev.target) toggleSidebar()
    }

    const panels = useStore(store => store.panels)
    const panelList = panels.state
    const { updateCenteredPanelId } = useSlideEffects()
    const { renderAnimatedInsertPanels, renderAnimatedRemovePanels, hidePanelDuringAnimation } =
        useAnimatedSlide()

    //console.log('RENDER PANELCONTROLLER-------------------------------------')
    //console.log('panel state', panels)
    //console.log('active index', swiperRef.current?.activeIndex)
    //console.log('starting index', startingIndex)
    //console.log('center index', centerIndex)

    useLayoutEffect(function handleMobileSwipe() {
        if (isMobile) {
            let touchStartX = null
            let touchStartY = null
            let touchStartTime = null

            const handleTouchStart = event => {
                touchStartX = event.touches[0].clientX
                touchStartY = event.touches[0].clientY
                touchStartTime = Date.now()
            }

            const handleTouchMove = event => {
                if (!touchStartX || !touchStartY) return

                const touchMoveX = event.touches[0].clientX
                const touchMoveY = event.touches[0].clientY
                const deltaX = touchMoveX - touchStartX
                const deltaY = Math.abs(touchMoveY - touchStartY)
                const timeDelta = Date.now() - touchStartTime

                // Calculate velocity (pixels per millisecond)
                const velocity = Math.abs(deltaX) / timeDelta

                // Adjust thresholds based on velocity
                const SWIPE_THRESHOLD = velocity > 0.5 ? 50 : 100 // More sensitive for fast swipes
                const VERTICAL_LIMIT = 30 // Stricter vertical limit

                if (Math.abs(deltaX) > SWIPE_THRESHOLD && deltaY < VERTICAL_LIMIT) {
                    if (deltaX < 0 && useStore.getState().showSidebar) {
                        handleSidebarClose()
                        touchStartX = null // Prevent multiple triggers
                    }
                    if (deltaX > 0 && useStore.getState().showRightSidebar) {
                        setShowRightSidebar(false)
                        touchStartX = null // Prevent multiple triggers
                    }
                }
            }

            const handleTouchEnd = () => {
                touchStartX = null
                touchStartY = null
                touchStartTime = null
            }

            document.addEventListener('touchstart', handleTouchStart)
            document.addEventListener('touchmove', handleTouchMove, { passive: false })
            document.addEventListener('touchend', handleTouchEnd)

            return () => {
                document.removeEventListener('touchstart', handleTouchStart)
                document.removeEventListener('touchmove', handleTouchMove)
                document.removeEventListener('touchend', handleTouchEnd)
            }
        }
    }, [])

    const handleUserControlClick = useCallback(() => {
        setShowRightSidebar(true)
    }, [])

    return (
        <>
            <div className={styles.panelControllerComp} onClick={blurSidebar}>
                <Sidebar showSidebar={showSidebar} />

                {gon.currentUser && (
                    <RightSidebar
                        show={showRightSidebar}
                        onClose={() => setShowRightSidebar(false)}
                    >
                        <FilterOptions
                            user={gon.currentUser}
                            header="User"
                            show={['user']}
                            theme="dark"
                            contextStyles={cn(styles.userSidebar)}
                            setShowFilterOptions={setShowFilterOptions}
                            close={() => setShowRightSidebar(false)}
                        />

                        <WalletHistory />
                    </RightSidebar>
                )}

                <div className={styles.swipeControls}>
                    <div className={styles.swipeControlContainer}>
                        <div
                            className={cn(
                                styles.sidebarButtonContainer,
                                isMobile && styles.mobileSidebarButtonContainer,
                            )}
                        >
                            <SidebarButton
                                showSidebar={showSidebar}
                                toggleSidebar={toggleSidebar}
                                customClass="sidebar-button"
                            />
                        </div>

                        <SlidePrevControl slidePrev={slidePrev} />
                    </div>

                    <div className={styles.swipeControlContainer}>
                        <SlideNextControl slideNext={slideNext} />
                        {!showRightSidebar && (
                            <div
                                className={cn(
                                    styles.userStreamContainer,
                                    isMobile && styles.mobileUserStreamContainer,
                                )}
                            >
                                {gon?.currentUser ? (
                                    <UserControl
                                        user={gon?.currentUser}
                                        showRightSidebar={showRightSidebar}
                                        setShowRightSidebar={setShowRightSidebar}
                                        contextStyles={cn(styles.userIcon)}
                                        isOpen={showSidebar}
                                        onClick={handleUserControlClick}
                                    />
                                ) : (
                                    <div
                                        className={cn(styles.loginIcon)}
                                        //onClick={openAuthModal}
                                        onClick={ev => (location.href = '/login')}
                                        data-tip={'Sign up / Log in'}
                                    >
                                        <i className={cn('fa fa-user')} />
                                    </div>
                                )}
                            </div>
                        )}
                    </div>
                </div>

                <ErrorBoundary label="DndProvider">
                    <DndProvider backend={MultiBackend} options={HTML5toTouch}>
                        <ErrorBoundary label="Swiper">
                            <div
                                ref={panelControllerRef}
                                style={{ overflow: 'hidden', height: '100dvh' }}
                            >
                                <Swiper
                                    slidesPerView={isMobile ? 1 : 3}
                                    initialSlide={isMobile ? startingIndex : startingIndex + 1}
                                    //initialSlide={isMobile ? startingIndex - 1 : (startingIndex - 1)}
                                    //initialSlide={isMobile ? 1 : 0}
                                    onSwiper={swiper => {
                                        swiperRef.current = swiper
                                        window.swiper = swiper
                                        // Listen for slide changes to update the history
                                        swiper.on('slideChange', async () => {
                                            //console.log('HASH slideChange')
                                            //const centerIndex = getCenterIndex()
                                            const ix = isMobile
                                                ? swiper.activeIndex
                                                : swiper.activeIndex + 1
                                            let panels = useStore.getState().panels.state
                                            let panel = panels[ix]
                                            const filter = panel?.filter
                                            if (filter) {
                                                const isDraft =
                                                    filter.questId &&
                                                    cache.getCachedQuest(filter.questId)?.is_draft
                                                if (isDraft) return

                                                const panelId = panel.panelId
                                                const path = pathFromFilter(filter)
                                                history.pushState(
                                                    { centerIndex: ix, panelId, filter },
                                                    '',
                                                    path,
                                                ) // + `#panel-${panelId}`)
                                                api.updateUserSpaceOptions({
                                                    last_path: path,
                                                    filter,
                                                })
                                                if (filter.questId) {
                                                    await api.userViewsQuest(filter.questId)
                                                    const actionsQueryKey = ['actions']
                                                    queryClient.invalidateQueries({
                                                        queryKey: actionsQueryKey,
                                                        exact: true,
                                                    })
                                                    queryClient.refetchQueries({
                                                        queryKey: actionsQueryKey,
                                                        exact: true,
                                                    })
                                                }
                                            }
                                        })
                                    }}
                                    preventClicksPropagation={true}
                                    preventClicks={false}
                                    touchMoveStopPropagation={true}
                                    simulateTouch={isMobile}
                                    passiveListeners={false}
                                    // These dont work when adding slides dynamically.
                                    //{...(isMobile && {
                                    //    allowSlideNext: () => !swiperRef?.current?.isEnd,
                                    //    allowSlidePrev: () => !swiperRef?.current?.isBeginning,
                                    //})}
                                    //This is related to a weird mobile bug that is fixed by toggling overflow property
                                    {...(isMobile &&
                                        slideUpdates === 0 && { style: { overflow: 'initial' } })}
                                    onActiveIndexChange={swiper => {
                                        //console.log('onActiveIndexChange', swiper.activeIndex)
                                        const centerIndex = isMobile
                                            ? swiper.activeIndex
                                            : swiper.activeIndex + 1
                                        updateCenteredPanelId(centerIndex)
                                        set({ activeIndex: swiper.activeIndex, centerIndex })

                                        if (!isMobile) {
                                            const lastActiveIndex = lastActiveIndexRef?.current
                                            const panels = useStore.getState().panels.state

                                            if (lastActiveIndex) {
                                                const lastCenterIndex = lastActiveIndex + 1
                                                const newCenterIndex = swiper.activeIndex + 1

                                                // Transform the new center panel to BIG
                                                transformTo(BIG, panels[newCenterIndex])

                                                // Transform the old center panel to SMALL
                                                if (lastCenterIndex !== newCenterIndex) {
                                                    transformTo(SMALL, panels[lastCenterIndex])
                                                }
                                            } else {
                                                // Initial load or edge case.
                                                transformTo(SMALL, panels[swiper.activeIndex])
                                                transformTo(BIG, panels[swiper.activeIndex + 1])
                                                transformTo(SMALL, panels[swiper.activeIndex + 2])
                                            }
                                        }

                                        // Update the lastActiveIndexRef
                                        lastActiveIndexRef.current = swiper.activeIndex
                                    }}
                                    virtual
                                >
                                    {renderAnimatedInsertPanels()}

                                    {renderAnimatedRemovePanels()}

                                    {panelList.map((panel, index) => {
                                        return (
                                            <SwiperSlide key={panel.panelId} virtualIndex={index}>
                                                <PanelContainer
                                                    // props passed through PanelContext to children.
                                                    panel={panel}
                                                    index={index}
                                                    hide={hidePanelDuringAnimation(index)}
                                                    isEmpty={panel.empty}
                                                >
                                                    {panel.empty ? (
                                                        <EmptyPanel />
                                                    ) : panel.filter?.history ? (
                                                        <HistoryPanel />
                                                    ) : (
                                                        <Panel keyboardOpen={keyboardOpen} />
                                                    )}
                                                </PanelContainer>
                                            </SwiperSlide>
                                        )
                                    })}
                                </Swiper>
                            </div>
                        </ErrorBoundary>

                        <Preview generator={generatePreview} />
                    </DndProvider>
                </ErrorBoundary>

                {true && (
                    <ReactTooltip
                        ref={tooltip => (tooltipRef.current = tooltip)}
                        effect="solid"
                        place="bottom"
                        delayShow={1000}
                        arrowColor="rgba(0,0,0,0)"
                        globalEventOff={isMobile ? 'touchstart' : undefined}
                    />
                )}
                <TitleNotificationWidget />
            </div>

            <div className={cn(styles.controlContainer, isMobile && styles.mobileControlContainer)}>
                {!keyboardOpen && (
                    <Control
                        showFilterOptions={showFilterOptions}
                        setShowFilterOptions={setShowFilterOptions}
                        showRightSidebar={showRightSidebar}
                        setShowRightSidebar={setShowRightSidebar}
                    />
                )}
            </div>

            {showFilterOptions && (
                <div
                    className={cn(
                        styles.filterOptionsContainer,
                        isMobile && styles.mobileFilterOptionsContainer,
                    )}
                >
                    <FilterOptions
                        show={['default', 'teams']}
                        theme="dark"
                        contextStyles={cn(
                            styles.filterOptions,
                            isMobile && styles.mobileFilterOptions,
                        )}
                        showNoti
                        setShowFilterOptions={setShowFilterOptions}
                        blur
                        append
                        close={() => setShowFilterOptions(!showFilterOptions)}
                    />
                </div>
            )}

            <AutocompletePopup />
        </>
    )
})

function SlidePrevControl({ slidePrev }) {
    useStore(state => state.panels.state)
    useStore(state => state.activeIndex)

    const numPrevPanels = getPanelsToLeft(getCenterIndex())?.length - 1

    return (
        numPrevPanels > 0 && (
            <div className={styles.swipeLeftContainer}>
                <div
                    className={cn(
                        isMobile ? styles.mobileSwipeControl : styles.swipeControl,
                        styles.swipeLeftControl,
                    )}
                    onClick={slidePrev}
                >
                    <ChevronLeft className={styles.swipeLeft} size={20} />
                </div>

                <div
                    className={cn(
                        isMobile ? styles.mobileSwipeControlLabel : styles.swipeControlLabel,
                    )}
                >
                    {numPrevPanels}
                </div>
            </div>
        )
    )
}

function SlideNextControl({ slideNext }) {
    useStore(state => state.panels.state)
    useStore(state => state.activeIndex)

    const numNextPanels = getPanelsToRight(getCenterIndex())?.length - 1

    return (
        numNextPanels > 0 && (
            <div className={styles.swipeRightContainer}>
                <div
                    className={cn(
                        isMobile ? styles.mobileSwipeControlLabel : styles.swipeControlLabel,
                    )}
                >
                    {numNextPanels}
                </div>

                <div
                    className={cn(
                        isMobile ? styles.mobileSwipeControl : styles.swipeControl,
                        styles.swipeRightControl,
                    )}
                    onClick={slideNext}
                >
                    <ChevronRight className={styles.swipeRight} size={20} />
                </div>
            </div>
        )
    )
}

function TitleNotificationWidget() {
    const numNoti = useStore(state => state.numNoti)
    useEffect(() => {
        document.title = `Treechat ${numNoti ? `(${numNoti})` : ''}`
    }, [numNoti])
    return <></>
}
