import { PanelState } from 'state/PanelState'

export const BIG = 1
export const SMALL = 0.85
export const TRANSITION = 'all 0.2s ease-in-out'

export function setStringTransform(
    transformObject: { [key: string]: string | number },
    existingTransform?: string,
): string {
    let existingTransformObject: { [key: string]: string } = {}

    if (existingTransform) {
        // Parse existing transform string
        existingTransform.split(' ').forEach(transform => {
            const [key, value] = transform.split('(')
            existingTransformObject[key] = value.slice(0, -1) // Remove closing parenthesis
        })
    }

    // Merge new transforms with existing ones, overwriting if necessary
    Object.entries(transformObject).forEach(([key, value]) => {
        // Handle special cases for certain transformations
        if (key === 'translate' || key === 'scale') {
            if (typeof value === 'number') {
                existingTransformObject[key] = `${value}`
            } else if (Array.isArray(value) && value.length === 2) {
                existingTransformObject[key] = `${value[0]}, ${value[1]}`
            }
        } else {
            // Default case for other transformations
            existingTransformObject[key] = `${value}`
        }
    })

    // Generate the final transform string
    const transformations = Object.entries(existingTransformObject).map(
        ([key, value]) => `${key}(${value})`,
    )

    return transformations.join(' ')
}

interface TransformObject {
    scale: number
    translateY: number
}

export function getTransform(cssTransform: string): TransformObject {
    const transformObject: TransformObject = {
        scale: 1,
        translateY: 0,
    }

    cssTransform.split(' ').forEach(transform => {
        if (transform?.length > 0) {
            const [k, value] = transform.split('(')
            if (k === 'scale' || k === 'translateY') {
                transformObject[k] = parseFloat(value.slice(0, -1))
            }
        }
    })

    return transformObject
}

export function transformTo(targetSize, panel: PanelState) {
    const panelContainer = panel.getPanelRef()
    if (!panelContainer) return

    const transformContainer = panelContainer.querySelector('.transform-container')
    const scrollContainer = panelContainer.querySelector('.scroll-container')
    const transformObject = getTransform(transformContainer.style.transform)
    const currentTranslateY = transformObject.translateY || 0
    const SCALE_FACTOR = targetSize === BIG ? BIG / SMALL : SMALL / BIG

    if (targetSize === BIG) {
        //console.log('SCALE TO BIG', panel.panelId)
        const currentOffset = scrollContainer.scrollTop - currentTranslateY // This should be equivalent to pc.getBoundingClientRect().top
        //console.log('currentOffset', currentOffset)
        //console.log('SCALE_FACTOR', SCALE_FACTOR)
        const newTranslateY = currentOffset * (SCALE_FACTOR - 1)
        //console.log('newTranslateY', newTranslateY)
        transformContainer.style.transition = TRANSITION
        transformContainer.style.transform = `translateY(${-newTranslateY}px) scale(${BIG})`

        const updatedScale = getTransform(transformContainer.style.transform).scale
        //console.log('updatedScale to big', updatedScale, transformContainer.style.transform)

        setTimeout(() => {
            requestAnimationFrame(() => {
                const updatedScale = getTransform(transformContainer.style.transform).scale
                const updatedTranslateY = getTransform(
                    transformContainer.style.transform,
                ).translateY
                if (updatedScale === SMALL || updatedTranslateY === 0) return

                //console.log('SCALE TO BIG SCROLL UPDATE', panel.panelId)
                //console.log(
                //    'transformContainer.style.transform',
                //    transformContainer.style.transform,
                //)
                //console.log('scrollContainer.scrollTop', scrollContainer.scrollTop)
                //console.log('newTranslateY', newTranslateY)
                //console.log('target scrollTop', scrollContainer.scrollTop + newTranslateY)

                transformContainer.style.transition = 'none'

                transformContainer.style.transform = `scale(${BIG})`
                scrollContainer.scrollTop = scrollContainer.scrollTop + newTranslateY

                // Use requestAnimationFrame to ensure the style changes have been applied
                requestAnimationFrame(() => {
                    transformContainer.style.transition = TRANSITION
                })
            })
        }, 300) // 50ms timeout, adjust if needed
    } else {
        //console.log('SCALE TO SMALL', panel.panelId)
        // The idea here is that when holding sc.scrollTop constant, and then scaling smaller the content will rise up (simulating a scroll up).
        //We need to make up the difference by translating down (positive translateY).
        // Transformation order matters and we will do -> transform: translateY scale
        // Transforms are performed RIGHT to LEFT, so this does the translate after the scale and uses the viewport sized pixels, more importantly this turns out to be a SMOOTHER animation when performed.

        const currentOffset = scrollContainer.scrollTop - currentTranslateY // This should be equivalent to pc.getBoundingClientRect().top
        const newTranslateY = Math.round(currentOffset * (1 - SMALL)) // We need to make up the difference for the smaller scale by translating down (positive translateY).
        transformContainer.style.transition = TRANSITION
        transformContainer.style.transform = `translateY(${newTranslateY}px) scale(${SMALL})`
        const updatedScale = getTransform(transformContainer.style.transform).scale
        //console.log('updatedScale to small', updatedScale)

        // Use setTimeout and requestAnimationFrame to make the scroll adjustment imperceptible
        setTimeout(() => {
            requestAnimationFrame(() => {
                const updatedScale = getTransform(transformContainer.style.transform).scale
                const updatedTranslateY = getTransform(
                    transformContainer.style.transform,
                ).translateY
                if (updatedScale === BIG || updatedTranslateY === 0) return

                //console.log('SCALE TO SMALL SCROLL UPDATE', panel.panelId)
                //console.log(
                //    'transformContainer.style.transform',
                //    transformContainer.style.transform,
                //)
                //console.log('scrollContainer.scrollTop', scrollContainer.scrollTop)
                //console.log('newTranslateY', newTranslateY)
                const newScrollTop = Math.max(0, scrollContainer.scrollTop - newTranslateY)
                //console.log('target scrollTop', newScrollTop)
                transformContainer.style.transition = 'none'

                transformContainer.style.transform = `scale(${SMALL})`
                scrollContainer.scrollTop = newScrollTop

                // Use requestAnimationFrame to ensure the style changes have been applied
                requestAnimationFrame(() => {
                    transformContainer.style.transition = TRANSITION
                })
            })
        }, 300)
    }
}
