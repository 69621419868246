import React, { ReactElement } from 'react'
import styles from 'components/votes/lock-vote.module.scss'
import EditableAmount from '../EditableAmount'
import { ActionButton } from 'components/votes/buttons/ActionButton'
import { SaveButton } from 'components/votes/buttons/SaveButton'
import { useSatsToDollars, formatUsdValue } from 'api/useBsvExchangeRate'

interface BoostTabProps {
    description: string
    customSats: number | null
    setCustomSats: (value: number | null) => void
    useSats: boolean
    quickLockRef: React.MutableRefObject<any>
    handleLockOrUpvalue: (sats: number, blocks?: number) => void
    animateLock: (ref: any, scale?: number) => void
}

export function BoostTab({
    description,
    customSats,
    setCustomSats,
    useSats,
    quickLockRef,
    handleLockOrUpvalue,
    animateLock,
}: BoostTabProps): ReactElement {
    const usdAmount = useSatsToDollars(customSats)
    const usdValue = formatUsdValue(usdAmount)

    return (
        <>
            <div className={styles.popupDescription}>{description}</div>

            <div className={styles.amountRow}>
                <div className={styles.amountInputContainer}>
                    <EditableAmount
                        value={customSats}
                        onChange={setCustomSats}
                        useSats={useSats}
                        label="Choose Amount"
                    />
                    {usdValue && <div className={styles.usdValue}>≈ {usdValue}</div>}
                </div>
            </div>

            <ActionButton
                action="boost"
                customSats={customSats}
                handleAction={handleLockOrUpvalue}
            />

            <SaveButton
                save="boost"
                customSats={customSats}
                quickLockRef={quickLockRef}
                animateLock={animateLock}
            />
        </>
    )
}
