import React from 'react'
import styles from 'components/quests/quest-options-header.module.scss'

export default function QuestOptionsHeader(props) {
    const closeQuestOptions = ev => {
        ev.stopPropagation()
        ev.preventDefault()

        if (props.close) props.close()
    }

    return (
        <div className={styles.questOptionsHeader}>
            <div className={styles.questOptionsTitle}>{props.title}</div>

            {props.close && (
                <div className={styles.questOptionsToggle} onClick={closeQuestOptions}>
                    <i className="fa fa-close"></i>
                </div>
            )}
        </div>
    )
}
