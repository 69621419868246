import React from 'react'
//import useStore from 'state/knovStore'
import styles from 'components/quests/new-quest-button.module.scss'
import cn from 'classnames'
import FilterIcon from 'components/filters/FilterIcon'

export default function NewQuestButtonUIMain(props) {
    const leftBtnStyles = props.showDropdown ? styles.leftBtn : null

    return (
        <div className={styles.newThreadComp}>
            <div
                className={cn(styles.streamBtn, leftBtnStyles, props.contextStyles)}
                onClick={props.onClick}
            >
                {false && (
                    <FilterIcon
                        filter={props.filter}
                        panel={'middle'}
                        user={props.user}
                        contextStyles={cn(styles.filterIcon)}
                        fontIcon={styles.fontIcon}
                        notiIcon={styles.notiIcon}
                        publicIcon={styles.publicIcon}
                        allIcon={styles.allIcon}
                    />
                )}

                {props.showIcon && (
                    <i className={cn('fa fa-pencil', props.showLabel ? styles.withLabel : null)} />
                )}

                {props.showLabel && <span className={styles.label}>{props.label}</span>}
            </div>

            {props.showDropdown && (
                <div
                    className={cn(styles.streamBtn, styles.rightBtn)}
                    onClick={() => props.setShowFilterOptions(!props.showFilterOptions)}
                >
                    {props.showFilterOptions ? (
                        <i className="fa fa-chevron-up" />
                    ) : (
                        <i className="fa fa-chevron-down" />
                    )}
                </div>
            )}
        </div>
    )
}
