import React, { useState } from 'react'
import styles from 'components/people/people.module.scss'
import UserIcon from 'components/users/UserIcon'
import ThinQuest from 'components/quests/ThinQuest'
import useInsertRight from 'refactor/hooks/useInsertRight'
import cn from 'classnames'
import usePanelContext from 'refactor/hooks/usePanelContext'
import useStore from 'state/knovStore'
import { getCachedQuest } from 'state/cache'

export function Participants({ participants, quest, onClick, contextStyles }) {
    return (
        <div
            className={cn(styles.participantsComp, contextStyles)}
            onClick={() => onClick && onClick()}
        >
            <div className={styles.participantsHeader}>
                <div className={styles.participantsLabel}>Participants:</div>

                {quest && (
                    <div className={styles.timeWrapper}>
                        <Time time={quest.updated_at} />
                    </div>
                )}
            </div>

            <div className={styles.participantsWrapper}>
                {participants.length === 1 && (
                    <div className={styles.firstParticipant}>
                        <UserIcon user={participants[0]} />
                        <span className={styles.participantName}>
                            {participants[0].name || 'Unknown Participant'}
                        </span>
                    </div>
                )}

                {participants.length > 1 && (
                    <div className={styles.multiParticipants}>
                        {participants.map((participant, index) => (
                            <div key={participant.id || index} className={styles.multiParticipant}>
                                <UserIcon user={participant} contextStyles={styles.multiUserIcon} />
                                <div className={styles.multiParticipantName}>
                                    {participant.name || 'Unknown'}
                                </div>
                            </div>
                        ))}
                    </div>
                )}
            </div>
        </div>
    )
}

export function nonUserParticipants(quest) {
    return quest?.participants
        ?.filter(participant => participant.id !== gon?.currentUser?.id)
        ?.sort((a, b) => {
            const aLatestAnswer = quest.sorted_answers
                .filter(answer => answer.user_id === a.id)
                .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))[0]
            const bLatestAnswer = quest.sorted_answers
                .filter(answer => answer.user_id === b.id)
                .sort((x, y) => new Date(y.created_at) - new Date(x.created_at))[0]
            return (
                new Date(bLatestAnswer?.created_at || 0) - new Date(aLatestAnswer?.created_at || 0)
            )
        })
}

const People = ({ quest }) => {
    const { insertRight } = useInsertRight()
    const [hover, setHover] = useState(false)

    const onClick = () => {
        if (quest && quest.participants_key) {
            insertRight(
                {
                    filter: {
                        participants: quest.participants_key,
                    },
                },
                {
                    questId: quest.id,
                },
            )
        }
    }

    const participants = nonUserParticipants(quest)

    const firstParticipant = participants && participants[0]

    const displayAnswerIds = firstParticipant
        ? quest.sorted_answers
              .filter(answer => answer.user_id === firstParticipant?.id)
              .sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
              .slice(0, 1)
              .map(answer => answer.id)
        : []

    // Always show last message for ease of identifying thread.
    const lastAnswer = quest.sorted_answers[quest.sorted_answers.length - 1]
    if (lastAnswer && !displayAnswerIds.includes(lastAnswer.id)) {
        displayAnswerIds.push(lastAnswer.id)
    }

    return (
        <div
            className={cn(styles.peopleComp, hover && styles.hover)}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={onClick}
        >
            {participants && participants.length > 0 ? (
                <>
                    <Participants participants={participants} quest={quest} />
                    <div className={styles.lastThreadLabel}>Last thread:</div>

                    <div
                        className={styles.questRow}
                        onMouseEnter={() => setHover(false)}
                        onMouseLeave={() => setHover(true)}
                    >
                        <ThinQuest
                            quest={quest}
                            displayAnswerIds={displayAnswerIds}
                            contextStyles={cn(styles.thinQuest, hover && styles.thinQuestBorder)}
                        />
                    </div>

                    {quest.participants_key_count - 1 > 0 && (
                        <div className={styles.participantsCountWrapper}>
                            <span className={styles.participantsCount}>
                                {quest.participants_key_count - 1} more{' '}
                                {quest.participants_key_count - 1 === 1 ? 'thread' : 'threads'}.
                            </span>
                        </div>
                    )}
                </>
            ) : (
                <div className={styles.noParticipants}>
                    No participants found for quest {quest.id}
                </div>
            )}
        </div>
    )
}

export default People

function Time(props) {
    const { time } = props
    const today = new Date()
    const givenDate = new Date(time)

    const isToday = givenDate.toDateString() === today.toDateString()

    if (isToday) {
        return (
            <div className={styles.time}>
                {givenDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
            </div>
        )
    } else {
        return <div className={styles.time}>{givenDate.toLocaleDateString()}</div>
    }
}
