import React from 'react'
import styles from 'components/auth/hero-image.module.scss'
import cn from 'classnames'

const HeroImage = () => {
    return (
        <div className={cn('col-md-offset-4 col-md-4', styles.hero)}>
            <div className="session-logo-image">
                <a className={styles.heroLink} href="/">
                    <img className={styles.logoImg} src="/treechat-logo-text.png" />
                </a>
            </div>
        </div>
    )
}

export default HeroImage
