import React from 'react'
import { blur, focus } from './focusApi'

const editorRefs = {
    activeNewAnswerRef: React.createRef(),
    sideNewAnswerRef: React.createRef(),

    activeQuestEditorRef: React.createRef(),
    sideQuestEditorRef: React.createRef(),

    newQuestEditorRef: React.createRef(),

    middleSearchBarRef: React.createRef(),
    rightSearchBarRef: React.createRef(),
}

export function getEditorRef(ref) {
    if (ref) return editorRefs[ref]
    else return null
}

export function assignMiddleSearchBarRef(ref) {
    editorRefs.middleSearchBarRef = ref
}

export function focusMiddleSearchBar() {
    focus(editorRefs.middleSearchBarRef)
}

export function assignRightSearchBarRef(ref) {
    editorRefs.rightSearchBarRef = ref
}

export function focusRightSearchBar() {
    focus(editorRefs.rightSearchBarRef)
}

export function assignActiveNewAnswerRef(ref) {
    editorRefs.activeNewAnswerRef.current = ref
}

export function focusActiveNewAnswer() {
    focus(editorRefs.activeNewAnswerRef?.current?.richEditor?.current)
}

export function assignSideNewAnswerRef(ref) {
    editorRefs.sideNewAnswerRef.current = ref
}

export function focusSideNewAnswer() {
    focus(editorRefs.sideNewAnswerRef?.current?.richEditor?.current)
}

export function assignActiveQuestEditorRef(ref) {
    editorRefs.activeQuestEditorRef.current = ref
}

export function assignSideQuestEditorRef(ref) {
    editorRefs.sideQuestEditorRef.current = ref
}

export function assignNewQuestEditorRef(ref) {
    editorRefs.newQuestEditorRef.current = ref
}

export function focusActiveQuestEditor() {
    focus(editorRefs.activeQuestEditorRef)
}

export function focusSideQuestEditor() {
    focus(editorRefs.sideQuestEditorRef)
}

export function focusNewQuestEditor() {
    focus(editorRefs.newQuestEditorRef)
}

export function blurNewQuestEditor() {
    blur(editorRefs.newQuestEditorRef)
}
