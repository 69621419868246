import React from 'react'
import cn from 'classnames'
import styles from '../lock-vote.module.scss'
import { SignalType } from 'types/signals'

interface ActionButtonProps {
    action: SignalType
    customSats: number | null
    customBlocks?: number
    handleAction: (sats: number, blocks?: number) => void
}

export function ActionButton({
    action,
    customSats,
    customBlocks,
    handleAction,
}: ActionButtonProps) {
    const label = action === 'upvalue' || action === 'boost' ? 'Upvalue' : 'Lock'

    return (
        <div className={styles.actionButtonComp}>
            <div
                className={cn(
                    styles.actionButton,
                    action === 'upvalue' || action === 'boost'
                        ? styles.upvalueButton
                        : styles.lockButton,
                )}
                onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    const wholeSats = Math.round(customSats)
                    if (typeof wholeSats === 'number' && wholeSats >= 1) {
                        handleAction(wholeSats, customBlocks)
                    }
                }}
            >
                <i
                    className={`fa fa-${
                        action === 'upvalue' || action === 'boost' ? 'arrow-up' : 'lock'
                    }`}
                />
                <span className={styles.actionButtonLabel}>{label}</span>
            </div>
        </div>
    )
}
