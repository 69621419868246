import React, { useState } from 'react'
import ImageInput from 'components/shared/ImageInput'
import api from 'api/api'
import styles from 'components/modals/image-update.module.scss'

export default function SpaceImageUpdate({ modalParams, closeModal }) {
    const { space } = modalParams

    const [{ alt, src }, setImg] = useState({
        src: space.space_image_url ? space.space_image_url : '',
        alt: 'No Image',
    })

    const handleSubmit = async ev => {
        ev.preventDefault()
        ev.stopPropagation()

        const form = new FormData(ev.target)
        closeModal()
        const apiSpace = await api.updateSpace(space.id, form)
        if (apiSpace) modalParams.callback(apiSpace)
    }

    const handleImage = ev => {
        if (ev.target.files[0]) {
            setImg({
                src: URL.createObjectURL(ev.target.files[0]),
                alt: ev.target.files[0].name,
            })
        }
    }

    return (
        <form method="PATCH" onSubmit={handleSubmit} encType="multipart/form-data">
            {/*<input type="hidden" name="authenticity_token" value={gon.formToken} />*/}

            <ImageInput
                src={src}
                alt={alt}
                name="space_image"
                handleImg={handleImage}
                label="Space Logo"
                contextStyles={styles.spaceImageInput}
            />

            <div className="btn-container">
                <button className="btn btn-primary reload-btn" type="submit">
                    Submit
                </button>
            </div>
        </form>
    )
}
