import React, { useRef } from 'react'
import Modal from 'react-modal'
import useStore from 'state/knovStore'
import styles from './crypto-pay-modal.module.scss'
import cn from 'classnames'

Modal.setAppElement('#knovigator-body')

export default function CryptoPayModal(props) {
    const isCryptoPayModalOpen = useStore(state => state.isCryptoPayModalOpen)
    const left = useStore(state => state.cryptoPayModalLeft)
    const top = useStore(state => state.cryptoPayModalTop)
    const closeCryptoModal = useStore(state => state.actions.modalActions.closeCryptoModal)
    const cryptoPayCallback = useStore(state => state.cryptoPayCallback)
    const inputRef = useRef()
    let customStyles = {
        content: {
            top,
            left,
            right: 'auto',
            bottom: 'auto',
            transform: 'translate(-60px, -50%)',
        },
    }
    return (
        <Modal
            isOpen={isCryptoPayModalOpen}
            contentLabel="Knov Modal"
            style={customStyles}
            onRequestClose={closeCryptoModal}
        >
            <div className={cn(styles.handCashComp, 'handcash-pay-comp')}>
                <div className={cn(styles.cent)}>¢</div>
                <div className={cn(styles.inputContainer)}>
                    <input
                        placeholder="Enter amount..."
                        type="number"
                        width="100%"
                        ref={inputRef}
                    />
                </div>
                <span
                    className={cn(styles.payBtn, 'btn btn-primary')}
                    style={{ backgroundColor: '#337ab7 !important' }}
                    onClick={() => {
                        cryptoPayCallback(inputRef.current.value)
                        closeCryptoModal()
                    }}
                >
                    <i className="fa fa-check"></i>
                </span>
            </div>
        </Modal>
    )
}
