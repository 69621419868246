import { v4 as uuid } from 'uuid'
import { Filter } from 'components/filters/useStreamFilters'

export type PanelId = string
export type QuestId = string

export enum DefaultStreamFilters {
    public = 'public',
    private = 'private',
    notifications = 'notifications',
    link = 'link',
    all = 'all',
}

export class PanelState {
    panelId: PanelId = uuid()
    empty: boolean = false
    newThread: boolean = false
    stream?: `${DefaultStreamFilters}` | { teamId: string } = 'public'
    filter?: Filter
    questId?: QuestId
    query?: any

    constructor(panel?: Partial<PanelState>) {
        if (panel) {
            Object.assign(this, panel)
        }
    }

    private panelRef?: HTMLDivElement
    setPanelRef = (ref: HTMLDivElement) => {
        this.panelRef = ref
    }
    getPanelRef = () => this.panelRef
    private animationPanelRef?: HTMLDivElement
    setAnimationPanelRef = (ref: HTMLDivElement) => {
        this.animationPanelRef = ref
    }
    getAnimationPanelRef = () => this.animationPanelRef

    getTransformContainer = () => this.panelRef?.querySelector('.transform-container')
    getScrollContainer = () => this.panelRef?.querySelector('.scroll-container')

    getAnimationStyles = () => {
        const transformContainer = this.getTransformContainer()
        const scrollContainer = this.getScrollContainer()
        if (!transformContainer || !scrollContainer) return null

        const transform = transformContainer.style.transform
        const scrollTop = scrollContainer.scrollTop
        //console.log('getAnimationStyles', scrollTop)
        return {
            mirrorPanelRef: this.panelRef,
            transform,
            scrollTop,
        }
    }
}

export const emptyPanel = () => new PanelState({ empty: true })
