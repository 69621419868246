import React from 'react'
import Vote from 'components/votes/Vote'
import TweetEmbed from 'react-tweet-embed'
import VidCommands from './VidCommands'
import UrlPost from './UrlPost'
import parseDomain from 'parse-domain'
import styles from './url-answer.module.scss'
import cn from 'classnames'
import WithSelection from '../shared/WithSelection'
import { LinkPreview, twitterRegexp, knovLinkRegexp } from 'components/shared/LinkPreview'

export default class UrlAnswer extends React.PureComponent {
    constructor(props) {
        super(props)
    }

    isUserSignedIn() {
        return $('#knovigator-body').attr('data-user') === 'true'
    }

    showVotes = () => {
        return typeof this.props.showVotes === 'undefined' ? true : this.props.showVotes
    }

    squash = ev => {
        ev.stopPropagation()
        ev.preventDefault()
    }

    voteContainerParent = React.createRef()

    showVidCommands = () => this.props.answer.yt_app && this.props.command

    isTwitterUrl = url => {
        return twitterRegexp.test(url?.address)
    }

    render() {
        const answer = this.props.answer
        const url = answer.url
        const address = answer.url && answer.url.address ? answer.url.address : ''
        const parse = parseDomain(address)
        let domain,
            img = null
        if (parse) {
            domain = `${parse.domain}.${parse.tld}`
            //img = `https://www.google.com/s2/favicons?sz=32&domain_url=${domain}`
            img = `https://icon.horse/icon/${domain}?size=small`
        }
        const editStyles = this.props.editMode ? styles.edit : null
        const isEmbedStyles = this.props.isEmbed ? styles.isEmbed : null
        const embedUrlContainerStyles = this.props.isEmbed ? styles.embedUrlContainer : null
        const commandContainerTitleStyles = this.props.isTitle ? styles.commandContainerTitle : null

        return (
            <div className={styles.urlAnswerComp}>
                {this.showVidCommands() && (
                    <div className={cn(styles.commandContainer, commandContainerTitleStyles)}>
                        <VidCommands
                            command={this.props.command}
                            vidStart={this.props.vidStart}
                            vidLoop={this.props.vidLoop}
                            comment={answer}
                            getYtPlayer={this.props.getYtPlayer}
                            vidLoaded={this.props.vidLoaded}
                            loadVid={this.props.loadVid}
                        />
                    </div>
                )}

                <div className={cn(styles.flexContainer)}>
                    {/*this.props.showVotes &&
                        <div className={cn(styles.voteContainer)} ref={this.voteContainerParent}>
                            <Vote
                                votable={this.props.answer}
                                votableType="Answer"
                                votableId={this.props.answer.id}
                                voteTotal={this.props.answer.vote_total}
                                cryptoVoteTotal={this.props.answer.crypto_vote_total}
                                userVote={this.props.answer.current_user_vote}
                                userSignedIn={this.isUserSignedIn()}
                                hover={this.props.answerHover}
                                updateVotable={this.props.updateAnswer}
                                displayCrypto={this.props.answer.crypto_vote_total > 0}
                                parentRef={this.voteContainerParent}
                                toggleShowKnovigator={this.props.toggleShowKnovigator}
                            />
                        </div>
                    */}

                    <div className={cn(styles.userPostContent, editStyles, isEmbedStyles)}>
                        {this.props.answer.url &&
                            !knovLinkRegexp.test(this.props.answer.url.address) && (
                                <div
                                    className={cn(styles.urlContainer, embedUrlContainerStyles)}
                                    data-answer={this.props.answer.id}
                                >
                                    <div className={styles.urlWithLogo}>
                                        <div className={cn(styles.urlFavicon)}>
                                            <img src={img} alt={domain} />
                                        </div>

                                        <div className={styles.sourceContainer}>
                                            <div className={cn(styles.source)}>
                                                <div
                                                    className={cn(
                                                        styles.sourceHeader,
                                                        'source-header',
                                                    )}
                                                >
                                                    {this.props.isYoutube ? (
                                                        <UrlPost
                                                            url={this.props.answer.url}
                                                            snippet={this.props.answer.content}
                                                            domain={domain}
                                                            vidStart={this.props.vidStart}
                                                        />
                                                    ) : (
                                                        <div className={styles.linkPreviewWrapper}>
                                                            <LinkPreview
                                                                url={this.props.answer.url.address}
                                                                inViewCallback={
                                                                    this.props.inViewCallback
                                                                }
                                                                urlModel={this.props.answer.url}
                                                            />
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            {url?.selection && !this.isTwitterUrl(url) && (
                                                <div
                                                    className={cn(
                                                        styles.selectionContainer,
                                                        'web-quote-outer',
                                                    )}
                                                    data-answer={this.props.answer.id}
                                                >
                                                    <WithSelection>
                                                        <div
                                                            className={cn(
                                                                styles.selection,
                                                                'web-quote-inner',
                                                            )}
                                                            data-answer-id={this.props.answer.id}
                                                        >
                                                            {this.props.answer.url.selection.replace(
                                                                /&nbsp;/,
                                                                ' ',
                                                            )}
                                                        </div>
                                                    </WithSelection>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        )
    }
}
