import React, { useState, useEffect } from 'react'
import useStore from 'state/knovStore'
import AddStream from 'components/quests/AddStream'
import cn from 'classnames'
import CopyTooltip from 'components/shared/CopyTooltip'
import ReactTooltip from 'react-tooltip'
import { insertToClipboard } from '../../utils'
import WithTooltip from 'components/shared/WithTooltip'

import UserIcon from 'components/users/UserIcon'
import TeamIcon from 'components/users/TeamIcon'
import PrivateIcon from 'components/shared/icons/PrivateIcon'
import LinkIcon from 'components/shared/icons/LinkIcon'
import ClipIcon from 'components/shared/icons/ClipIcon'
import PublicIcon from 'components/shared/icons/PublicIcon'
import TreechatIconPng from 'components/shared/icons/TreechatIconPng'
import TwetchIcon from 'components/shared/icons/TwetchIcon'
import AllIcon from 'components/shared/icons/AllIcon'
import NotiIcon from 'components/shared/icons/NotiIcon'
import HistoryIcon from 'components/shared/icons/HistoryIcon'
import StarredIcon from 'components/shared/icons/StarredIcon'
import { isMobile } from 'react-device-detect'
import useStreamFilters from 'components/filters/useStreamFilters'
import { getCenterPanel } from 'state/imperativeApis/swiperApi'
import { Wallet } from 'lucide-react'
import styles from 'components/shared/filter-options.module.scss'

import perms from 'lib/perms'
import NewQuestButton from '../quests/NewQuestButton'

export default function FilterOptions(props) {
    const user = gon && gon.currentUser
    // FilterOptions can sometimes be mounted outside of a Panel as in PanelController.
    // In that case we default to the center panel.
    const panelId = props.panelId || getCenterPanel()?.panelId
    const filters = useStreamFilters(panelId, { append: !!props.append })
    const filter = filters.filter

    const openWalletModal = useStore(state => state.actions.modalActions.openWalletModal)
    const knovigatorSpace = useStore(state => state.knovigatorSpace)
    const activeSpaceId = useStore(state => state.activeSpaceId)
    const userSpaces = useStore(state => state.userSpaces)
    const userStreams = useStore(state => state.userStreams)
    const numNoti = useStore(state => state.numNoti)

    const notiStyles = props.numNoti > 0 ? styles.numNoti : null

    const selectNotifications = () => {
        filters.selectNotifications()
        props.close()
    }

    const selectStarred = () => {
        filters.selectStarred()
        props.close()
    }

    const selectHistory = () => {
        filters.selectHistory()
        props.close()
    }

    const selectUser = () => {
        filters.selectUser(props.user?.id)
        props.close()
    }

    const defaultPublicOrder = 'time'
    const selectPublic = () => {
        const order = filters.isPublicSelected() ? filter.order : defaultPublicOrder
        filters.selectPublic({ order })
        props.close()
    }

    const selectTreechat = () => {
        const order = filters.isTreechatSelected() ? filter.order : defaultPublicOrder
        filters.selectTreechat({ order })
        props.close()
    }

    const selectHodlocker = () => {
        const order = filters.isHodlockerSelected() ? filter.order : defaultPublicOrder
        filters.selectHodlocker({ order })
        props.close()
    }

    const selectTwetch = () => {
        const order = filters.isTwetchSelected() ? filter.order : defaultPublicOrder
        filters.selectTwetch({ order })
        props.close()
    }

    const selectPrivate = () => {
        const order = filters.isPrivateSelected() ? filter.order : 'time'
        filters.selectPrivate({ order })
        props.close()
    }

    const selectClips = () => {
        const order = filters.isClipSelected() ? filter.order : 'time'
        filters.selectClips({ order })
        props.close()
    }

    const selectPublicClips = () => {
        const order = filters.isPublicClipsSelected() ? filter.order : 'time'
        filters.selectPublicClips({ order })
        props.close()
    }

    const selectLinks = () => {
        const order = filters.isLinkSelected() ? filter.order : 'time'
        filters.selectLinks({ order })
        props.close()
    }

    const selectAll = () => {
        const order = filters.isAllSelected() ? filter.order : 'time'
        filters.selectAll({ order })
        props.close()
    }

    const selectTeam = teamId => {
        const order = filters.isTeamSelected(teamId) ? filter.order : 'time'
        filters.selectTeam(teamId, { order })
        props.close()
    }

    const spaceName = userSpaces?.find(s => s.id === activeSpaceId)?.name || knovigatorSpace?.name

    const myRef = React.useRef(null)

    useEffect(() => {
        if (!props.blur) return

        function handleClickOutside(ev) {
            if (myRef.current && !myRef.current.contains(ev.target)) {
                ev.stopPropagation()
                ev.preventDefault()
                setTimeout(() => {
                    props.close && props.close()
                }, 100)
            }
        }

        // Bind the event listener
        document.addEventListener('mouseup', handleClickOutside)

        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener('mouseup', handleClickOutside)
        }
    }, [myRef])

    const header = props.header || 'Choose Stream'

    return (
        <div
            ref={myRef}
            className={cn(
                styles.filterOptionsComp,
                `filter-options-comp ${props.theme}`,
                props.contextStyles,
            )}
        >
            <>
                <div className={styles.spaceHeaderLabel}>{header}</div>

                {props.show.includes('default') && (
                    <>
                        <div className={styles.streamSection}>
                            {user && (
                                <FilterOption
                                    key="private"
                                    name="private"
                                    filter={filter}
                                    selected={filter?.private}
                                    selectOption={selectPrivate}
                                    tip="Show your private threads."
                                    close={props.close}
                                    canThread
                                    append={props.append}
                                >
                                    <PrivateIcon
                                        contextStyles={cn(
                                            styles.filterOptionIcon,
                                            styles.privateIcon,
                                        )}
                                    />

                                    <div className={styles.streamLabel}>Home</div>
                                </FilterOption>
                            )}
                        </div>

                        <div className={styles.streamSection}>
                            <FilterOption
                                key="public"
                                name="public"
                                filter={filter}
                                selected={filter?.public}
                                selectOption={selectPublic}
                                tip={`Show public on-chain threads.`}
                                close={props.close}
                                canThread
                                //contextStyles={cn(styles.publicStream, styles.headerStream)}
                                append={props.append}
                            >
                                <PublicIcon
                                    contextStyles={cn(styles.filterOptionIcon, styles.publicIcon)}
                                    initialStyles={styles.initialStyles}
                                />

                                <div className={styles.streamLabel}>Public</div>
                            </FilterOption>

                            <div className={styles.subStreamContainer}>
                                <FilterOption
                                    key="treechat"
                                    name="treechat"
                                    filter={filter}
                                    selected={filter?.treechat}
                                    selectOption={selectTreechat}
                                    tip={`Show public on-chain ${spaceName} threads.`}
                                    close={props.close}
                                    canThread
                                    contextStyles={cn(styles.subStream)}
                                    append={props.append}
                                >
                                    <div className={styles.middleLine}>
                                        <div className={styles.middleLineV}></div>
                                        <div className={styles.middleLineH}></div>
                                    </div>

                                    <TreechatIconPng contextStyles={cn(styles.treechatIcon)} />

                                    <div className={styles.streamLabel}>Treechat</div>
                                </FilterOption>

                                <FilterOption
                                    key="hodlocker"
                                    name="hodlocker"
                                    filter={filter}
                                    selected={filter?.hodlocker}
                                    selectOption={selectHodlocker}
                                    tip={`Show on-chain Hodlocker threads.`}
                                    close={props.close}
                                    canThread
                                    contextStyles={cn(styles.subStream, styles.hodlockerStream)}
                                    append={props.append}
                                >
                                    <div className={styles.middleLine}>
                                        <div className={styles.middleLineV}></div>
                                        <div className={styles.middleLineH}></div>
                                    </div>

                                    <div className={styles.hodlockerIconContainer}>
                                        <HodlockerIcon
                                            contextStyles={cn(
                                                styles.filterOptionIcon,
                                                styles.hodlockerIcon,
                                            )}
                                            initialStyles={styles.initialStyles}
                                        />
                                    </div>

                                    <div className={styles.streamLabel}>Hodlocker</div>
                                </FilterOption>

                                <FilterOption
                                    key="twetch"
                                    name="twetch"
                                    filter={filter}
                                    selected={filter?.twetch}
                                    selectOption={selectTwetch}
                                    tip={`Show on-chain Twetch threads.`}
                                    close={props.close}
                                    canThread
                                    contextStyles={cn(styles.subStream)}
                                    append={props.append}
                                >
                                    <div className={styles.endLine}></div>

                                    <div className={styles.twetchIconContainer}>
                                        <TwetchIcon
                                            contextStyles={cn(
                                                styles.filterOptionIcon,
                                                styles.twetchIcon,
                                            )}
                                            initialStyles={styles.initialStyles}
                                        />
                                    </div>

                                    <div className={styles.streamLabel}>Twetch</div>
                                </FilterOption>
                            </div>
                        </div>

                        <div className={styles.streamSection}>
                            {gon?.currentUser && props.showNoti && (
                                <div className={styles.notiStreamContainer}>
                                    <FilterOption
                                        key="noti"
                                        name="noti"
                                        append={props.append}
                                        filter={filter}
                                        selected={filter?.notifications}
                                        selectOption={selectNotifications}
                                        tip={`Show ${spaceName} space's public threads.`}
                                        close={props.close}
                                    >
                                        <NotiIcon showIcon contextStyles={cn(styles.notiIcon)} />

                                        <div className={styles.streamLabel}>Notifications</div>

                                        <div className={styles.statusContainer}>
                                            <div className={notiStyles}>{numNoti}</div>
                                        </div>
                                    </FilterOption>
                                </div>
                            )}

                            {gon?.currentUser && (
                                <div className={styles.starredStreamContainer}>
                                    <FilterOption
                                        key="starred"
                                        name="starred"
                                        append={props.append}
                                        filter={filter}
                                        selected={filter?.starred}
                                        selectOption={selectStarred}
                                        tip={`Show your starred threads in ${spaceName} space`}
                                        close={props.close}
                                    >
                                        <StarredIcon
                                            showIcon
                                            contextStyles={cn(styles.starredIcon)}
                                        />

                                        <div className={styles.streamLabel}>Starred</div>
                                    </FilterOption>
                                </div>
                            )}

                            {gon?.currentUser && (
                                <div className={styles.historyStreamContainer}>
                                    <FilterOption
                                        key="history"
                                        name="history"
                                        append={props.append}
                                        filter={filter}
                                        selected={filter?.history}
                                        selectOption={selectHistory}
                                        tip={`Show your past actions in ${spaceName} space`}
                                        close={props.close}
                                    >
                                        <HistoryIcon
                                            showIcon
                                            contextStyles={cn(styles.historyIcon)}
                                        />

                                        <div className={styles.streamLabel}>History</div>
                                    </FilterOption>
                                </div>
                            )}
                        </div>

                        {user && (
                            <FilterOption
                                key="links"
                                name="links"
                                filter={filter}
                                selected={filter?.link}
                                selectOption={selectLinks}
                                tip="Show your shared-url threads."
                                close={props.close}
                                canThread
                                append={props.append}
                            >
                                <LinkIcon
                                    contextStyles={cn(styles.filterOptionIcon, styles.linkIcon)}
                                />

                                <div className={styles.streamLabel}>Link Access</div>
                            </FilterOption>
                        )}

                        {user && (
                            <FilterOption
                                key="all"
                                name="all"
                                filter={filter}
                                selected={filter?.all}
                                selectOption={selectAll}
                                tip="Show all threads you have access to."
                                close={props.close}
                                append={props.append}
                            >
                                <AllIcon
                                    contextStyles={cn(styles.filterOptionIcon, styles.allIcon)}
                                />

                                <div className={styles.streamLabel}>All</div>
                            </FilterOption>
                        )}

                        {user && (
                            <FilterOption
                                key="clips"
                                name="clips"
                                filter={filter}
                                selected={filter?.clips}
                                selectOption={selectClips}
                                tip="Show all saved clips."
                                close={props.close}
                                append={props.append}
                            >
                                <ClipIcon
                                    contextStyles={cn(styles.filterOptionIcon, styles.linkIcon)}
                                />

                                <div className={styles.streamLabel}>Clips</div>
                            </FilterOption>
                        )}
                    </>
                )}

                {props.show.includes('teams') && (
                    <>
                        {!!userStreams?.length && (
                            <div className={styles.streamHeader}>User Streams</div>
                        )}
                        {!!userStreams?.length &&
                            props.show &&
                            userStreams.map(stream => {
                                return (
                                    <FilterOption
                                        key={stream.id}
                                        name="team"
                                        filter={filter}
                                        team={stream}
                                        selected={
                                            filter?.team_ids &&
                                            filter?.team_ids?.includes(stream.id)
                                        }
                                        selectOption={() => selectTeam(stream.id)}
                                        tip={`Show threads and messages from ${stream.name}`}
                                        close={props.close}
                                        canThread
                                        append={props.append}
                                    >
                                        <div className={styles.teamIconContainer}>
                                            <TeamIcon
                                                team={stream}
                                                tip={`${stream.name} Stream`}
                                                contextStyles={styles.filterOptionIcon}
                                            />
                                        </div>

                                        <div className={styles.streamName}>{stream.name}</div>

                                        <div className={styles.statusContainer}>
                                            {stream.public && (
                                                <div className={styles.statusComp}>
                                                    <i className="fa fa-globe" />
                                                </div>
                                            )}

                                            {!stream.public && (
                                                <div className={styles.statusComp}>
                                                    <i className="fa fa-lock" />
                                                </div>
                                            )}
                                        </div>
                                    </FilterOption>
                                )
                            })}

                        {gon.currentUser && (
                            <div className={styles.addStreamContainer}>
                                <AddStream
                                    closeStreamSelector={props.close}
                                    contextStyles={styles.addStreamButton}
                                    tip={'Create new stream.'}
                                />
                            </div>
                        )}
                    </>
                )}

                {props.show.includes('user') && (
                    <>
                        <FilterOption
                            key="user"
                            name="user"
                            filter={filter}
                            selected={filter?.user}
                            selectOption={selectUser}
                            tip={`Show ${spaceName} space's public threads.`}
                            close={props.close}
                            append={props.append}
                        >
                            <UserIcon
                                user={props.user}
                                showIcon
                                contextStyles={cn(styles.userIcon)}
                            />

                            <div className={styles.streamLabel}>{gon.currentUser?.name}</div>
                        </FilterOption>

                        <FilterOption
                            key="wallet"
                            name="wallet"
                            filter={filter}
                            selected={false}
                            selectOption={() => openWalletModal()}
                            tip="Show wallet."
                            close={props.close}
                            append={props.append}
                        >
                            <div className={styles.walletIconContainer}>
                                <div className={styles.walletIcon}>
                                    <Wallet size={20} />
                                </div>
                            </div>

                            <div className={styles.streamLabel}>Wallet</div>
                        </FilterOption>
                    </>
                )}
            </>
        </div>
    )
}

function FilterOption(props) {
    const [hover, setHover] = useState(false)
    const onMouseEnter = ev => {
        if (isMobile) return
        setHover(true)
    }
    const onMouseLeave = ev => {
        if (isMobile) return
        setHover(false)
    }

    const onClick = ev => {
        ev.preventDefault()
        ev.stopPropagation()

        if (props.selectOption) props.selectOption()
    }

    const selectedStyles = props.selected ? styles.selected : ''

    const team = props.team
    const key = team ? team.id : props.name
    //console.log('key', key)

    const showControls = isMobile ? true : hover

    return (
        <div
            key={key}
            onClick={onClick}
            className={cn(styles.filterOptionComp, selectedStyles, props.contextStyles)}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            <WithTooltip tip={props.tip} contextStyles={styles.flexContainer}>
                <div className={styles.streamContainer}>{props.children}</div>

                {showControls && (
                    <div className={styles.settingsContainer}>
                        <StreamControls
                            name={props.name}
                            filter={props.filter}
                            team={team}
                            selectOption={props.selectOption}
                            close={props.close}
                            canThread={props.canThread}
                            append={props.append}
                        />
                    </div>
                )}
            </WithTooltip>
        </div>
    )
}

function StreamControls(props) {
    const team = props.name == 'team' ? props.team : null

    const navigateTeamSettings = (ev, team) => {
        ev.preventDefault()
        ev.stopPropagation()

        location.href = `/stream/${team.id}/settings`
    }

    const copyHandler = (ev, tipped, team) => {
        ev.preventDefault()
        ev.stopPropagation()

        if (tipped.current) {
            insertToClipboard(team.url)
            tipped.current.setAttribute('data-delay-show', 0)
            tipped.current.setAttribute('data-tip', 'Copied!')
            ReactTooltip.rebuild()
            ReactTooltip.show(tipped.current)
            tipped.current.setAttribute('data-delay-show', 1000)
            tipped.current.setAttribute('data-tip', 'Copy')
            if (isMobile) setTimeout(() => ReactTooltip.hide(tipped.current), 1000)
        }
    }

    return (
        <div className={styles.streamControlsComp}>
            {team && (
                <>
                    {perms.amTeamAdmin(team) && (
                        <div
                            className={cn(styles.settingsBtnComp)}
                            onClick={ev => {
                                props.selectOption(team.id)
                                navigateTeamSettings(ev, team)
                            }}
                        >
                            <i className="fa fa-gear" />
                        </div>
                    )}

                    <CopyStreamUrl team={team} copyHandler={copyHandler} />
                </>
            )}

            {false && props.canThread && (
                <NewStreamQuestBtn
                    panelId={props.panelId}
                    filter={props.filter}
                    append={props.append}
                    close={props.close}
                />
            )}
        </div>
    )
}

function NewStreamQuestBtn(props) {
    const mobileThreadBtnStyles = isMobile ? styles.mobileThreadBtn : null

    return (
        <NewQuestButton
            panelId={props.panelId}
            filter={props.filter}
            callback={() => props.close()}
            append={props.append}
        >
            <div className={cn(styles.threadBtnComp, mobileThreadBtnStyles)}>
                <i className="fa fa-edit" />
                {!isMobile && <span className={styles.threadBtnLabel}>New</span>}
            </div>
        </NewQuestButton>
    )
}

function CopyStreamUrl(props) {
    const tipped = React.createRef()
    return (
        <div className="control">
            <div className="copy-button-comp">
                <CopyTooltip tip="Copy Stream Url" offset={0} ref={tipped}>
                    <div
                        className={cn(styles.settingsBtnComp)}
                        onClick={ev => props.copyHandler(ev, tipped, props.team)}
                    >
                        <i className="fa fa-link" />
                    </div>
                </CopyTooltip>
            </div>
        </div>
    )
}

function HodlockerIcon(props) {
    return <div className={styles.hodlockerIcon}>HL</div>
}
